import React, { Suspense, useEffect } from 'react'
import AppRouter from './routers/app-router'
import MobileNavbar from './components/navbar/mobile-navbar'
import Navbar from './components/navbar/navbar'
import { hot } from 'react-hot-loader/root'
import { theme } from 'src/styling'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import SnackBar from 'src/context/snackbar'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import axios from 'axios'
import { redirectToLoggedOutPage } from './utils/redirect-to-logged-out-page'
import { checkCompanyUploadStatus, checkVulnerabilitiesUploadStatus } from './utils/company'

const useStyles = makeStyles({
  page: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    maxWidth: '100%',
    overflowY: 'hidden',
  },
  content: {
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    marginTop: '0',
    backgroundColor: theme.colors.ui.greyVeryLight,
    transition: 'padding 0.5s',
  },
})

const App: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const token = Cookies.get('auth-token')
  const rbvmDownload = JSON.parse(localStorage.getItem('rbvmDownload'))
  const { pocExpired, freeTrialExpired } = useSelector((state) => state.auth)
  const { enqueueSnackbar } = SnackBar.Consumer()

  useEffect(() => {
    checkCompanyUploadStatus(enqueueSnackbar)
    checkVulnerabilitiesUploadStatus(enqueueSnackbar)
    dispatch({
      type: 'RESET_FILES_DOWNLOADING',
    })

    if (rbvmDownload && !window.downloadTimer && JSON.parse(localStorage.getItem('rbvmDownload'))) {
      window.downloadTimer = setInterval(() => {
        axios
          .get('/check-download-processing-status')
          .then((status) => {
            const data = status.data
            if (!data.isProcessing && data.url) {
              clearInterval(window.downloadTimer)
              localStorage.setItem('rbvmDownload', 'false')
              if (!JSON.parse(localStorage.getItem('rbvmDownload'))) {
                const aTag = document.createElement('a')
                aTag.href = data.url
                aTag.click()
              }
            }
          })
          .catch((error) => {
            console.log('error', error)
          })
      }, 5000)
    }
  }, [])

  const logoutUserIfNoAuthToken = () => {
    if (document.visibilityState === 'visible' && !Cookies.get('auth-token')) {
      const pathsToIgnore = ['/logged-out', '/account-deactivated', '/login', '/redeem']
      const currentPath = window.location.href

      if (pathsToIgnore.some((path) => currentPath.includes(path))) return

      redirectToLoggedOutPage()
    }
  }

  useEffect(() => {
    document.onvisibilitychange = () => logoutUserIfNoAuthToken()
    window.onfocus = () => logoutUserIfNoAuthToken()
  }, [])

  return (
    <Box className={classes.page} id="page">
      {token && !pocExpired && !freeTrialExpired && <Navbar />}
      {token && !pocExpired && !freeTrialExpired && <MobileNavbar />}

      <Box className={classes.content} id="page-content">
        <Suspense fallback={null}>
          <AppRouter />
        </Suspense>
      </Box>
    </Box>
  )
}

export default hot(App)
