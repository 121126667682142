import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import hcMore from 'highcharts/highcharts-more'
import { theme } from 'src/styling'
hcMore(Highcharts)
import sha1 from 'sha1'

type LineChartProps = {
  height: number | string
  gridYLineWidth?: number
  gridXLineWidth?: number
  showXLabels?: boolean
  maxYAxis?: number
  valuePrefix?: string
  showLegend?: boolean
  names?: string[]
  lineMarker: any
  handleSelection?: (event: Event) => void
  data: []
}
// ============
// CHART CONFIG
// ============
const generateConfig = (props: LineChartProps): object => {
  const colors = [theme.colors.ui.grey, theme.colors.ui.gold, theme.colors.ui.blueDark]
  const config = {
    chart: {
      type: 'line',
      height: props.height,
      events: {
        selection: props.handleSelection
          ? function (event: Event) {
              props.handleSelection(event)
              event.preventDefault()
            }
          : {},
      },
      zoomType: props.handleSelection ? 'x' : null,
    },
    title: {
      text: null as any,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      gridLineWidth: props.gridXLineWidth ? props.gridXLineWidth : 0,
      labels: {
        enabled: props.showXLabels,
        style: { fontSize: theme.fontSizes.small },
      },
      type: 'datetime',
      title: {
        text: null as any,
      },
      lineWidth: 0,
      offset: 0,
      tickLength: 0,
    },
    plotOptions: props.lineMarker
      ? {
          line: {
            marker: { enabled: false },
          },
        }
      : {},
    yAxis: {
      min: 0,
      max: props.maxYAxis,
      title: {
        text: null as any,
      },
      labels: {
        style: { fontSize: theme.fontSizes.small },
      },
    },
    legend: {
      enabled: props.showLegend,
      itemStyle: { fontSize: theme.fontSizes.mediumSmall },
    },
    tooltip: {
      backgroundColor: theme.colors.ui.blueDark,
      borderColor: theme.colors.ui.blueDark,
      borderRadius: 8,
      borderWidth: 0,
      followPointer: true,
      padding: 14,
      xDateFormat: '%Y-%m-%d',
      headerFormat: '<b>{point.key}</b><br/>',
      pointFormat: "<span style='color:#fff; font-weight: 400;'>{point.y}</span><br/>",
      style: { color: theme.colors.ui.white, fontSize: theme.fontSizes.mediumLarge, textAlign: 'center' },
      useHTML: true,
      valuePrefix: props.valuePrefix,
    },
    series: props.data.map((d, index: number) => {
      return { color: colors[index], data: d, name: props?.names.length > 0 ? props.names[index] : '' }
    }),
  }

  return config
}

// =========
// COMPONENT
// =========
const GlobalLineChart: React.FC<LineChartProps> = (props) => {
  const config = generateConfig(props)

  return <HighchartsReact key={sha1(JSON.stringify(props.data))} highcharts={Highcharts} options={config} />
}

GlobalLineChart.propTypes = {
  /**
   * The data to be rendered on the bubble chart.
   */
  data: PropTypes.array.isRequired,
  /**
   * Defines the height of the area chart, in pixels.
   */

  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The maximum value of the bubble chart xyaxis.
   */
  maxXAxis: PropTypes.number,
  /**
   * The maximum value of the bubble chart y-axis.
   */
  maxYAxis: PropTypes.number,
  /**
   * Defines the width of the area chart, in pixels.
   */
  width: PropTypes.number,
  /**
   * Defines the label for a bubble's x value when hovering over a bubble.
   */
  xTitle: PropTypes.string,
  /**
   * Defines the label for a bubble's x value when hovering over a bubble.
   */
  yTitle: PropTypes.string,
  /**
   * Defines the x Axis Categories
   */
  xAxisCategories: PropTypes.arrayOf(PropTypes.string),
  /**
   * Defines the Y grid line width
   */
  gridYLineWidth: PropTypes.number,
  /**
   * Defines the X grid line width
   */
  gridXLineWidth: PropTypes.number,
}

GlobalLineChart.defaultProps = {
  data: null,
  height: null,
  maxXAxis: null,
  maxYAxis: null,
  width: null,
  xTitle: 'x',
  yTitle: 'y',
}

export { GlobalLineChart }
