import { Loaders } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

// TYPES
interface BarChartLoaderInterface {
  bars?: number
}

// ======
// STYLED
// ======
const BarChartLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const BarsContainer = styled.div`
  display: flex;
  height: 85%;
  width: 100%;
  margin-bottom: 5%;
`
const BarContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
`

// =========
// COMPONENT
// =========
const GlobalBarChartLoader: React.SFC<BarChartLoaderInterface> = (props) => {
  const bars = [...Array(props.bars)]

  return (
    <BarChartLoaderContainer>
      <BarsContainer>
        {bars.map((bar) => (
          <BarContainer key={bar}>
            <Loaders.Line direction="vertical" />
          </BarContainer>
        ))}
      </BarsContainer>

      <Loaders.Line width="100%" />
    </BarChartLoaderContainer>
  )
}

GlobalBarChartLoader.propTypes = {
  /**
   * The number of bars to render on the chart.
   */
  bars: PropTypes.number,
}

GlobalBarChartLoader.defaultProps = {
  bars: 5,
}

export { GlobalBarChartLoader }
