import axios from 'axios'
import { rootStore } from '../redux/store'

export const checkCompanyUploadStatus = async (enqueueSnackbar) => {
  axios
    .get('/check-upload-processing-status/company')
    .then((status) => {
      localStorage.setItem('companyDownloading', JSON.stringify(status?.data))
      window.dispatchEvent(new Event('storage'))

      const isCompanyDownloading = status?.data.some(({ isProcessing }) => isProcessing)
      const companiesToDownload = status?.data.filter(({ url }) => url)
      if (companiesToDownload?.length) {
        rootStore.dispatch({ type: 'REMOVE_FILES_DOWNLOADING', payload: { files: companiesToDownload } })

        companiesToDownload.map(({ url }) => {
          const aTag = document.createElement('a')
          aTag.href = url
          aTag.click()
        })
      }
      const companyWithError = status?.data.filter(({ error }) => error)

      for (const company of companyWithError) {
        enqueueSnackbar(`Company download ${company?.fileType} for ${company?.companyName} failed.`, {
          variant: 'error',
        })
      }

      if (isCompanyDownloading) {
        window.companyUploadTimer = setInterval(() => {
          axios.get('/check-upload-processing-status/company').then((status) => {
            localStorage.setItem('companyDownloading', JSON.stringify(status?.data))
            window.dispatchEvent(new Event('storage'))

            const isCompanyDownloading = status?.data.some(({ isProcessing }) => isProcessing)
            const companiesToDownload = status?.data.filter(({ url }) => url)
            if (companiesToDownload?.length) {
              rootStore.dispatch({ type: 'REMOVE_FILES_DOWNLOADING', payload: { files: companiesToDownload } })

              companiesToDownload.forEach(({ url }) => {
                const aTag = document.createElement('a')
                aTag.href = url
                aTag.click()
              })
            }

            const companyWithError = status?.data.filter(({ error }) => error)

            for (const company of companyWithError) {
              enqueueSnackbar(`Company download ${company?.fileType} for ${company?.companyName} failed.`, {
                variant: 'error',
              })
            }

            if (!isCompanyDownloading) clearInterval(window.companyUploadTimer)
          })
        }, 5000)
      }
    })
    .catch((error) => {
      console.log(error)
    })

  return () => {
    clearInterval(window.companyUploadTimer)
  }
}

export const checkVulnerabilitiesUploadStatus = async (enqueueSnackbar) => {
  axios
    .get('/check-upload-processing-status/vulnerability')
    .then((status) => {
      localStorage.setItem('vulnerabilityDownloading', JSON.stringify(status?.data))
      window.dispatchEvent(new Event('storage'))

      const isVulnerabilityDownloading = status?.data.some(({ isProcessing }) => isProcessing)
      const isVulnerabilitiesToDownload = status?.data.filter(({ url }) => url)
      isVulnerabilitiesToDownload.map(({ url }) => {
        const aTag = document.createElement('a')
        aTag.href = url
        aTag.click()
      })
      const isVulnerabilitiesWithError = status?.data.filter(({ error }) => error)

      for (const company of isVulnerabilitiesWithError) {
        enqueueSnackbar(`Vulnerabilities download failed.`, {
          variant: 'error',
        })
      }

      if (isVulnerabilityDownloading) {
        window.vulnerabilityUploadTimer = setInterval(() => {
          axios.get('/check-upload-processing-status/vulnerability').then((status) => {
            localStorage.setItem('vulnerabilityDownloading', JSON.stringify(status?.data))
            window.dispatchEvent(new Event('storage'))

            const isVulnerabilityDownloading = status?.data.some(({ isProcessing }) => isProcessing)

            const isVulnerabilitiesToDownload = status?.data.filter(({ url }) => url)
            isVulnerabilitiesToDownload.map(({ url }) => {
              const aTag = document.createElement('a')
              aTag.href = url
              aTag.click()
            })

            const isVulnerabilitiesWithError = status?.data.filter(({ error }) => error)

            for (const company of isVulnerabilitiesWithError) {
              enqueueSnackbar(`Vulnerabilities download failed.`, {
                variant: 'error',
              })
            }

            if (!isVulnerabilityDownloading) clearInterval(window.vulnerabilityUploadTimer)
          })
        }, 5000)
      }
    })
    .catch((error) => {
      console.log(error)
    })

  return () => {
    clearInterval(window.vulnerabilityUploadTimer)
  }
}

export const checkAssetsUploadStatus = async (enqueueSnackbar) => {
  axios
    .get('/check-upload-processing-status/assets')
    .then((status) => {
      localStorage.setItem('assetsDownloading', JSON.stringify(status?.data))
      window.dispatchEvent(new Event('storage'))

      const isAssetsDownloading = status?.data.some(({ isProcessing }) => isProcessing)
      const isAssetsToDownload = status?.data.filter(({ url }) => url)
      isAssetsToDownload.map(({ url }) => {
        const aTag = document.createElement('a')
        aTag.href = url
        aTag.click()
      })
      const isAssetsWithError = status?.data.filter(({ error }) => error)

      for (const company of isAssetsWithError) {
        enqueueSnackbar(`Assets download failed.`, {
          variant: 'error',
        })
      }

      if (isAssetsDownloading) {
        window.assetsUploadTimer = setInterval(() => {
          axios.get('/check-upload-processing-status/assets').then((status) => {
            localStorage.setItem('assetsDownloading', JSON.stringify(status?.data))
            window.dispatchEvent(new Event('storage'))

            const isAssetsDownloading = status?.data.some(({ isProcessing }) => isProcessing)

            const isAssetsToDownload = status?.data.filter(({ url }) => url)
            isAssetsToDownload.map(({ url }) => {
              const aTag = document.createElement('a')
              aTag.href = url
              aTag.click()
            })

            const isAssetsWithError = status?.data.filter(({ error }) => error)

            for (const company of isAssetsWithError) {
              enqueueSnackbar(`Assets download failed.`, {
                variant: 'error',
              })
            }

            if (!isAssetsDownloading) clearInterval(window.assetsUploadTimer)
          })
        }, 5000)
      }
    })
    .catch((error) => {
      console.log(error)
    })

  return () => {
    clearInterval(window.assetsUploadTimer)
  }
}

export const isFileVulnerabilityProcessing = (companyNodeId, organisationNodeId, type, downloadType) =>
  JSON.parse(localStorage.getItem(downloadType)).find(
    ({ isProcessing, companyId, organisationId, fileType }) =>
      isProcessing &&
      fileType === type &&
      parseInt(companyId) === parseInt(companyNodeId) &&
      parseInt(organisationId) === parseInt(organisationNodeId)
  )

export const isFileProcessing = (companyNodeId, companyScoreNodeId, type) =>
  JSON.parse(localStorage.getItem('companyDownloading')).find(
    ({ isProcessing, companyId, companyScoreId, fileType }) =>
      isProcessing &&
      fileType === type &&
      parseInt(companyId) === parseInt(companyNodeId) &&
      companyScoreId === companyScoreNodeId
  )

export const isFileDownloading = (reduxDownloadState, companyIdDownloading, copmanyScoreIdDownloading, fileType) => {
  return (
    JSON.parse(localStorage.getItem('companyDownloading')).find(
      ({ companyId, companyScoreId, isProcessing, fileType: localStorageFileType }) =>
        companyId === parseInt(companyIdDownloading) &&
        companyScoreId === parseInt(copmanyScoreIdDownloading) &&
        isProcessing &&
        localStorageFileType === fileType
    ) ||
    reduxDownloadState?.find(
      ({ companyId, companyScoreId, type }) =>
        companyId === parseInt(companyIdDownloading) &&
        companyScoreId === parseInt(copmanyScoreIdDownloading) &&
        type === fileType
    )
  )
}
