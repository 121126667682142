//Will be replaced at some point. Will not convert to marterial UI

import { Link, NavLink } from 'react-router-dom'
import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from 'src/images/icons/x.svg'
import { ReactComponent as LogoutIcon } from 'src/images/navbar/log-out.svg'
import { Notifications as NotificationsIcon } from '@material-ui/icons'
import { ReactComponent as MenuIcon } from 'src/images/icons/menu.svg'
import { ReactComponent as SettingsIcon } from 'src/images/navbar/settings.svg'
import { hot } from 'react-hot-loader/root'
import { mq } from 'src/styling'
import navbarLogoImage from 'src/images/orpheus/orpheus-logo-small.png'
import navbarLogoText from 'src/images/orpheus/orpheus-text.png'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { getRoutesFromPayload } from 'src/routers/router-helpers'

// ===================
// STYLES
// ===================

const MobileNavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props): string => (props.isDropdownOpen ? '100vh' : '4rem')};
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  border-bottom-left-radius: 0.15rem;
  border-bottom-right-radius: 0.15rem;
  transition: height 0.75s;

  ${mq.sm} {
    display: none;
  }
`

const MobileNavbarTopContainer = styled.div`
  width: 100%;
  line-height: 4rem;
  background-color: ${(props): string => props.theme.colors.ui.blueDark};
  transition: border ${(props): string => (props.isDropdownOpen ? '0s' : '0s 0.75s')};
`

const MobileNavbarHamburgerMenu = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 1.25rem;
  height: 1.5rem;
  width: 1.5rem;
`

const MobileNavbarLogoLink = styled(Link)`
  height: 4rem;
  width: fit-content;
  color: #fff;
`

const MobileNavbarLogoImageContainer = styled.div`
  width: 3.5rem;
  text-align: center;
  display: inline-block;
`

const MobileNavbarLogoImage = styled.img`
  width: 2.5rem;
  vertical-align: middle;
`

const MobileNavbarLogoTextContainer = styled.div`
  width: 7rem;
  text-align: left;
  display: inline-block;
`

const MobileNavbarLogoText = styled.img`
  width: 6rem;
  vertical-align: middle;
`

const MobileNavbarDropdownContainer = styled.div`
  flex-grow: 1;
  height: ${(props): string => (props.isDropdownOpen ? 'auto' : '0')};
  background-color: ${(props): string => props.theme.colors.ui.blueDark};
  transition: height 0.5s;
  overflow: hidden;

  & > a:hover {
    color: white;
    opacity: 1;
    background-color: ${(props): string => props.theme.colors.ui.blueLight};
  }
`

const MobileNavbarLink = styled(NavLink)`
  color: #cccccc;
  text-decoration: none;
  height: 4rem;
  line-height: 4rem;
  display: block;
  white-space: nowrap;
  text-align: center;
  transform: translate-x(-50%);
  opacity: 0.75;
  &.active {
    opacity: 1;
    color: white;
  }
`

const MobileNavbarLogoutContainer = styled.div`
  height: 4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.75;
`

const MobileNavbarLogoutIcon = styled.div`
  width: 1.75rem;
  transform: rotate(180deg);
`

const MobileNavbarNotificationsIcon = styled.div`
  width: 1.75rem;
  color: #fff;
`

const MobileNavbarSettingsLink = styled(NavLink)`
  height: 4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.75;

  &.active {
    background-color: ${(props): string => props.theme.colors.ui.blueLight};
    opacity: 1;
  }
`

const MobileNavbarSettingsIcon = styled.div`
  width: 1.75rem;
`

// =========
// COMPONENT
// =========
const MobileNavbar: React.FC = () => {
  const routes = getRoutesFromPayload()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dispatch = useDispatch()

  const handleLogout = (): void => {
    dispatch({
      type: 'LOGOUT',
    })
  }

  const handleOpenCloseDropdown = (): void => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleClickLink = (): void => {
    setIsDropdownOpen(false)
  }

  return (
    <MobileNavbarContainer id="mobile-navbar" isDropdownOpen={isDropdownOpen}>
      <MobileNavbarTopContainer isDropdownOpen={isDropdownOpen}>
        <MobileNavbarLogoLink to="/external-asm">
          <MobileNavbarLogoImageContainer>
            <MobileNavbarLogoImage src={navbarLogoImage} />
          </MobileNavbarLogoImageContainer>
          <MobileNavbarLogoTextContainer>
            <MobileNavbarLogoText src={navbarLogoText} />
          </MobileNavbarLogoTextContainer>
        </MobileNavbarLogoLink>

        <MobileNavbarHamburgerMenu onClick={handleOpenCloseDropdown}>
          {isDropdownOpen ? <CloseIcon /> : <MenuIcon />}
        </MobileNavbarHamburgerMenu>
      </MobileNavbarTopContainer>

      <MobileNavbarDropdownContainer isDropdownOpen={isDropdownOpen}>
        <MobileNavbarLink to="/external-asm" onClick={handleClickLink}>
          External ASM
        </MobileNavbarLink>
        <MobileNavbarLink to="/internal-rbvm" onClick={handleClickLink}>
          Vulnerabilities
        </MobileNavbarLink>
        <MobileNavbarLink to="/third-party-risk" onClick={handleClickLink}>
          Third Party Risk
        </MobileNavbarLink>
        <MobileNavbarLink to="/intelligence" onClick={handleClickLink}>
          Intelligence
        </MobileNavbarLink>
        {routes && routes['/threat'] && (
          <MobileNavbarLink to="/threat" onClick={handleClickLink}>
            Threat Analysis
          </MobileNavbarLink>
        )}
        <MobileNavbarLink to="/profiles" onClick={handleClickLink}>
          Profiles
        </MobileNavbarLink>
        {/* This is a temporary measure until we improve the offering so can be uncommitted and the page will be visible */}
        {/* <MobileNavbarLink to="/explore" onClick={handleClickLink}>
          Explore
        </MobileNavbarLink> */}
        <MobileNavbarLink to="/reports" onClick={handleClickLink}>
          Reports
        </MobileNavbarLink>
        <MobileNavbarSettingsLink to="/alerts" onClick={handleClickLink}>
          <MobileNavbarNotificationsIcon>
            <NotificationsIcon />
          </MobileNavbarNotificationsIcon>
        </MobileNavbarSettingsLink>
        <MobileNavbarSettingsLink to="/settings" onClick={handleClickLink}>
          <MobileNavbarSettingsIcon>
            <SettingsIcon />
          </MobileNavbarSettingsIcon>
        </MobileNavbarSettingsLink>
        <MobileNavbarLogoutContainer onClick={handleLogout}>
          <MobileNavbarLogoutIcon>
            <LogoutIcon />
          </MobileNavbarLogoutIcon>
        </MobileNavbarLogoutContainer>
      </MobileNavbarDropdownContainer>
    </MobileNavbarContainer>
  )
}

export default hot(MobileNavbar)
