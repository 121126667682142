import { Box, Button } from '@material-ui/core'

import { ReactComponent as Arrow } from 'src/images/icons/arrow.svg'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'src/styling'

type styleProps = {
  disabled: boolean
  disableButton: boolean
  isAdversaryGroupItem?: boolean
}
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.25rem',
    border: '0.9px solid rgb(225, 225, 225)',
    margin: '0.18rem 0 0.18rem 0',
    pointerEvents: (props: styleProps): 'none' | 'auto' => (props.disabled ? 'none' : 'auto'),
    opacity: (props: styleProps): string => (props.disabled ? '0.3' : '1'),
    height: (props: styleProps): number => (props.isAdversaryGroupItem ? null : 70.2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.9rem 0',
  },
  titleContainer: {
    padding: '0.171rem 0.54rem',
    fontSize: theme.fontSizes.medium,
    fontWeight: 450,
  },
  titleContainerWithoutSubtitle: {
    padding: '0.675rem 0.54rem',
    fontSize: theme.fontSizes.medium,
    fontWeight: 450,
  },
  subTitleContainer: {
    paddingLeft: '0.63rem',
    fontSize: theme.fontSizes.mediumSmall,
    color: theme.colors.ui.greyDark,
  },
  leftContainer: {
    padding: '0 0.9rem 0 0.9rem',
  },
  rightContainer: {
    color: (props: styleProps): 'none' | 'auto' => (props?.colorRightChild ? props?.colorRightChild : ''),
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'right',
    alignItems: 'center',
    height: '100%',
    padding: ' 0.18rem 0.9rem 0.18rem 0.18rem',
  },
  arrowContainer: {
    ...theme.customStyles.arrowContainer,
    marginLeft: 'auto',
    opacity: (props: styleProps): string => (props.disableButton ? '0.6' : '1'),
    maxWidth: '36px',
    maxHeight: '36px',
    minWidth: '36px',
    padding: '4.5px',
    minHeight: '36px',
  },
})

type TableItemProps = {
  title: string
  subTitle?: string
  showArrow?: boolean
  onArrowClick?: () => void
  disabled?: boolean
  leftChild?: React.ReactElement
  rightChild?: React.ReactElement
  disableButton?: boolean
  isAdversaryGroupItem?: boolean
}

const TableItem: React.FC<TableItemProps> = (props) => {
  const classes = useStyles(props)

  return (
    <Box className={classes.container}>
      {props.leftChild && <Box className={classes.leftContainer}>{props.leftChild}</Box>}
      <Box className={classes.textContainer}>
        {props.subTitle ? (
          <Box className={classes.titleContainer}>{props.title}</Box>
        ) : (
          <Box className={classes.titleContainerWithoutSubtitle}>{props.title}</Box>
        )}
        {props.subTitle ? <Box className={classes.subTitleContainer}>{props.subTitle}</Box> : null}
      </Box>
      {props.rightChild && <Box className={classes.rightContainer}>{props.rightChild}</Box>}
      {props.showArrow && (
        <Button disabled={props.disableButton} className={classes.arrowContainer} onClick={props.onArrowClick}>
          <Arrow />
        </Button>
      )}
    </Box>
  )
}

export default TableItem
