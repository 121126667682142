import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'src/styling'

// ===================
// TYPES
// ===================

type GlobalFormHeaderProps = {
  children: React.ReactNode
  /**
   * Defines the direction of elements within the form group.
   */
  direction?: 'horizontal' | 'vertical'
  /**
   * Defines the alignment of children in the form group.
   */
  justify?: 'end' | 'center' | 'spread'
}

// ===================
// STYLES
// ===================

const useStyles = makeStyles({
  formHeader: {
    height: '2.7rem',
    borderBottom: `0.9px solid ${theme.colors.ui.greyLight}`,
    marginBottom: '0.9rem',
    fontWeight: 400,
    fontSize: theme.fontSizes.xxLarge,
    color: theme.colors.ui.blueDark,
  },
})

// ===================
// COMPONENT
// ===================
export const GlobalFormHeader: React.FC<GlobalFormHeaderProps> = ({ children }: GlobalFormHeaderProps) => {
  const classes = useStyles()
  return <h2 className={classes.formHeader}>{children}</h2>
}
