import React from 'react'
import InputRange from 'react-input-range'
import { makeStyles } from '@material-ui/core'

// =======
// TYPES
// =======
interface SeveritySliderValue {
  min: number
  max: number
}
type SeveritySliderProps = {
  value: SeveritySliderValue
  minValue: number
  maxValue: number
  onChange: (value: SeveritySliderValue) => void
}

const useStyles = makeStyles({
  sliderBackgroundStyles: {
    '& .input-range__track--background': {
      background:
        'linear-gradient( 90deg, #09b681, #3fbd64, #74c449, #a8cc2e, #dcd212, #fdca05, #f8a612, #f1821f, #ec5e2c, #e64544 ) !important',
    },
    '& .input-range__track--active': { background: `none !important` },
  },
})

export const SeveritySlider: React.FC<SeveritySliderProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={`severity-slider ${classes.sliderBackgroundStyles} `}>
      <InputRange {...props} />
      {props.value && (
        <div className="severity-slider__values">
          {props.value.min} - {props.value.max}
        </div>
      )}
    </div>
  )
}
