import React, { useState } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import { ReactComponent as ChevronDown } from 'src/images/icons/global-chevron-down.svg'
import { ReactComponent as SvgEye } from 'src/images/icons/Eye_open_font_awesome.svg'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'src/styling'

// ===================
// TYPES
// ===================

type CheckBoxInterface = {
  /**
   * If true, checkbox is checked.
   */
  checked?: boolean
  /**
   * Lable.
   */
  label?: string
  /**
   * Position of label.
   */
  labelPosition?: 'left' | 'right'
  /**
   * Action on checkbox click. Val is the current checkbox state.
   */
  onClick: (val: boolean) => any
  /**
   * Unique checkbox id required for group checkboxes.
   */
  id?: number
  /**
   * When enabled, hovering over a checkbox will show a "Select all" or "Select only" tooltip.
   */
  enableHover?: boolean
  /**
   * Used for checkbox groups to shoul the correct tooltip on hover.
   */
  isOnlySelected?: boolean
  /**
   * When a checkbox has children in a checkbox group, then it is semi selected when only some children are checked.
   */
  isSemiSelected?: boolean
  /**
   * Action called on tooltip is click.
   */
  onToolTipClick?: (val: any) => any
  /**
   * Action called when the Chevron is clicked next to a checkbox with children.
   */
  onChevronClick?: () => any
  /**
   * Children check boxes. Appear when chevron is clicked
   */
  children?: React.ReactNode[]
}

// ===================
// STYLES
// ===================

const useStyles = makeStyles({
  toolTipText: {
    color: 'white',
  },
  toolTipEye: { width: '1.2rem', height: '0.9rem', marginRight: '0.5rem' },
  toolTipContainer: {
    position: 'absolute',
    backgroundColor: theme.colors.ui.blueLight,
    height: '2rem',
    borderRadius: '0.15rem',
    zIndex: 1,
    right: '0',
    transform: 'translate(100%, 0%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  checkBoxLabel: {
    textAlign: 'end',
    fontSize: '0.9rem',
  },
  chevronIcon: { width: '0.5rem', minWidth: '0.5rem', height: '0.5rem', cursor: 'pointer', marginLeft: '1rem' },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: 'fit-content',
    padding: '20px 10px 20px 10px',
    height: '2.5rem',
  },
  childrenContainer: {
    overflow: 'scroll',
    padding: '0 1rem 0 2rem',
    maxHeight: '0rem',
  },
  childrenContainerExpanded: {
    maxHeight: '20rem',
  },
})

/**
 * Checkbox tooltip is what appears on the right on the checkbox. If will either have an "All" or "Only" label to select all items, or only this item
 * @param {*}
 */

type CheckBoxToolTipProps = {
  isOnlySelected: boolean
  checked: boolean
  onToolTipClick: (val: boolean) => any
}
const CheckBoxToolTip = (props: CheckBoxToolTipProps): React.ReactElement => {
  const { isOnlySelected, checked, onToolTipClick } = props
  const classes = useStyles()

  return (
    <div className={classes.toolTipContainer} onClick={(): void => onToolTipClick(isOnlySelected && checked)}>
      <div className={classes.toolTipEye}>
        <SvgEye />
      </div>
      <div className={classes.toolTipText}>{isOnlySelected && checked ? 'All' : 'Only'}</div>
    </div>
  )
}

// ===================
// COMPONENT
// ===================

const GlobalCheckBox: React.SFC<CheckBoxInterface> = React.memo(function GlobalCheckBoxComponent(props) {
  const [showTooltip, setTooltip] = useState(false)
  const { labelPosition } = props
  const classes = useStyles()

  return (
    <>
      <div
        className={classes.checkBoxContainer}
        onMouseEnter={(): void => setTooltip(true)}
        onMouseLeave={(): void => setTooltip(false)}
      >
        {props.label && labelPosition === 'left' && <div className={classes.checkBoxLabel}>{props.label}</div>}

        <Checkbox
          checked={props.checked}
          indeterminate={props.isSemiSelected && !props.checked}
          onClick={() => props.onClick(!props.checked)}
        />

        {props.label && labelPosition === 'right' && <div className={classes.checkBoxLabel}>{props.label}</div>}

        {props.onChevronClick && (
          <>
            <div className={classes.chevronIcon} onClick={(): void => props.onChevronClick()}>
              <ChevronDown />
            </div>
          </>
        )}

        {props.enableHover && showTooltip && (
          <CheckBoxToolTip
            isOnlySelected={props.isOnlySelected}
            checked={props.checked}
            onToolTipClick={props.onToolTipClick}
          />
        )}
      </div>
      <div
        className={`${classes.childrenContainer} ${
          props.children && props.children.length > 0 ? classes.childrenContainerExpanded : ''
        }`}
      >
        {props.children}
      </div>
    </>
  )
})

GlobalCheckBox.defaultProps = {
  checked: false,
  label: null,
  labelPosition: 'left',
  onClick: null,
  enableHover: false,
  isOnlySelected: false,
  isSemiSelected: false,
  onChevronClick: null,
}

GlobalCheckBox.displayName = 'GlobalCheckBox'

export { GlobalCheckBox }
