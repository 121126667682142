import React, { lazy, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SnackBar from 'src/context/snackbar'
import Error404 from '../components/errors/error-404'
import PrivateRoute from './private-route'
import { getFirstActiveTab, getRoutesFromPayload } from '../routers/router-helpers'
import moment from 'moment'
import PocExpired from 'src/pages/poc-expired'
import FreeTrialExpiredPage from 'src/pages/free-trial-expired'
import PublicRoute from './public-route'

const ExternalASMPages = lazy(() => import(/* webpackChunkName: "externalASM" */ '../pages/external-asm'))
const ThirdPartyRiskPage = lazy(() => import(/* webpackChunkName: "thirdPartyRisk" */ '../pages/third-party-risk'))
const IntelligencePage = lazy(() => import(/* webpackChunkName: "intelligence" */ '../pages/intelligence'))
const ThreatPage = lazy(() => import(/* webpackChunkName: "intelligence" */ '../pages/threat'))
const ProfilesPage = lazy(() => import(/* webpackChunkName: "discover" */ '../pages/profiles'))
const ExplorePage = lazy(() => import(/* webpackChunkName: "explore" */ '../pages/explore'))
const ReportsPage = lazy(() => import(/* webpackChunkName: "reports" */ '../pages/reports'))
const UserPages = lazy(() => import(/* webpackChunkName: "user" */ '../pages/settings'))
const InternalRBVMPage = lazy(() => import(/* webpackChunkName: "vulnerabilities" */ '../pages/internal-rbvm'))
const Login = lazy(() => import(/* webpackChunkName: "auth" */ '../pages/login'))
const AccountDeactivated = lazy(
  () => import(/* webpackChunkName: "account-deactivated" */ '../pages/account-deactivated')
)
const LoggedOutPage = lazy(() => import(/* webpackChunkName: "logged-out" */ '../pages/logged-out'))
const ReportViewerPages = lazy(() => import(/* webpackChunkName: "report viewer" */ '../pages/your-report'))
const SelfServiceSignUp = lazy(() => import(/* webpackChunkName: "self-signup" */ '../pages/self-service-signup'))
const SubscriptionPages = lazy(() => import(/* webpackChunkName: "report viewer" */ '../pages/subscription'))
const AlertsPage = lazy(() => import(/* webpackChunkName: "report viewer" */ '../pages/alerts'))

const AppRouter: React.FC = () => {
  //get first active tab. If no tabs are active use "/login"
  const tabs = getRoutesFromPayload()
  const urlEnteredOnPageLoad = sessionStorage.getItem('urlOnPageLoad')
  const searchParamsOnLogin = sessionStorage.getItem('searchParamsOnLogin')
  const searchParamsOnPageLoad = window.location.search
  const { enqueueSnackbar } = SnackBar.Consumer()
  const searchParamsToAdd = searchParamsOnLogin
    ? searchParamsOnLogin
    : searchParamsOnPageLoad
    ? searchParamsOnPageLoad
    : ''

  let firstActiveTab = getFirstActiveTab() + `${searchParamsToAdd ?? ''}`

  // Since the default page is set to /external-asm, we can ignore the urlEnteredOnPageLoad setting
  // just after logging in so other pages can be displayed
  if (urlEnteredOnPageLoad && urlEnteredOnPageLoad !== '/external-asm') firstActiveTab = urlEnteredOnPageLoad

  useEffect(() => {
    if (window.location.pathname + window.location.search === urlEnteredOnPageLoad)
      sessionStorage.removeItem('urlOnPageLoad')

    if (searchParamsOnLogin && window.location.pathname + window.location.search === firstActiveTab)
      sessionStorage.removeItem('searchParamsOnLogin')
    const premiumScanning = localStorage.getItem('premiumScanning')
    if (premiumScanning) {
      enqueueSnackbar(
        `Your latest scan was completed at ${moment(JSON.parse(premiumScanning).lastScan).format('DD/MM/YYYY HH:mm')}`,
        {
          variant: 'success',
        }
      )
      localStorage.removeItem('premiumScanning')
    }
  }, [])

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={firstActiveTab} />
      </Route>
      <Route path="/login" component={Login} />
      <PublicRoute path="/account-deactivated" component={AccountDeactivated} />
      <PublicRoute path="/logged-out" component={LoggedOutPage} />
      <PublicRoute path="/redeem" component={SelfServiceSignUp} />
      <Route path="/external-asm" component={PrivateRoute(ExternalASMPages)} />
      <Route path="/internal-rbvm" component={PrivateRoute(InternalRBVMPage)} />
      <Route path="/third-party-risk" component={PrivateRoute(ThirdPartyRiskPage)} />
      <Route path="/intelligence" component={PrivateRoute(IntelligencePage)} />
      {tabs && Object.keys(tabs).includes('/threat') && <Route path="/threat" component={PrivateRoute(ThreatPage)} />}
      <Route path="/profiles" component={PrivateRoute(ProfilesPage)} />
      <Route path="/explore" component={PrivateRoute(ExplorePage)} />
      <Route path="/reports" component={PrivateRoute(ReportsPage)} />
      <Route path="/alerts" component={PrivateRoute(AlertsPage)} />
      <Route path="/your-report" component={PrivateRoute(ReportViewerPages)} />
      <Route path="/settings" component={PrivateRoute(UserPages)} />
      <PublicRoute path="/poc-expired" component={PocExpired} />
      <PublicRoute path="/trial-expired" component={FreeTrialExpiredPage} />
      {tabs && Object.keys(tabs).includes('/subscription') && (
        <Route path="/subscription" component={PrivateRoute(SubscriptionPages)} />
      )}

      {/* Redirecting all vulnerabilities route to internal-rbvm */}
      <Route
        path="/vulnerabilities"
        render={(url) => {
          const { pathname, search } = url.location
          const params = search || ''
          const finalUrl = `/internal-rbvm${pathname.substring('/vulnerabilities'.length)}${params}`
          return <Redirect to={finalUrl} />
        }}
      />

      {/* Redirecting all your-risk route to external-asm */}
      <Route
        path="/your-risk"
        render={(url) => {
          const oldUrl = url.location.pathname
          const modifiedUrl = oldUrl.replace('your-risk', 'external-asm')
          return <Redirect to={modifiedUrl} />
        }}
      />

      {/* V1 url compatibility links */}
      <Route
        path="/overview"
        render={(url) => {
          const redirectURL = ['/intelligence']
          const searchParams = new URLSearchParams(url.location.search)

          if (searchParams.get('id')) redirectURL.push('/reports/', searchParams.get('id'))

          // The way tags are searched in V2 includes the name of the tag which isn't used in V1 so they cannot be converted.
          const queryString = []
          if (searchParams.get('date.start')) queryString.push(`from=${searchParams.get('date.start')}`)
          if (searchParams.get('date.end')) queryString.push(`to=${searchParams.get('date.end')}`)
          if (searchParams.get('severity.min')) queryString.push(`min=${searchParams.get('severity.min')}`)
          if (searchParams.get('severity.max')) queryString.push(`max=${searchParams.get('severity.max')}`)

          const finalUrl = `${redirectURL.join('')}?${queryString.join('&')}`

          return <Redirect to={finalUrl} />
        }}
      />

      <Route
        path="/analysis"
        render={(url) => {
          // Backwards capatibility for V1 profiles page urls
          const redirectURL = ['/profiles']
          const searchParams = new URLSearchParams(url.location.search)
          if (searchParams.get('id')) redirectURL.push('/', searchParams.get('id'))

          // Combine everything into one url
          const finalUrl = `${redirectURL.join('')}`

          return <Redirect to={finalUrl} />
        }}
      />
      <Route component={PrivateRoute(Error404)} />
    </Switch>
  )
}

export default AppRouter
