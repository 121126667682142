import { theme } from 'src/styling'

export const getSeverityColor = (value: number, min: number, max: number) => {
  let colorIndexComparisonValue = ((value - min) / (max - min)) * 10
  if (colorIndexComparisonValue > 10) {
    colorIndexComparisonValue = 10
  } else if (colorIndexComparisonValue < 0) {
    colorIndexComparisonValue = 0
  }

  let color = theme.colors.severity.find((_, index) => {
    return index <= colorIndexComparisonValue && index + 1 >= colorIndexComparisonValue
  })
  if (color) color.replace(', 1)', ', 0.8)')
  return color
}
