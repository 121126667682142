import {
  CheckBoxItem,
  DateRangeItem,
  MultiSelectInputItem,
  MultiTextInputItem,
  RadioGroupItem,
  SliderItem,
} from './filterItems'
import {
  GlobalFilterItemProps,
  GlobalFilterProps,
  MultiItemTextInputType,
  MultiSelectInputType,
} from './filterItems/sharedStyles'
import React, { useState } from 'react'

import { ReactComponent as FilterSRC } from 'src/images/icons/filter.svg'
import { Tag } from 'components'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// ===================
// TYPES
// ===================
type FilterContainerBottomProps = {
  showExpanded?: boolean
  vertical: boolean
}

type FilterContainerTopProps = {
  showExpanded: boolean
  vertical: boolean
}

type AbsoluteContainerProps = {
  vertical: boolean
}

type FilterItemContainerProps = {
  vertical: boolean
  disabled: boolean
}

type FilterItemsProps = {
  showExpanded: boolean
  vertical: boolean
}

// ===================
// STYLES
// ===================
const FilterIcon = styled.div`
  height: 3.3rem;
  width: 3.3rem;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  :hover {
    cursor: pointer;
  }
`

const FilterContainerBottom = styled.div<FilterContainerBottomProps>`
  width: ${({ showExpanded, vertical }): string => (vertical && showExpanded ? '25rem' : vertical ? '6rem' : '100%')};
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.3rem 1.2rem 0.3rem 1.2rem;
  transition: all 0.4s ease-out;
  ${({ vertical }): string => (vertical ? 'height: 5vh;' : '')}
`

const FilterContainerTop = styled.div<FilterContainerTopProps>`
  width: ${({ showExpanded, vertical }): string => (vertical && showExpanded ? '25rem' : vertical ? '6rem' : '100%')};
  background-color: white;
  transition: all 0.4s ease-out;
  ${({ showExpanded, vertical }): string =>
    showExpanded ? 'max-height: 100vh' : !vertical ? 'max-height: 0px' : 'max-height: 100vh'};
  ${({ vertical, showExpanded }): string =>
    vertical && !showExpanded
      ? 'height: 95vh; overflow: hidden;'
      : vertical
      ? 'height: 95vh; overflow: scroll; overflow-x: hidden;'
      : ''}
`

const AbsoluteContainer = styled.div<AbsoluteContainerProps>`
  position: relative;
  ${({ vertical }): string => (vertical ? 'width: fit-content;' : '')}
  z-index: 99;
  box-shadow: 0 0.1rem 0.2rem 0 ${theme.colors.ui.greyLight}};
`

const AppliedFiltersContainer = styled.div`
  padding: 0.8rem;
`

const FilterItemContainer = styled.div<FilterItemContainerProps>`
  max-width: 500px;
  width: ${({ vertical }): string => (vertical ? '20rem' : '80%')};
  height: max-content;
  padding: ${({ vertical }): string => (vertical ? '1.2rem 0.5rem 0.8rem 0.5rem' : '1.2rem 1.2rem 0.8rem 1.2rem')};
  ${({ disabled }): string =>
    disabled
      ? `
  opacity: 0.6;
  pointer-events:none;
  `
      : ''}
`

const FilterItems = styled.div<FilterItemsProps>`
  opacity: ${({ showExpanded }): string => (showExpanded ? '1' : '0')};
  transition: all 0.1s ease-out;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  ${({ showExpanded }): string => (showExpanded ? '' : 'pointer-events:none;')}
  padding-bottom: 2rem;
  flex-direction: ${({ vertical }): string => (vertical ? 'column' : 'row')};
`

// ===================
// FILTER COMPONENTS
// ===================
const GlobalFilterItem: React.FC<GlobalFilterItemProps> = ({ filterOptions, vertical }: GlobalFilterItemProps) => {
  switch (filterOptions.type) {
    case 'MultiSelectInput': {
      return <MultiSelectInputItem filterOptions={filterOptions} vertical={vertical} />
    }
    case 'Slider': {
      return <SliderItem filterOptions={filterOptions} vertical={vertical} />
    }

    case 'MultiItemTextInput': {
      return <MultiTextInputItem filterOptions={filterOptions} vertical={vertical} />
    }

    case 'DateRange': {
      return <DateRangeItem filterOptions={filterOptions} vertical={vertical} />
    }

    case 'CheckBoxGroup': {
      return <CheckBoxItem filterOptions={filterOptions} vertical={vertical} />
    }
    case 'RadioGroup': {
      return <RadioGroupItem filterOptions={filterOptions} vertical={vertical} />
    }
    default:
      return null
  }
}

// ===================
// COMPONENT
// ===================
export const GlobalFilter: React.FC<GlobalFilterProps> = ({ filtersData, vertical }: GlobalFilterProps) => {
  const [showExpanded, setExpanded] = useState(false)

  const toggleExpanded = (): void => setExpanded(!showExpanded)

  // Generate filter items from filtersData
  const filters = filtersData.map((x, k) => <GlobalFilterItem filterOptions={x} vertical={vertical} key={k} />)

  // For MultiSelectInput and MultiItemTextInputs show selected items when filter is closed
  const appliedFilters = filtersData
    .filter((obj) => obj.type === 'MultiSelectInput' || obj.type === 'MultiItemTextInput')
    .map((v: MultiSelectInputType | MultiItemTextInputType) => v.currentValues)
    .flat()
    .map((x: string, k: number) => <Tag value={x} key={k} />)

  return (
    <AbsoluteContainer
      onMouseEnter={(): void => setExpanded(true)}
      onMouseLeave={(): void => setExpanded(false)}
      vertical={vertical}
    >
      <FilterContainerTop showExpanded={showExpanded} vertical={vertical}>
        <FilterItems showExpanded={showExpanded} vertical={vertical}>
          {[...filters]}
        </FilterItems>
      </FilterContainerTop>
      <FilterContainerBottom vertical={vertical} showExpanded={showExpanded}>
        <FilterIcon onClick={(): void => toggleExpanded()}>
          <FilterSRC />
        </FilterIcon>
        {!vertical && <AppliedFiltersContainer>{appliedFilters}</AppliedFiltersContainer>}
      </FilterContainerBottom>
    </AbsoluteContainer>
  )
}
