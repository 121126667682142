import React from 'react'
import { Drawer } from '@material-ui/core'
import { useSelector } from 'react-redux'

type NavbarDrawerProps = {
  open: boolean
  onClose: (open: boolean) => void
  children: JSX.Element
}

const NavbarDrawer = ({ open, onClose, children }: NavbarDrawerProps) => {
  const uiState = useSelector((state) => state.ui)
  const isNavbarOpen = uiState.isNavbarOpen
  return (
    <Drawer
      open={open}
      onClose={onClose}
      elevation={6}
      PaperProps={{ style: { position: 'absolute', width: '486px' } }}
      ModalProps={{
        style: {
          position: 'absolute',
          left: isNavbarOpen ? '9rem' : '3.15rem',
          transition: 'left 0.5s',
        },
      }}
    >
      {children}
    </Drawer>
  )
}

export default NavbarDrawer
