// Replace with https://material-ui.com/components/slider/

import 'react-input-range/lib/css/index.css'

import InputRange from 'react-input-range'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// =====
// TYPES
// =====

type SliderInterface = {
  /**
   * Array of 2 numbers describing the slider range: [0, 100]
   */
  range: number[]
  /**
   * Object describing the current values of shape {min:number, max:number}
   */
  currentValues: SliderValues
  /**
   * Called when slider is changed. Vals is of shape {min: number, max:number}
   */
  onValuesUpdated: (vals: SliderValues) => any
}

type SliderValues = {
  min: number
  max: number
}

// =====
// STYLES
// =====

const LabelContainer = styled.div`
  position: relative;
  color: black;
  text-align: center;
  margin: auto;
  margin-top: 20px;
`

const SliderContainer = styled.div`
  & > * .input-range__track--background {
    background: linear-gradient(
      90deg,
      #09b681,
      #3fbd64,
      #74c449,
      #a8cc2e,
      #dcd212,
      #fdca05,
      #f8a612,
      #f1821f,
      #ec5e2c,
      #e64544
    );
  }

  & > * .input-range__track--active {
    background: transparent;
  }

  & > * .input-range__label-container {
    display: none;
  }

  & > * .input-range__slider-container,
  * .input-range__track {
    transition: none !important;
  }

  & > * .input-range__slider {
    background: ${theme.colors.ui.blueDark};
    border-color: ${theme.colors.ui.blue};
  }
`

const GlobalSliderInput: React.SFC<SliderInterface> = React.memo(function GlobalSliderInputComponent(props) {
  return (
    <SliderContainer>
      <InputRange
        draggableTrack
        maxValue={props.range[1]}
        minValue={props.range[0]}
        value={props.currentValues}
        onChange={props.onValuesUpdated}
      />
      <LabelContainer>
        {props.currentValues.min} - {props.currentValues.max}
      </LabelContainer>
    </SliderContainer>
  )
})

GlobalSliderInput.propTypes = {
  /**
   * Defines the slider range
   */
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * Current slider value
   */
  currentValues: PropTypes.exact({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  /**
   * Called when slider values are updated
   */
  onValuesUpdated: PropTypes.func,
}

GlobalSliderInput.defaultProps = {
  range: [0, 100],
  currentValues: {
    min: 5,
    max: 50,
  },
}

export { GlobalSliderInput }
