import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { ToolTip } from 'components'
import { isMobile } from 'react-device-detect'
import { theme } from 'src/styling'
import ToggleDrawer from './help-drawer'

type Props = {
  title: string
  contentSentence: string
  contentDrawer?: { header: string; description: string }[]
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.fontSizes.xxLarge,
  },
  contentDrawer: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

const TitleWithToolTip: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [isToolTipOpen, setIsToolTipOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const onOpen = (): void => setIsToolTipOpen(true)
  const onClose = (): void => setIsToolTipOpen(false)

  const handleClick = (): void => {
    props.contentDrawer ? setDrawerOpen(true) : setDrawerOpen(!isToolTipOpen)
  }

  const toggleDrawer = (newOpen: boolean) => (): void => {
    setDrawerOpen(newOpen)
  }

  return (
    <Box className={classes.horizontalContainer} onClick={isMobile ? (): void => handleClick : null}>
      <Box className={classes.title}>{props.title}</Box>
      {props.contentSentence && (
        <div className={props.contentDrawer ? classes.contentDrawer : undefined} onClick={handleClick}>
          <ToolTip
            isMobile={isMobile}
            onOpen={onOpen}
            onClose={onClose}
            open={isToolTipOpen}
            placement={'bottom-start'}
          >
            <>{props.contentSentence}</>
          </ToolTip>
        </div>
      )}
      <ToggleDrawer open={drawerOpen} toggleDrawer={() => toggleDrawer(false)} contentDrawer={props.contentDrawer} />
    </Box>
  )
}

export default TitleWithToolTip
