import React, { useRef, useState } from 'react'
import { mq, theme } from 'src/styling'
import { ReactComponent as ChevronDown } from 'src/images/icons/global-chevron-down.svg'
import { Tag } from 'components'
import styled from '@emotion/styled'
import useOutsideAlerter from 'src/utils/use-outsider-alerter'

// ======
// TYPES
// ======
interface MultiSelectInputInterface {
  currentValues?: string[]
  isDropdownOverlay?: boolean
  noSelectionText?: string
  options: string[]
  onValuesUpdated: (vals: string[]) => any
}

type SelectContainerProps = {
  isDropdownOpen: boolean
}

type DropdownIconProps = {
  isDropdownOpen: boolean
}

type DropdownContainerProps = {
  isDropdownOpen: boolean
  showDropdownScrollbar: boolean
  isDropdownOverlay: boolean
}

// ======
// STYLED
// ======
const MultiSelectInputContainer = styled.div`
  width: 100%;
  position: relative;
`

const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  line-height: 1.5rem;
  border: 1px solid ${(props): string => (props.isDropdownOpen ? theme.colors.ui.greyDark : theme.colors.ui.greyLight)};
  border-radius: ${(props): string => (props.isDropdownOpen ? '0' : '0.15rem')};
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.colors.ui.greyDark};
  }
`

const NoSelectionText = styled.div`
  padding-left: 0.75rem;
  align-self: center;
  font-size: 0.9rem;

  ${mq.sm} {
    font-size: 1rem;
  }
`

const ValuesContainer = styled.div`
  padding: 0.15rem;
  padding-right: 0.75rem;
  display: flex;
  flex: 1 1 0;
  flex-wrap: wrap;

  ${mq.sm} {
    padding: 0.25rem;
  }
`

const DropdownDivider = styled.span`
  margin: 0.5rem 0;
  border-left: 1px solid ${theme.colors.ui.greyLight};
`

const DropdownIconContainer = styled.span`
  align-self: center;
  cursor: pointer;
`

const DropdownIcon = styled.div<DropdownIconProps>`
  width: 0.9rem;
  margin: 0.5rem;
  vertical-align: middle;
  transform: rotate(${(props): string => (props.isDropdownOpen ? '180deg' : '0')});
  transition: transform ${(props): string => (props.isDropdownOpen ? '0.5s' : '0.2s')};

  ${mq.sm} {
    width: 1rem;
    margin: 0.75rem;
  }
`

const DropdownContainer = styled.div<DropdownContainerProps>`
  max-height: ${(props): string => (props.isDropdownOpen ? '13.5rem' : '0')};
  width: 100%;
  overflow-y: ${(props): string => (props.showDropdownScrollbar ? 'auto' : 'hidden')};
  border: ${(props): string => (props.isDropdownOpen ? '0.9px' : '0')} solid ${theme.colors.ui.greyDark};
  border-top: 0;
  margin-bottom: 1rem;
  background-color: #fff;
  position: ${(props): string => (props.isDropdownOverlay ? 'absolute' : 'relative')};
  z-index: ${(props): string => (props.isDropdownOverlay ? '10' : 'auto')};
  transition: max-height ${(props): string => (props.isDropdownOpen ? '0.5s' : '0s')};
`

type OptionProps = {
  isSelected: boolean
}

const Option = styled.div<OptionProps>`
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  background-color: ${(props): string => (props.isSelected ? theme.colors.ui.greyVeryLight : 'rgba(0,0,0,0)')};
  font-size: 0.9rem;

  :hover {
    background-color: ${theme.colors.ui.greyVeryLight};
  }

  ${mq.sm} {
    font-size: 1rem;
  }
`

// =========
// COMPONENT
// =========
const GlobalMultiSelectInput = React.memo(function GlobalMultiSelectInputComponent(props: MultiSelectInputInterface) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false) // Stores if dropdown is open or not
  const [showDropdownScrollbar, setShowDropdownScrollbar] = useState(false)
  const node = useRef()

  // detect outside click
  useOutsideAlerter(node, setIsDropdownOpen, false)

  const handleOpenCloseDropdown = (): void => {
    setTimeout(
      () => {
        setShowDropdownScrollbar(!isDropdownOpen)
      },
      isDropdownOpen ? 0 : 500
    )
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleDeleteValue = (value: string): void => {
    // Keep all values except the deleted one
    props.onValuesUpdated(props.currentValues.filter((currentValue: string) => currentValue !== value))
  }

  const handleAddValue = (value: string): void => {
    // Add new value
    props.onValuesUpdated([...props.currentValues, value])
  }

  //When clicking item from the dropdown
  const handleClickOption = (option: string): void => {
    if (props.currentValues.includes(option)) {
      //If item exist in currentValues, remove it
      handleDeleteValue(option)
    } else {
      //If item does not exist in currentValues, add it
      handleAddValue(option)
    }
  }

  return (
    <MultiSelectInputContainer ref={node}>
      <SelectContainer isDropdownOpen={isDropdownOpen} onClick={handleOpenCloseDropdown}>
        {props.currentValues.length === 0 && <NoSelectionText>{props.noSelectionText}</NoSelectionText>}

        <ValuesContainer>
          {props.currentValues.map((currentValue) => (
            <Tag
              key={currentValue}
              value={currentValue}
              isDeletable={true}
              onDelete={(): void => handleDeleteValue(currentValue)}
            />
          ))}
        </ValuesContainer>

        <DropdownDivider></DropdownDivider>

        <DropdownIconContainer>
          <DropdownIcon isDropdownOpen={isDropdownOpen}>
            <ChevronDown />
          </DropdownIcon>
        </DropdownIconContainer>
      </SelectContainer>

      <DropdownContainer
        isDropdownOpen={isDropdownOpen}
        isDropdownOverlay={props.isDropdownOverlay}
        showDropdownScrollbar={showDropdownScrollbar}
      >
        {props.options.map((option) => (
          <Option
            key={option}
            onClick={(): void => handleClickOption(option)}
            isSelected={props.currentValues.includes(option)}
          >
            {option}
          </Option>
        ))}
      </DropdownContainer>
    </MultiSelectInputContainer>
  )
})

// GlobalMultiSelectInput.defaultProps = {
//   currentValues: [],
//   isDropdownOverlay: false,
//   noSelectionText: 'Select',
//   options: [],
//   onValuesUpdated: null,
// }

export { GlobalMultiSelectInput }
