import React, { ReactElement } from 'react'

import PropTypes from 'prop-types'
import { ReactComponent as SortIcon } from 'src/images/icons/sort-arrow-icon.svg'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// TYPES
interface TableInterface {
  alignments?: string | string[]
  headings?: string[]
  onColumnSort?: (() => any)[]
  onRowClick?: (val: number | object) => void
  rows?: ReactElement[][] | object[]
  title?: string
  widths?: string | string[]
  widthsMax?: string | string[]
  widthsMin?: string | string[]
  handleOnMouseEnter?: (val: any[]) => any
  showSeperators?: boolean
  scrollable?: boolean
  isRowClickable?: (rowIndex: number) => boolean
}

// ======
// STYLED
// ======
const TableContainer = styled.table`
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  display: flex;
  border-collapse: collapse;
  flex-direction: column;
`

const TableTitleContainer = styled.td`
  display: flex;
`
const TableTitle = styled.h5`
  padding-bottom: 0.5rem;
  color: ${theme.colors.ui.grey};
`

const TableHeadingsContainer = styled.thead`
  display: flex;
`
const TableHeaderRow = styled.tr`
  display: flex;
  width: 100%;
`

type TableHeadingProps = {
  alignment: string
  width: string
  isSortable: boolean
  maxWidth: string
  minWidth: string
}
const TableHeading = styled.th<TableHeadingProps>`
  display: flex;
  justify-content: ${(props): string =>
    props.alignment === 'left' ? 'flex-start' : props.alignment === 'center' ? 'center' : 'flex-end'};
  align-items: center;
  width: ${(props): string => props.width};
  padding: 0.5rem;
  padding-left: 0;
  color: ${theme.colors.ui.black};
  cursor: ${(props): string => (props.isSortable ? 'pointer' : 'default')};
`
const ColumnSortImage = styled.div`
  height: 0.6rem;
  margin-left: 0.5rem;
`

type TableBodyContainerProps = {
  scrollable: boolean
  showSeperators?: boolean
}
const TableBodyContainer = styled.tbody<TableBodyContainerProps>`
  overflow-y: ${({ scrollable }): string => (scrollable ? 'auto' : 'hidden')};
  overflow-x: hidden;
  ${({ showSeperators }): string => (showSeperators ? `border-top: 1px solid ${theme.colors.ui.grey};` : '')}
`

type TableRowProps = {
  isRowClickable: boolean
  isNotScanned: boolean
}
const TableRow = styled.tr<TableRowProps>`
  display: flex;
  opacity: ${(props): string => (props.isNotScanned ? '0.4' : '1')};
  :hover {
    background-color: ${(props): string => props.isRowClickable && theme.colors.ui.greyVeryLight};
    cursor: ${(props): string => (props.isRowClickable ? 'pointer' : 'default')};
  }
  :last-of-type {
    border-bottom: 0;
  }
`

type TableCellProps = {
  maxWidth: string
  width: string
  alignment: string
  minWidth: string
  showSeperators: boolean
}

const TableCell = styled.td<TableCellProps>`
  ${({ showSeperators }): string => (showSeperators ? `border-bottom: 1px solid ${theme.colors.ui.greyLight};` : '')}
  flex-grow: 1;
  display: flex;
  justify-content: ${(props): string =>
    props.alignment === 'left' ? 'flex-start' : props.alignment === 'center' ? 'center' : 'flex-end'};
  align-items: center;
  width: ${(props): string => props.width};
  max-width: ${(props): string => props.maxWidth};
  min-width: ${(props): string => props.minWidth};
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0.2rem 0.75rem 0.2rem 0;
`

// =========
// COMPONENT
// =========
const GlobalTable: React.FC<TableInterface> = (props) => {
  const rows = props.rows

  let rowClickData: any[]
  if (props.onRowClick) {
    rowClickData = rows.map((row: any[]) => row.pop())
  }

  return (
    <TableContainer>
      {props.title && (
        <TableTitleContainer>
          <TableTitle>{props.title}</TableTitle>
        </TableTitleContainer>
      )}

      {props.headings && (
        <TableHeadingsContainer>
          <TableHeaderRow>
            {props.headings.map((heading, headingIndex) => {
              return (
                <TableHeading
                  key={headingIndex}
                  alignment={typeof props.alignments === 'object' ? props.alignments[headingIndex] : props.alignments}
                  width={typeof props.widths === 'object' ? props.widths[headingIndex] : props.widths}
                  maxWidth={typeof props.widthsMax === 'object' ? props.widthsMax[headingIndex] : props.widthsMax}
                  minWidth={typeof props.widthsMin === 'object' ? props.widthsMin[headingIndex] : props.widthsMin}
                  onClick={props.onColumnSort && props.onColumnSort[headingIndex]}
                  isSortable={props.onColumnSort && props.onColumnSort[headingIndex] !== null}
                >
                  {heading}
                  {props.onColumnSort && props.onColumnSort[headingIndex] && (
                    <ColumnSortImage>
                      <SortIcon />
                    </ColumnSortImage>
                  )}
                </TableHeading>
              )
            })}
          </TableHeaderRow>
        </TableHeadingsContainer>
      )}

      <TableBodyContainer
        scrollable={props.scrollable == null ? true : props.scrollable}
        showSeperators={props.showSeperators == null ? true : false}
      >
        {rows.map((row: React.ReactElement[], rowIndex: number) => {
          const isRowClickable =
            props.isRowClickable != null ? props.isRowClickable(rowIndex) : row[0].props.value === 0 ? false : true
          return (
            <TableRow
              id={rowIndex}
              onMouseEnter={(): void => props.handleOnMouseEnter && props.handleOnMouseEnter(row)}
              key={rowIndex}
              isNotScanned={row[0].props.value === 0}
              isRowClickable={isRowClickable}
              onClick={(): React.ReactElement => props.onRowClick && props.onRowClick(rowClickData[rowIndex])}
            >
              {row.map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  showSeperators={props.showSeperators == null ? true : false}
                  width={typeof props.widths === 'object' ? props.widths[cellIndex] : props.widths}
                  maxWidth={typeof props.widthsMax === 'object' ? props.widthsMax[cellIndex] : props.widthsMax}
                  minWidth={typeof props.widthsMin === 'object' ? props.widthsMin[cellIndex] : props.widthsMin}
                  alignment={typeof props.alignments === 'object' ? props.alignments[cellIndex] : props.alignments}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBodyContainer>
    </TableContainer>
  )
}
GlobalTable.propTypes = {
  /**
   * The alignment of the content within each column
   *
   * Accepts either a single value (applied to all columns) or an array of values.
   */
  alignments: PropTypes.oneOfType([PropTypes.oneOf(['left', 'center', 'right']), PropTypes.arrayOf(PropTypes.string)]),
  /**
   * The table headings.
   */
  headings: PropTypes.arrayOf(PropTypes.string),
  /**
   * Callback: fired when a column header is clicked.
   *
   * Must be an array of callbacks matching the number of columns rendered in the tables. When a column heading is clicked,
   * the corresponding callback with be fired. Use 'null' for columns which are not sortable.
   */
  onColumnSort: PropTypes.arrayOf(PropTypes.func),
  /**
   * Callback: fired when a row is clicked.
   *
   * If provided, the last item in each row is stripped from the rendered data and passed as an input when the corresponding row is clicked.
   */
  onRowClick: PropTypes.func,
  /**
   * The data to be rendered in the table.
   * */
  rows: PropTypes.arrayOf(PropTypes.array),
  /**
   * The table title.
   */
  title: PropTypes.string,
  /**
   * The width of each column.
   *
   * Accepts either a single value (applied to all columns) or an array of values.
   */
  widths: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * The maximum width of each column.
   *
   * Accepts either a single value (applied to all columns) or an array of values.
   */
  widthsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * The minimum widths of each column.
   *
   * Accepts either a single value (applied to all columns) or an array of values.
   */
  widthsMin: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Called when mouse enters row
   */
  handleOnMouseEnter: PropTypes.func,
}
GlobalTable.defaultProps = {
  alignments: 'left',
  headings: null,
  onColumnSort: null,
  onRowClick: null,
  rows: null,
  title: null,
  widths: '100px',
  widthsMax: 'none',
  widthsMin: 'auto',
}
export { GlobalTable }
