import PropTypes from 'prop-types'
import React from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// TYPES
interface AreaCharLoaderInterface {
  gridSpacing?: string
}

// ======
// STYLED
// ======
const pulseAnimation = keyframes`
  0% {
    fill: ${theme.colors.ui.greyVeryLight};
  }
  50% {
    fill: ${theme.colors.ui.greyLight};
  }
  100% {
    fill: ${theme.colors.ui.greyVeryLight};
  }
`
const PolygonPath = styled.polygon`
  fill: none;
  stroke-width: 2;
  stroke-miterlimit: 10;
  strokelinejoin: 'round';
  z-index: 99;
  fill: ${theme.colors.ui.greyLight};
  animation: ${pulseAnimation} 1.5s ease infinite;
`
const SVGContainer = styled.svg`
  position: absolute;
  top: 40%;
`

type GraphContainerProps = {
  gridSpacing: string
}

const GraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-size: ${(props: GraphContainerProps): string => `${props.gridSpacing} ${props.gridSpacing}`};
  background-image: linear-gradient(to right, lightgrey 1px, transparent 1px);
  overflow: hidden;
`

const Points = `0 103.66 277.06 58.71 542.84 84.5 808.62 0 1074.41 32.5 1346 9.66 1346.33 694.39 0.46 694.39 0 103.66`

// =========
// COMPONENT
// =========
const GlobalAreaChartLoader: React.SFC<AreaCharLoaderInterface> = (props) => {
  return (
    <>
      <GraphContainer gridSpacing={props.gridSpacing}>
        <SVGContainer viewBox={'0 0 1346.33 694.39'}>
          <PolygonPath strokeLinecap="round" points={Points} />
        </SVGContainer>
      </GraphContainer>
    </>
  )
}

GlobalAreaChartLoader.propTypes = {
  /**
   * Defines the horizontal spacing of the the background grid.
   */
  gridSpacing: PropTypes.string,
}

GlobalAreaChartLoader.defaultProps = {
  gridSpacing: '90px',
}

export { GlobalAreaChartLoader }
