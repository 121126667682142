import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDropDown'
import { makeStyles } from '@material-ui/core/styles'

type ContentHighlighterProps = {
  content: string
  searchWord: string
}

const useStyles = makeStyles({
  highlightBackground: {
    backgroundColor: '#FFFF00',
  },
  currentOccurrence: {
    backgroundColor: '#FFD700',
  },
  boxStyling: {
    borderTop: '1px solid #ddd',
    maxHeight: '60vh',
    overflowY: 'auto',
    wordBreak: 'break-word',
  },
  marginRight: {
    marginRight: '8px',
  },
})

const ContentHighlighter: React.FC<ContentHighlighterProps> = ({ content, searchWord }) => {
  const classes = useStyles()
  const [highlightedContent, setHighlightedContent] = useState<React.ReactNode[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [totalOccurrences, setTotalOccurrences] = useState(0)
  const occurrences = useRef<(HTMLSpanElement | null)[]>([])
  const initialLoad = useRef(true)
  useEffect(() => {
    const newOccurrences: (HTMLSpanElement | null)[] = []
    const matches = content.match(new RegExp(searchWord, 'gi')) || []
    setTotalOccurrences(matches.length)

    if (matches.length) {
      const searchWordNormalized = searchWord.toLowerCase()
      const splitContent = content.split(new RegExp(`(${searchWord})`, 'gi')).map((part, index) => {
        if (part.toLowerCase() === searchWordNormalized) {
          return (
            <span key={index} ref={(ref) => newOccurrences.push(ref)} className={classes.highlightBackground}>
              {part}
            </span>
          )
        }
        return part
      })

      setHighlightedContent(splitContent)
      occurrences.current = newOccurrences
    } else {
      setHighlightedContent([content])
      setCurrentIndex(0) // Resetting as there are no items to iterate.
      occurrences.current = [] // No occurrences to reference.
    }
  }, [content, searchWord, classes.highlightBackground])

  useEffect(() => {
    if (totalOccurrences === 0) return
    if (initialLoad.current && occurrences.current[0]) {
      goToOccurrence(0)
      initialLoad.current = false
    }

    // Apply highlight only if there are occurrences.
    occurrences.current.forEach((span, index) => {
      if (span) {
        span.className = currentIndex === index ? classes.currentOccurrence : classes.highlightBackground
      }
    })
  }, [currentIndex, totalOccurrences, classes])

  const goToOccurrence = (delta: number) => {
    if (totalOccurrences === 0) return

    let newIndex = currentIndex + delta

    // Ensuring we cycle through the occurrences and don't exceed bounds.
    if (newIndex >= totalOccurrences) {
      newIndex = 0
    } else if (newIndex < 0) {
      newIndex = totalOccurrences - 1
    }

    setCurrentIndex(newIndex)

    occurrences.current[newIndex]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const displayIndices = totalOccurrences > 0 ? `${currentIndex + 1} of ${totalOccurrences}` : `0 of 0`

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" className={classes.marginRight}>
          Mention
        </Typography>
        <Typography variant="body2" className={classes.marginRight}>
          {displayIndices}
        </Typography>
        <IconButton size="small" onClick={() => goToOccurrence(-1)} disabled={totalOccurrences === 0}>
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton size="small" onClick={() => goToOccurrence(1)} disabled={totalOccurrences === 0}>
          <ArrowDownwardIcon />
        </IconButton>
      </Box>
      <Box pt={1} className={classes.boxStyling}>
        <Typography variant="body1">{highlightedContent}</Typography>
      </Box>
    </>
  )
}

export default ContentHighlighter
