import { mq, theme } from 'src/styling'

import React from 'react'
import styled from '@emotion/styled'

// =====
// TYPES
// =====
type ModalHeaderProps = {
  /**
   * The right-aligned content in the modal header.
   */
  children?: React.ReactNode
  /**
   * The modal subtitle1.
   */
  subTitle1?: React.ReactNode
  /**
   * The modal subtitle2. This heading is grey.
   */
  subTitle2?: React.ReactNode
  /**
   * The modal title.
   */
  title?: React.ReactNode | string
  /**
   * The modal title icon, which is displayed on the left of the header
   */
  titleIcon?: any
}

// ======
// STYLED
// ======
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  // align-items: center;
  border-bottom: 0.05rem solid ${theme.colors.ui.greyLight};
  padding-bottom: 1rem;

  ${mq.md} {
    padding-bottom: 1.25rem;
  }
`

const HeaderLeft = styled.div`
  display: flex;
  padding-right: 1rem;
`

const Title = styled.h2`
  font-size: 1.15rem;
  padding-top: 6px;
  font-weight: 400;
  color: ${theme.colors.ui.blueDark};
  margin-bottom: 0.25rem;

  ${mq.sm} {
    font-size: 1.5rem;
  }
`

const Subtitle1 = styled.div`
  font-size: 0.9rem;
  color: black;
`
const Subtitle2 = styled.div`
  font-size: 0.9rem;
  color: ${theme.colors.ui.grey};
`

const IconContainer = styled.div`
  margin-right: 0.5rem;
`

// =========
// COMPONENT
// =========
export const GlobalModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  subTitle1,
  subTitle2,
  title,
  titleIcon,
}: ModalHeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderLeft>
        {titleIcon && <IconContainer>{titleIcon}</IconContainer>}

        <div>
          {title && <Title>{title}</Title>}
          {subTitle1 && <Subtitle1>{subTitle1}</Subtitle1>}
          {subTitle2 && <Subtitle2>{subTitle2}</Subtitle2>}
        </div>
      </HeaderLeft>

      <div>{children}</div>
    </HeaderContainer>
  )
}
