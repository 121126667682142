import React, { useState } from 'react'

import { CheckBox } from 'components'
import { ClassNames } from '@emotion/core'
import { makeStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'

// ===================
// TYPES
// ===================

type CheckBoxGroupInterface = {
  /**
   * An object describing the checboxes as well as their children
   */
  options: CheckBoxGroupItem[]
  /**
   * A set of the currently selected values
   */
  currentValues: Set<number>
  /**
   * Called when the set of checked checkboxes changes
   */
  onValuesUpdated: (val: Set<number>) => any
  /**
   * When true, the checkbox group will be displayed in a column
   */
  vertical?: boolean
}

type CheckBoxGroupItem = {
  label: string
  id: number
  /**
   * Children checkboxes will be displayed when chevron is clicked
   */
  children?: CheckBoxGroupItem[]
}

type ConvertedItemDictInterface = {
  [key: string]: ConvertedItemInterface
}

type ConvertedItemInterface = {
  id: number
  children: ConvertedItemDictInterface
}

type CheckboxShape = {
  label: string
  item: ConvertedItemInterface
  key: number
  childrenCheckBoxes: React.ReactNode[]
  allIds: number[]
  parentId: number
  childrenIds: number[]
  amountOfChildrenSelected: number
}

type styleProps = {
  vertical: boolean
}

// ===================
// STYLES
// ===================

const useStyles = makeStyles({
  checkBoxGroupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  column: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
})

// ===================
// HELPER FUNCTIONS
// ===================

/**
 * This function converts a single object from options into its own key value pair
 * @param {*} item
 */
const convertToDict = (item: CheckBoxGroupItem): object => {
  let children = {}
  if (item.children) {
    item.children.forEach((child: CheckBoxGroupItem) => {
      children = { ...children, ...convertToDict(child) }
    })
  }
  const newObject = { [item.label]: { id: item.id, children: { ...children } } }
  return newObject
}

/**
 * This function uses recursion to generate an array of all checkbox ids including children
 * @param {*} convertedItems Type: Object {label:{id, children:{id}}, label:{id, children}}
 */
const getAllIds = (convertedItems: ConvertedItemDictInterface): number[] => {
  let ids: number[] = []
  Object.keys(convertedItems).map((v) => {
    ids.push(convertedItems[v].id)
    const children = convertedItems[v].children
    ids = [...ids, ...getAllIds(children)]
  })
  return ids
}

/**
 * Returns amount of items that are in both the array and set
 * @param {*} array of checkbox ids
 * @param {*} set of activated checkbox ids
 */
const setHasAmount = (array: number[], set: Set<number>): number => {
  let amount = 0
  for (const i in array) {
    if (set.has(array[i])) {
      amount += 1
    }
  }
  return amount
}

// ===================
// COMPONENT
// ===================

const CheckBoxGroup: React.FC<CheckBoxGroupInterface> = ({
  options,
  currentValues,
  onValuesUpdated,
  vertical = true,
}) => {
  const classes = useStyles()
  const [showChildren, setShowChildren] = useState(new Set())

  /**
   * This function returns a checkbox + any children checkboxes
   * @param {*} label Lable of the checkbox
   * @param {*} item Checkbox data {id, clildren}
   * @param {*} key Key of the checkbox. Should be unique
   * @param {*} childrenCheckBoxes Any children checkboxes. Like: Foums -> 0x00sec, Prtship...
   */
  const getCheckbox = ({
    label,
    item,
    key,
    childrenCheckBoxes,
    allIds,
    parentId,
    childrenIds = [],
    amountOfChildrenSelected = 0,
  }: CheckboxShape): React.ReactElement => {
    const updatedSet = new Set(currentValues)
    const checked = updatedSet.has(item.id)

    const onClick = () => {
      //If checked, check self + all children. If unchecked, uncheck self, all children and parent
      if (checked) {
        ;[item.id, parentId, ...childrenIds].forEach((x) => updatedSet.delete(x))
      } else {
        ;[item.id, ...childrenIds].forEach((x) => updatedSet.add(x))
      }
      onValuesUpdated(updatedSet)
    }

    const onChevronClick = () => {
      if (childrenIds.length > 0) {
        const x = new Set(showChildren)
        x.has(item.id) ? x.delete(item.id) : x.add(item.id)
        setShowChildren(x)
      }
    }

    const onToolTipClick = (clickAll: boolean) => {
      if (clickAll) onValuesUpdated(new Set(allIds))
      else onValuesUpdated(new Set([item.id, ...childrenIds]))
    }

    return (
      <CheckBox
        label={label}
        key={key}
        checked={checked}
        labelPosition={'right'}
        enableHover={true}
        isOnlySelected={updatedSet.has(item.id) && updatedSet.size === 1}
        isSemiSelected={amountOfChildrenSelected > 0}
        onChevronClick={onChevronClick}
        onClick={onClick}
        onToolTipClick={onToolTipClick}
      >
        {showChildren.has(item.id) && childrenCheckBoxes}
      </CheckBox>
    )
  }

  //Convert array into dict
  let convertedItems = {}
  options.forEach((x) => (convertedItems = { ...convertedItems, ...convertToDict(x) }))

  //Store ids of all checkboxes including children.
  const allIds = getAllIds(convertedItems)

  /**
   * This function generates the checkboxes + children checkboxes recursivly
   * @param {*} options Type: Object {label:{id, children}, label:{id, children}}
   */
  const generateItems = (options: ConvertedItemDictInterface, parentId: number): React.ReactNode[] => {
    return Object.keys(options).map((label, key) => {
      const item = options[label]
      let childrenCheckBoxes: React.ReactNode[] = []
      const childrenIds = getAllIds(item.children)
      const amountOfChildrenSelected = setHasAmount(childrenIds, currentValues)
      //If all children are selected, then select the parent too
      if (amountOfChildrenSelected === childrenIds.length && childrenIds.length > 0) {
        currentValues.add(item.id)
      }

      //generate children checkboxes recursivly
      if (item.children) {
        childrenCheckBoxes = generateItems(item.children, item.id)
      }
      return getCheckbox({
        label,
        item,
        key,
        childrenCheckBoxes,
        allIds,
        parentId,
        childrenIds,
        amountOfChildrenSelected,
      })
    })
  }

  const checkBoxes = generateItems(convertedItems, null)

  return (
    <div className={`${classes.checkBoxGroupContainer} ${vertical ? classes.column : classes.row}`}>{checkBoxes}</div>
  )
}

export { CheckBoxGroup }
