import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost'
import introspectionQueryResultData from '../utils/fragments/fragmentTypes'
import Cookies from 'js-cookie'
import { isHttpAuthError } from 'src/utils/network'
import { redirectToLoggedOutPage } from 'src/utils/redirect-to-logged-out-page'

type ServerError = {
  statusCode?: number
}
const client = new ApolloClient({
  uri: process.env.API_DOMAIN + process.env.API_ENDPOINT + process.env.GRAPHQL_ENDPOINT,
  cache: new InMemoryCache({
    addTypename: false,
    fragmentMatcher: new IntrospectionFragmentMatcher({ introspectionQueryResultData }),
  }),
  request: (operation): void => {
    const token = Cookies.get('auth-token')
    operation.setContext({
      headers: {
        Authorization: token ? token : '',
      },
    })
  },
  onError: ({ networkError }) => {
    if (isHttpAuthError(networkError?.statusCode)) {
      Cookies.remove('auth-token')
      redirectToLoggedOutPage()
    }
  },
})
export default client
