import React from 'react'
import { hot } from 'react-hot-loader/root'
import Error from 'src/components/errors/error'

// ===================
// COMPONENET
// ===================
const Error404: React.FC = () => {
  return <Error errorCode={'NOT_FOUND'} />
}

export default hot(Error404)
