import Cookies from 'js-cookie'
import axios from 'axios'
import { isHttpAuthError } from 'src/utils/network'
import { redirectToLoggedOutPage } from 'src/utils/redirect-to-logged-out-page'

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get('auth-token')
    config.url = process.env.API_DOMAIN + process.env.API_ENDPOINT + config.url
    config.headers = {
      Authorization: `${token}`,
      Accept: 'application/json',
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error) => {
    // Handle network errors and other errors here.
    // The error parameter contains the error details.

    // Do something based on the error status code or other criteria.
    if (error.response) {
      // The server responded with a status code outside the range of 2xx.
      // You can access the status code

      console.error('Status code:', error.response.status)
    } else if (error.request) {
      // The request was made, but no response was received.
      // This can happen due to network errors or if the server doesn't respond.
      console.error('No response received:', error.request)
    } else if (isHttpAuthError(error.response.status)) {
      Cookies.remove('auth-token')
      redirectToLoggedOutPage()
    } else {
      // Something else happened while setting up the request.
      console.error('Error:', error.message)
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default axios
