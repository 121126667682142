export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatNumber = (number: number): string => {
  if (!number) return '0'

  if (number > 999 && number < 1000000) {
    return `${parseFloat((number / 1000).toFixed(1))}K`
  } else if (number > 1000000) {
    return `${parseFloat((number / 1000000).toFixed(1))}M`
  } else {
    return number.toString()
  }
}

export const formatWordForPlural = (count: number) => (count > 1 || count === 0 ? 's' : '')

export const getFormattedContent = (content: string) => {
  const imageLinkRegex = /<a href="https:\/\/media\.orpheus-portal\.com\/media\/images_intreps\/[\s]*.*">/gm
  const imageLinks = content.match(imageLinkRegex)
  if (!imageLinks) {
    return content
  }
  const newImageLinks = imageLinks.map((imageLink, index) => {
    const firstSlice = imageLink.slice(0, -1)
    const secondSlice = imageLink.slice(-1)
    // there are two links to every image,
    // the first one is the actual image so this removes the underline to it
    if (index % 2 === 0) {
      return `${firstSlice} class="imageLink noBottomBorder" style="cursor: zoom-in;" ${secondSlice}`
    }
    return `${firstSlice} class="imageLink"${secondSlice}`
  })
  let formattedContent = content.replace(/orpheus-portal.com\/#/g, 'orpheus-portal.com')

  imageLinks.forEach((link, index) => {
    formattedContent = formattedContent.replace(link, newImageLinks[index])
  })
  return formattedContent
}

export const cleanCompanyNameToFile = (name: string) => {
  let nameCleaned = name.trim()
  nameCleaned = nameCleaned.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
  nameCleaned = nameCleaned.replace(/ /g, '_')

  return nameCleaned
}

export const addCommasToNumber = (number: number) => {
  const numberString = number.toString()

  const characters = numberString.split('')
  const reversedCharacters = characters.reverse()

  // Insert commas every three characters
  const resultArray = []
  for (let i = 0; i < reversedCharacters.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      resultArray.push(',')
    }
    resultArray.push(reversedCharacters[i])
  }

  // Reverse the array back and join the characters
  const resultString = resultArray.reverse().join('')

  // Return the formatted number
  return resultString
}
