import React, { Suspense, useState, useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { DocumentNode } from 'apollo-boost'
import { createResource } from 'src/network/fetcher'
import NoResults from 'src/components/errors/no-results'
import { gqlResource } from 'src/types/globalTypes'
import client from 'src/network/apollo-client'
import TableContainer from '@material-ui/core/TableContainer'
import Chip from '@material-ui/core/Chip'
import ErrorBoundary from 'src/components/errors/error-boundary'
import { Dialog, Loaders, DialogGroup, DialogHeader, TitleWithToolTip } from 'components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { theme } from 'src/styling'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'src/network/axios'
import SnackBar from 'src/context/snackbar'
import { getJwtPayload } from 'src/auth/payload'
import moment from 'moment'
import { numberWithCommas } from '../../utils/formatting'
import { recordUserInteraction, userDownloadingDataFromModal } from 'src/services/newrlic'

import { GlobalTableNewOld } from '../tableNew/tableOld'

// ===================
// TYPES
// ===================
export type ContentDialogProps = {
  resource: gqlResource
  id?: number
  updateTotalCount?: (totalCount: number) => void
}

export type ModalProps = {
  onClose?: () => void
  filter?: {
    country?: string
  }
  id?: string
}

export interface SectionInterface {
  title?: string
  subtitle: string
  headings?: string[]
  rowKeys?: string[]
  contentSentence: string
  showExportButton: boolean
  alias?: string
  query?: DocumentNode
  download: string
}
type DialogSectionProps = {
  sections: SectionInterface[]
  content?: (resource: ContentDialogProps) => React.ReactElement
  id?: string
}

type SectionProps = {
  section: SectionInterface
  handleIncreaseError: () => void
  content?: (resource: ContentDialogProps) => React.ReactElement
  id?: string
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  contentSubTitle: {
    color: theme.colors.ui.gold,
    paddingTop: '0.7rem',
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '1rem',
    },
  },
  filterChip: {
    marginRight: '15px',
  },
  exportButton: {
    textTransform: 'none',
    width: '7rem',
    height: '2.25rem',
    fontSize: theme.fontSizes.mediumLarge,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
    backgroundColor: theme.colors.ui.whiteSmoke,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.ui.snow,
    },
    color: theme.colors.ui.blueDark,
    padding: '8px 15px',
  },
  divided: {
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: '1.2rem',
  },
  mainDivided: {
    paddingBottom: '1.5rem',
  },
  tableData: {
    marginTop: '1.1rem',
    maxHeight: '800px',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      height: '0.2rem',
    },
  },
})

/**
 * Helper function. Provided object: {a:{b:"Hello"}} and path: "a.b" returns "Hello"
 * @param obj Object to process
 * @param path Key to extract from object
 */
// const leaf = (obj: objType, path: string) => path.split('.').reduce((value, el) => (el in value ? value[el] : {}), obj)

/**
 * Creates dialog
 * @param param0 Props required to generate dialog. Table will be generated from resource + rowKeys (for rows).
 *               Ability to pass a function that returns
 */

const SectionData: React.FC<any> = ({ section, handleIncreaseError, content, resource, id }) => {
  const first = 10
  const [after, setAfter] = useState(0)
  const resourceData = resource.read()
  const [data, setData] = useState(resourceData[section.alias]?.edges || [])
  const totalCount = resourceData[section.alias]?.totalCount || 0
  const [loading, setLoading] = useState(false)
  const [exploitedByRansomware, setExploitedByRansomware] = useState(false)

  const countExploitedByRansomware = data.reduce((acc, hostCve) => {
    if (hostCve.node.exploitedByRansomware) return (acc += 1)
    return acc
  }, 0)

  const [hasNextPage, setHasNextPage] = useState(resourceData[section.alias]?.pageInfo?.hasNextPage)
  //style
  const classes = useStyles()
  //snackbar
  const { enqueueSnackbar } = SnackBar.Consumer()

  const formatTextWithCountAndPlural = (title: string) => {
    if (!title) return ''
    return title
      .replace('{totalCount}', numberWithCommas(totalCount) || '')
      .replace('{isPlural}', totalCount > 1 ? 's' : '')
  }

  const handleDownload = async (download: string): Promise<void> => {
    recordUserInteraction(userDownloadingDataFromModal(download))
    const { companyId } = getJwtPayload()
    setLoading(true)
    await axios
      .get(`/${download}/${companyId}/download/csv`)
      .then((response) => {
        const date = moment().format('YYYY-MM-DD')
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.setAttribute('download', `${download}_${date}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setLoading(false)
      })
      .catch(() => {
        //Display error message
        enqueueSnackbar(`Sorry, we couldn't start your download. Please try again later.`, {
          variant: 'error',
        })
        setLoading(false)
      })
  }
  useEffect(() => {
    if (totalCount === 0) handleIncreaseError(section.alias)
  }, [totalCount])

  const handleClickExploitedByRansomware = () => {
    setExploitedByRansomware(!exploitedByRansomware)
  }

  if (totalCount === 0) return null

  const handleFetchMore = () => {
    client.query({ query: section.query, variables: { first, after: after + 10 } }).then((res) => {
      setData(data.concat(res.data[section.alias].edges))
      setHasNextPage(res.data[section.alias].pageInfo.hasNextPage)
      setAfter(after + 10)
    })
  }

  return (
    <Box className={classes.mainDivided} id={id}>
      <Box className={classes.divided}>
        <DialogHeader
          title={
            <TitleWithToolTip
              title={formatTextWithCountAndPlural(section.title)}
              contentSentence={formatTextWithCountAndPlural(section.contentSentence)}
            />
          }
          flipTitles={true}
          subtitle={(): React.ReactElement => (
            <>
              <Box style={section.subtitle ? { display: 'block' } : { display: 'none' }}>
                <Box className={classes.contentSubTitle}>
                  {section.subtitle ? section.subtitle.replace('{totalCount}', totalCount.toString() || '') : null}
                </Box>
              </Box>
            </>
          )}
        >
          {section.alias === 'cves' && (
            <Chip
              disabled={!countExploitedByRansomware}
              className={classes.filterChip}
              variant={exploitedByRansomware ? 'default' : 'outlined'}
              onClick={() => handleClickExploitedByRansomware()}
              label={`Exploited by ransomware: ${countExploitedByRansomware}`}
              color="primary"
            />
          )}

          {section.download && (
            <Button
              variant="contained"
              disabled={loading}
              className={classes.exportButton}
              onClick={(): Promise<void> => handleDownload(section.download)}
            >
              {loading ? <Loaders.Spinner size={18} /> : 'Export data'}
            </Button>
          )}
        </DialogHeader>
      </Box>
      {/* Handle no results found  */}

      <>
        <TableContainer className={classes.tableData} id={id + '-table-container'}>
          {content ? (
            content({ resource, exploitedByRansomware }) //We can also pass in the entire table
          ) : (
            <GlobalTableNewOld
              headings={section.headings}
              items={data}
              hasMore={hasNextPage}
              fetchMore={handleFetchMore}
              rowKeys={section.rowKeys}
            />
          )}
        </TableContainer>
      </>
    </Box>
  )
}

const Section: React.FC<SectionProps> = ({ section, handleIncreaseError, content, id }) => {
  const resource = createResource(section.query, {
    variables: { first: 10, after: 0, emailIssues: ['dmarc', 'spf'] },
  })

  return (
    <Box padding={'11px'}>
      <SectionData
        id={id}
        resource={resource}
        section={section}
        handleIncreaseError={handleIncreaseError}
        content={content}
      />
    </Box>
  )
}

//Loader
const FallbackDialog: React.FC = () => (
  <Box>
    <DialogGroup>
      <Loaders.Line />
    </DialogGroup>
    <DialogGroup>
      <Loaders.Text lines={9} width={'100%'} />
    </DialogGroup>
  </Box>
)

//Wrapper
const DialogSectionOld: React.FC<DialogSectionProps> = (props) => {
  //media query
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [sectionError, setSectionError] = useState([])

  const handleIncreaseError = (alias: string) => {
    setSectionError([...new Set(sectionError.concat(alias))])
  }

  return (
    <Dialog onClose={props.onClose} fullScreen={fullScreen}>
      {sectionError.length === props.sections.length && <NoResults message={'No results found'} />}
      <ErrorBoundary>
        <Suspense fallback={<FallbackDialog />}>
          {props.sections.map((section, index) => {
            return (
              <Section
                id={props.id}
                handleIncreaseError={handleIncreaseError}
                content={props.content}
                key={index}
                section={section}
              />
            )
          })}
        </Suspense>
      </ErrorBoundary>
    </Dialog>
  )
}

export default DialogSectionOld
