import React, { useState } from 'react'
import { Box, Button, Drawer, Typography, makeStyles } from '@material-ui/core'
import { theme as globalTheme } from 'src/styling'

type Props = {
  open: boolean
  toggleDrawer: (newOpen: boolean) => () => void
  contentDrawer: { header: string; description: string }[]
}

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    width: 500, // Adjust width as needed
  },
  header: {
    marginBottom: theme.spacing(1),
    color: globalTheme.colors.ui.greyDark,
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  mainHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    color: globalTheme.colors.ui.blue,
  },
}))

const ToggleDrawer: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <Drawer open={props.open} onClose={props.toggleDrawer(false)} anchor={'right'}>
      <Box className={classes.drawerContent}>
        <Typography variant="h5" className={classes.mainHeader}>
          Privacy Risk
        </Typography>
        {props?.contentDrawer?.map((item, index) => (
          <Box key={index}>
            <Typography variant="h6" className={classes.header}>
              {item.header}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Drawer>
  )
}

export default ToggleDrawer
