import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import hcMore from 'highcharts/highcharts-more'
import { theme } from 'src/styling'
hcMore(Highcharts)
import sha1 from 'sha1'

// TYPES
interface BarChartProps {
  series: { names: string[]; data: number[] }[]
  height?: number | string
  maxXAxis?: number
  maxYAxis?: number
  width?: number | string
  xTitle?: string
  yTitle?: string
  xAxisCategories?: string[]
  showLegend?: boolean
  title?: string
  xAxisVisible?: boolean
  tooltipSubTitle?: string
  handleClickPoint: (options: any) => void
}

// ============
// CHART CONFIG
// ============
const generateConfig = (props: BarChartProps): object => {
  const config = {
    chart: {
      type: 'bar',
      height: props.height,
      width: props.width,
    },
    title: {
      text: props.title,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        pointWidth: 20,
        shadow: false,
        dataLabels: { align: 'right', enabled: true },
      },
      bar: {
        dataLabels: { color: 'white', enabled: true },
        shadow: false,
        stacking: 'normal',
      },
    },
    legend: { enabled: false },
    xAxis: {
      categories: props.xAxisCategories,
      minorGridLineWidth: 0,
      tickLength: 0,
      minorTickLength: 0,
      type: 'category',
      labels: {
        style: {
          fontSize: theme.fontSizes.small,
        },
      },
    },
    yAxis: {
      min: 0,
      max: props.maxYAxis,
      title: {
        text: props.yTitle ?? null,
      },
      gridLineColor: 'transparent',
      visible: false,
      labels: {
        style: {
          fontSize: theme.fontSizes.small,
        },
      },
    },
    tooltip: {
      backgroundColor: theme.colors.ui.blueDark,
      borderColor: theme.colors.ui.blueDark,
      borderRadius: 8,
      borderWidth: 0,
      followPointer: true,
      padding: 14,
      headerFormat: '',
      pointFormat: `
          <div style="padding-bottom: 0.225rem;"><div>{point.category} <br/> {point.y}</div></div>
        `,
      style: { color: theme.colors.ui.white, fontSize: theme.fontSizes.mediumLarge, textAlign: 'center' },
      useHTML: true,
    },
    series: props.series,
  }

  return config
}

// =========
// COMPONENT
// =========
const GlobalBarChart: React.FC<BarChartProps> = (props) => {
  const config = generateConfig(props)

  return <HighchartsReact key={sha1(JSON.stringify(props.series))} highcharts={Highcharts} options={config} />
}

GlobalBarChart.propTypes = {
  /**
   * Defines the height of the area chart, in pixels.
   */

  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The maximum value of the bubble chart xyaxis.
   */
  maxXAxis: PropTypes.number,
  /**
   * The maximum value of the bubble chart y-axis.
   */
  maxYAxis: PropTypes.number,
  /**
   * Defines the width of the area chart, in pixels.
   */
  width: PropTypes.number,
  /**
   * Defines the label for a bubble's x value when hovering over a bubble.
   */
  xTitle: PropTypes.string,
  /**
   * Defines the label for a bubble's x value when hovering over a bubble.
   */
  yTitle: PropTypes.string,
  /**
   * Defines the x Axis Categories
   */
  xAxisCategories: PropTypes.arrayOf(PropTypes.string),
}

GlobalBarChart.defaultProps = {
  height: null,
  maxXAxis: null,
  maxYAxis: null,
  width: null,
}

export { GlobalBarChart }
