import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { Box } from '@material-ui/core'

// =====
// TYPES
// =====
type DialogGroupProps = {
  /**
   * Content rendered within the modal group
   */
  children: React.ReactNode
  /**
   * Padding
   */
  padding?: string
}

type GroupProps = {
  padding: string
}

// ======
// STYLED
// ======

const useStyles = makeStyles<Theme, GroupProps>((theme) => ({
  group: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: (props) => props.padding,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'left',
    borderBottom: `0.05rem solid ${theme.colors.ui.greyLight}`,
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
}))
// =========
// COMPONENT
// =========
const DialogGroup: React.FC<DialogGroupProps> = ({ children, padding = '2rem 0' }) => {
  const classes = useStyles({ padding })
  return <Box className={classes.group}>{children}</Box>
}

export { DialogGroup }
