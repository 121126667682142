import moment from 'moment'

export const unixTimestampToDate = (unixTimestamp: any): string => {
  if (unixTimestamp) {
    return moment(parseInt(unixTimestamp)).format('MMM DD, YYYY')
  }
  return null
}

export const unixTimestampUnixToDate = (unixTimestamp: any): string => {
  if (unixTimestamp) {
    return moment.unix(parseInt(unixTimestamp)).format('MMM DD, YYYY')
  }
  return null
}

export const unixTimestampToExactDate = (unixTimestamp: any): string => {
  if (unixTimestamp) {
    return moment(parseInt(unixTimestamp)).format('MMM DD, YYYY hh:mm')
  }
  return null
}

export const timeSinceUnixTimestamp = (unixTimestamp: any): string => {
  if (unixTimestamp) {
    return moment(parseInt(unixTimestamp)).fromNow()
  }
  return null
}

export const timeSinceDateTime = (dateTime: any): string => {
  if (dateTime) {
    return moment(dateTime).fromNow()
  }
  return null
}

export const esDateTimeToDate = (dateTime: any): string => {
  if (dateTime && typeof dateTime === 'string') {
    const datetimeSplitted = dateTime.split('T')[0]
    return moment(datetimeSplitted).format('MMMM D, YYYY')
  } else if (dateTime && typeof dateTime === 'number') {
    return moment(dateTime).format('MMM DD, YYYY')
  }
  return null
}

export const getOneYarDuration = () => {
  const date = new Date()
  const pastYear = date.getFullYear() - 1
  const to = moment(date).format('YYYY-MM-DD')
  const from = moment(date.setFullYear(pastYear)).format('YYYY-MM-DD')

  return {
    from,
    to,
  }
}

export const getDateSelectionUrl = (range): string => {
  const params = new URLSearchParams(location.search)
  params.set('from', moment(range.xAxis[0].min).format('YYYY-MM-DD'))
  params.set('to', moment(range.xAxis[0].max).format('YYYY-MM-DD'))
  return `${location.pathname}?${params.toString()}`
}

export const cookieExpiryDate = new Date(
  moment().add(1, 'day').set({ hours: 4, minutes: 0, seconds: 0, milliseconds: 0 }).valueOf()
)

export const addOrdinalSuffix = (date: number) => {
  if (!date) return null

  const suffixes = ['th', 'st', 'nd', 'rd']
  const remainder = date % 10

  if (date >= 11 && date <= 13) {
    return date + 'th'
  }

  return date + (suffixes[remainder] || 'th')
}
