import React, { useEffect, useState } from 'react'
import { mq, theme } from 'src/styling'

import { ReactComponent as CloseIcon } from 'src/images/icons/global-close-icon.svg'
import { makeStyles } from '@material-ui/core/styles'

// =====
// TYPES
// =====
type GlobalModalProps = {
  children: React.ReactChild[] | React.ReactChild
  /**
   * Defines if the modal has tabs. If true, direct children must be of type <ModalTab>. If false, direct children should be of type <ModalGroup>
   */
  isTabbed?: boolean
  onClose: () => void
  large?: boolean
  maxWidth?: string
  stickyHeader?: boolean
  /**
   * Style: scroll on modal.
   */
  scroll?: boolean
}

type StyleProps = {
  scroll: boolean
  maxWidth: string
  fullScreen: boolean
  large: boolean
  stickyHeader: boolean
}

type windowDimensions = {
  height: number
  width: number
}

// ======
// STYLED
// ======
const useStyles = makeStyles({
  modalBackground: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
    zIndex: 100,
    [mq.sm]: {
      paddingLeft: '4rem',
    },
    [mq.lg]: {
      paddingLeft: '4rem',
    },
  },
  modalContainer: {
    width: (props: StyleProps) => (props.fullScreen ? '100%' : '85%'),
    maxWidth: (props: StyleProps) => (props.fullScreen || props.large ? '100%' : props.maxWidth || '50rem'),
    height: (props: StyleProps) => (props.fullScreen ? '100%' : 'auto'),
    backgroundColor: theme.colors.ui.white,
    position: 'absolute',
    minHeight: '90%',
    overflow: (props: StyleProps) => (props.scroll || props.fullScreen ? 'scroll' : 'hide'),
    borderRadius: '0.15rem',
    left: (props: StyleProps) => (props.fullScreen ? '0rem' : ''),
    top: (props: StyleProps) => (props.fullScreen ? '0rem' : '1rem'),
    bottom: (props: StyleProps) => (props.fullScreen ? '0rem' : '1rem'),
    padding: '1rem',
    paddingTop: '2.75rem',
    webkitBoxShadow: '0px 0px 39px 2px rgba(0, 0, 0, 0.54)',
    mozBoxShadow: '0px 0px 39px 2px rgba(0, 0, 0, 0.54)',
    boxShadow: '0px 0px 39px 2px rgba(0, 0, 0, 0.54)',
    '&::-webkitScrollbar': {
      width: 0,
      background: 'transparent',
    },
    [mq.sm]: {
      top: (props: StyleProps) => (props.fullScreen ? '0rem' : '1rem'),
      bottom: (props: StyleProps) => (props.fullScreen ? '0rem' : '1rem'),
    },
    [mq.md]: {
      padding: '1.75rem',
      paddingTop: '3.25rem',
    },
    paddingRight: ({ stickyHeader }) => (stickyHeader ? '0' : '1.75rem'),
  },
  closeIconContainer: {
    height: '1rem',
    width: '1rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '0.75rem',
    right: '0.75rem',
    color: 'black',
    [mq.md]: {
      top: '1rem',
      right: '1rem',
    },
  },
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.colors.ui.greyLight}`,
    marginBottom: '1rem',
  },
  tabTitle: {
    margin: '0 1px 0 0',
    padding: '0.75rem',
    background: '#fff',
    borderTopLeftRadius: '0.15rem',
    borderTopRightRadius: '0.15rem',
    cursor: 'pointer',
  },
  tabTitleActive: {
    boxShadow: `0px 3px 0px 0px #fff, 0px 0px 0px 0.05rem ${theme.colors.ui.greyLight}`,
  },
  groupContainer: {
    overflow: (props: StyleProps) => (props.stickyHeader ? 'scroll' : 'hide'),
    paddingRight: ({ stickyHeader }) => (stickyHeader ? '1.75rem' : '0'),
    height: '97.5%',
    padding: '0 0.5rem',
  },
})

const getWindowDimensions = (): windowDimensions => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

// =========
// COMPONENT
// =========
export const GlobalModal: React.FC<GlobalModalProps> = ({
  children,
  isTabbed = false,
  stickyHeader = false,
  onClose,
  large,
  maxWidth,
  scroll,
}: GlobalModalProps) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const classes = useStyles({
    scroll,
    maxWidth,
    fullScreen: windowDimensions.width < 700,
    large,
    stickyHeader,
  })
  const [activeTab, setActiveTab] = useState(0)

  let tabTitles: React.ReactNode[] = []

  //Generate tabs
  if (isTabbed) {
    tabTitles = children.map((tab, i): React.ReactNode => {
      if ((tab as any).type.name !== 'GlobalModalTab')
        throw new Error('Children of Tabbed Modal must be of type ModalTab')
      return (
        <div
          key={i}
          onClick={(): void => {
            setActiveTab(i)
          }}
        >
          <h3 className={[classes.tabTitle, i === activeTab ? classes.tabTitleActive : ''].join(' ')}>
            {(tab as any).props.title}
          </h3>
        </div>
      )
    })
  }

  useEffect(() => {
    //When window is resized, check if modal needs resizing
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions())
    }

    //On Escape key exit
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.code == 'Escape') {
        return onClose()
      }
    }

    //Add event listernest
    document.addEventListener('keydown', handleKeyDown)
    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  return (
    <div className={classes.modalBackground} onClick={onClose}>
      <div
        className={classes.modalContainer}
        onClick={(e): void => {
          e.stopPropagation()
        }}
      >
        <div className={classes.closeIconContainer} onClick={onClose}>
          <CloseIcon />
        </div>

        {isTabbed && (
          <>
            <div className={classes.tabBar}>{tabTitles}</div>
            {children[activeTab]}
          </>
        )}

        {!isTabbed && (
          <div
            className={classes.groupContainer}
            onWheel={(e): void => {
              e.stopPropagation()
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  )
}
