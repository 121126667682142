import React, { useEffect, useState } from 'react'
import { TextField, Table, TableCell, TableRow, TableBody, TableContainer, Button } from '@material-ui/core'
import client from 'src/network/apollo-client'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { getJwtPayload } from 'src/auth/payload'
import { getFirstActiveTab } from 'src/routers/router-helpers'
import { Loaders } from '../loaders'
import { theme } from 'src/styling'
import { makeStyles, Theme } from '@material-ui/core'
import { gql as GQL } from 'apollo-boost'
import NavbarDrawer from '../navbar-drawer'
import Error from '../errors/error'
import { persistor } from 'src/redux/store'

// ========
// PROPS
// ========
type Props = {
  onClose: () => void
  organisationCompanyData: any[]
  loading: boolean
  open: boolean
  error: boolean
}

// ========
// STYLES
// ========
const useStyles = makeStyles<Theme>({
  selectCompanyHeaders: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  drawerContent: {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.5rem',
    '& .MuiFormControl-root': { width: '100%' },
  },
  resetButton: {
    color: theme.colors.ui.blue,
    fontSize: '0.7rem',
  },
  table: {
    border: `0.5px solid ${theme.colors.ui.greyDark}`,
  },
  companyTableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.ui.greyLight,
    },
  },
  loadingSpinner: { height: '100%', display: 'table', width: '50%', margin: '0 auto' },
  errorContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

// ========
// QUERIES
// ========
const UPDATE_USER_SPECIFIED_ORGANISATION_COMPANY_ID = GQL`
  mutation updateUserSpecifiedOrganisationCompanyId(
  $selectedCompanyId: Int
  ) {
    updateUserSpecifiedCompanyId(userSpecifiedCompanyId:$selectedCompanyId)
  }
`

const SelectCompany = ({ open, onClose, organisationCompanyData, loading, error }: Props) => {
  const classes = useStyles()
  const [selectedCompanyId, setSelectedCompanyId] = useState(getJwtPayload().companyId)
  const { companyId, viewingAsThirdParty } = getJwtPayload()
  const [rows, setRows] = useState([])
  const { thirdPartyCompanyName } = useSelector((state) => state.ui)
  const dispatch = useDispatch()

  const handleCompanyClick = (companyId: number) => {
    onClose()
    setSelectedCompanyId(companyId)
    setRows(organisationCompanyData)
  }

  useEffect(() => {
    setRows(organisationCompanyData)
  }, [organisationCompanyData])
  const requestSearch = (searchedVal: string) => {
    const filteredRows = organisationCompanyData.filter((row: any) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase())
    })
    setRows(filteredRows)
  }
  useEffect(() => {
    if (selectedCompanyId !== companyId) {
      client
        .mutate({
          mutation: UPDATE_USER_SPECIFIED_ORGANISATION_COMPANY_ID,
          variables: {
            selectedCompanyId,
          },
        })
        .then((response) => {
          const { token, expires, viewingAsThirdParty, thirdPartyCompanyName } = JSON.parse(
            response.data.updateUserSpecifiedCompanyId
          )

          Cookies.set('auth-token', token, {
            expires: expires,
          })
          dispatch({
            type: 'UPDATE_THIRD_PARTY_COMPANY_NAME',
            payload: { thirdPartyCompanyName },
          })
          dispatch({
            type: 'SHOW_THIRD_PARTY_COMPANY_CHANGED',
            payload: { showThirdPartyCompanyChange: true },
          })

          persistor.flush().then(() => {
            if (window.location.pathname === '/settings' && viewingAsThirdParty) {
              const firstActiveTab = getFirstActiveTab()
              window.location.pathname = firstActiveTab
            } else {
              window.location.reload()
            }
          })
        })
    }
  }, [selectedCompanyId])

  return (
    <NavbarDrawer open={open} onClose={onClose}>
      <div className={classes.drawerContent}>
        <div className={classes.selectCompanyHeaders}>
          <h2>Select Company</h2>
          <Button
            className={classes.resetButton}
            disabled={!viewingAsThirdParty}
            onClick={() => handleCompanyClick(null)}
          >
            Clear
          </Button>
        </div>
        {loading ? (
          <div className={classes.loadingSpinner}>
            <Loaders.Spinner />
          </div>
        ) : error ? (
          <div className={classes.errorContainer}>
            <Error disableImage />
          </div>
        ) : !organisationCompanyData.length ? (
          <div>No companies found</div>
        ) : (
          <>
            <TextField
              id="outlined-basic"
              label="Company name"
              variant="outlined"
              defaultValue={viewingAsThirdParty ? thirdPartyCompanyName : ''}
              onChange={(e) => requestSearch(e.target.value)}
              autoComplete="off"
            />
            <TableContainer className={classes.table}>
              <Table aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.id}
                      className={classes.companyTableRow}
                      onClick={() => handleCompanyClick(+row.id)}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </NavbarDrawer>
  )
}

export default SelectCompany
