import { Loaders } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

// TYPES
interface TableLoaderInterface {
  cols?: number
  rows?: number
  headings?: boolean
  title?: boolean
  widths?: string | string[]
}

// ======
// STYLED
// ======
const TitleContainer = styled.div`
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
`

const HeadingsContainer = styled.div`
  margin: 0.5rem 0.5rem 0;
`

const TableRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0;
`

type TableCellProps = {
  width: string | string[]
  i: number
}

const TableCell = styled.div<TableCellProps>`
  display: flex;
  width: ${(props): string => (Array.isArray(props.width) ? props.width[props.i] : props.width)};
  max-width: ${(props): string => (Array.isArray(props.width) ? props.width[props.i] : props.width)};
  flex-grow: 1;
  justify-content: center;
  padding: 1rem 0.5rem;
`

// =========
// COMPONENT
// =========
const GlobalTableLoader: React.SFC<TableLoaderInterface> = (props) => {
  const rows = Array.from(Array(props.rows).keys())
  const cols = Array.from(Array(props.cols).keys())

  const widths = cols.map((_, index) => {
    if (typeof props.widths === 'object' && props.widths[index]) {
      return props.widths[index]
    } else {
      return props.widths
    }
  })

  return (
    <div>
      {props.title && (
        <TitleContainer>
          <Loaders.Line width="75%" />
        </TitleContainer>
      )}

      {props.headings && (
        <HeadingsContainer>
          <Loaders.Line width="100%" />
        </HeadingsContainer>
      )}

      {rows.map((row) => {
        return (
          <TableRow key={row}>
            {cols.map((cell, cellIndex) => (
              <TableCell key={cell} i={cellIndex} width={Array.isArray(widths) ? widths[cellIndex] : widths}>
                <Loaders.Line height="1.5rem" width="100%" />
              </TableCell>
            ))}
          </TableRow>
        )
      })}
    </div>
  )
}

GlobalTableLoader.propTypes = {
  /**
   * The number of columns to render in the table.
   */
  cols: PropTypes.number,
  /**
   * Defines if a line representing the table headings should be included.
   */
  headings: PropTypes.bool,
  /**
   * The number of rows to render in the table.
   */
  rows: PropTypes.number,
  /**
   * Defines if a line representing the table title should be included.
   */
  title: PropTypes.bool,
  /**
   * The width of each column.
   *
   * Accepts either a single value (applied to all columns) or an array of values.
   */
  widths: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

GlobalTableLoader.defaultProps = {
  cols: 3,
  headings: false,
  rows: 3,
  title: false,
  widths: 'auto',
}

export { GlobalTableLoader }
