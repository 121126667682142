import { useSnackbar as Consumer, SnackbarProvider } from 'notistack'

import React from 'react'

const Provider = (props) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      preventDuplicate={true}
      hideIconVariant
    >
      {props.children}
    </SnackbarProvider>
  )
}

export default { Provider, Consumer }
