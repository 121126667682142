import React, { useRef } from 'react'
import { Box, Dialog, DialogContent } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'src/images/icons/global-close-icon.svg'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { RouteComponentProps } from 'react-router-dom'
import { theme as globalTheme } from 'src/styling'
import useOutsideAlerter from 'src/utils/use-outsider-alerter'

// ===================
// STYLES
// ===================

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    zIndex: ({ zIndex }: GlobalDialogProps) => (zIndex ? `${zIndex} !important` : 'auto'),
    '& .MuiPaper-root': {
      backgroundColor: ({ isImageModal }: GlobalDialogProps) => (isImageModal ? 'rgba(0, 0, 0, 0.6)' : 'initial'),
      width: ({ width }: GlobalDialogProps) => (width ? width : 'calc(100% - 64px)'),
      maxWidth: ({ maxWidth }: GlobalDialogProps) => maxWidth,
      overflow: ({ overflow }: GlobalDialogProps) => (overflow ? overflow : 'auto'),
    },
  },
  iconContainer: {
    height: '0.9rem',
    width: '0.9rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '0.80em',
    right: ({ isImageModal }: GlobalDialogProps) => (isImageModal ? '0.95rem' : '0.75rem'),
    color: ({ isImageModal }: GlobalDialogProps) =>
      isImageModal ? globalTheme.colors.ui.white : globalTheme.colors.ui.black,
  },
  dialogContent: {
    display: ({ isImageModal, hasImageSrc }: GlobalDialogProps) => (isImageModal && !hasImageSrc ? 'flex' : 'block'),
    justifyContent: ({ isImageModal, hasImageSrc }: GlobalDialogProps) =>
      isImageModal && !hasImageSrc ? 'center' : 'unset',
    alignItems: ({ isImageModal, hasImageSrc }: GlobalDialogProps) =>
      isImageModal && !hasImageSrc ? 'center' : 'unset',
    padding: '40px',
    minHeight: ({ minWidth, isImageModal, minHeight }: GlobalDialogProps) =>
      minHeight ? minHeight : minWidth ?? isImageModal ? '100%' : '85vh',
    height: ({ height }: GlobalDialogProps) => (height ? height : 'auto'),
    background: ({ isImageModal }: GlobalDialogProps) => (isImageModal ? 'transparent' : globalTheme.colors.ui.white),
    [theme.breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
}))
// ===================
// TYPES
// ===================
type GlobalDialogProps = {
  /**
   * Content rendered within the modal.
   */
  children: React.ReactNode
  /**
   * Callback: Fired when the user clicks outside of the modal.
   */
  onClose: () => void | RouteComponentProps['history']

  /**
   * prop on Dialog: Render if is mobile or tablet
   */
  fullScreen?: boolean
  minWidth?: string
  minHeight?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  isImageModal?: boolean
  hasImageSrc?: boolean
  width?: string
  height?: string
  closeOnOutsideClick?: boolean
  marginLeft?: string
  overflow?: string
  zIndex?: number
}

// ===================
// COMPONENT
// ===================
const GlobalDialog: React.FC<GlobalDialogProps> = (props) => {
  const classes = useStyles(props)
  const dialogRef = useRef(null)
  if (props.closeOnOutsideClick) {
    useOutsideAlerter(dialogRef, props.onClose, true)
  }

  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth={props.maxWidth ? props.maxWidth : 'md'}
      open
      scroll={'body'}
      onClose={props.onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.dialogContainer}
      ref={dialogRef}
      style={props.marginLeft ? { marginLeft: props.marginLeft } : {}}
    >
      <Box className={classes.iconContainer} onClick={props.onClose} id="close-btn">
        <CloseIcon />
      </Box>

      <DialogContent className={classes.dialogContent}>{props.children}</DialogContent>
    </Dialog>
  )
}

export { GlobalDialog }
