import { Dialog, ModalGroup } from 'components'
import React, { Suspense, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@material-ui/core'
import ErrorBoundary from 'src/components/errors/error-boundary'
import { Loaders } from 'components'
import { gql as GQL } from 'apollo-boost'
import NoResults from 'src/components/errors/no-results'
import { theme } from 'src/styling'
import { useQuery } from '@apollo/react-hooks'
import Error from 'src/components/errors/error'
import { Search } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { applyFiltersToUrl } from 'src/utils/filters'

// ======
// TYPES
// ======
type ContentModalProps = {
  onClose: () => void
  handleSetCveId: (cveId: string) => void
  handleCveModalOpenState: (state: boolean) => void
  routes: any
}

type SelectOrganisationModalProps = {
  onClose: () => void
  handleSetCveId: (cveId: string) => void
  handleCveModalOpenState: (state: boolean) => void
  routes: any
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '56px auto',
  },
  textField: { width: '100%' },
  tab: {
    fontSize: theme.fontSizes.medium,
    lineHeight: '1.6',
    padding: '5.5px 11px',
    minHeight: '43px',
    minWidth: 'unset',
  },
  miniNavbar: {
    width: '100% !important',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    '& .MuiTabs-fixed': { display: 'flex' },
  },

  title: {
    marginBottom: '1.5rem',
    marginTop: '-1rem',
    color: theme.colors.ui.blueDark,
  },
  searchButton: {
    minWidth: '0',
    width: '48px',
    padding: 8,
    marginRight: -4,
  },
})

// ========
// QUERIES
// ========
const QUERY_ALL_CVES = GQL`
query cves(
  $first: Int, 
  $cveFilter:CveScannerFilter, 
  $cveOrderBy: OVSSOrderByInput
  $exactMatch: Boolean
) {
  ovss(first:$first, filter:$cveFilter, orderBy:$cveOrderBy, exactMatch:$exactMatch) {
    edges {
      node {
        cve
      }
    }
  }
}
`

const QUERY_ALL_COMPANIES = GQL`
query companies(
  $first: Int, 
  $companyFilter: CompanyFilterInput, 
  $companyOrderBy: CompanyOrderByInput
) {
  companies(first:$first, filter:$companyFilter, orderBy:$companyOrderBy) {
    edges {
      node {
        id
        name
      }
    }
  }
}
`

// ==========
// COMPONENTS
// ==========
const FallbackModal: React.FC = () => (
  <Box>
    <ModalGroup>
      <Loaders.Line />
    </ModalGroup>
    <ModalGroup>
      <Loaders.Text lines={9} width={'100%'} />
    </ModalGroup>
  </Box>
)

const ContentModal: React.FC<ContentModalProps> = ({ onClose, handleSetCveId, handleCveModalOpenState, routes }) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)
  const [currentInput, setCurrentInput] = useState<string>('')
  const [inputError, setInputError] = useState<boolean>(false)
  const [helperText, setHelperText] = useState<string>('Press enter to search')
  const [searchTerm, setSearchTerm] = useState('')
  const cveRegex = /CVE-\d{4}-\d{4,7}/gim

  const history = useHistory()
  const { loading, error, data, refetch } = useQuery(QUERY_ALL_CVES, {
    variables: {
      first: 1,
      cveFilter: { cve: { searchTerm } },
      cveOrderBy: { field: 'cve', direction: 'DESC' },
      exactMatch: true,
    },
    skip: !searchTerm,
    onCompleted: (data) => {
      if (data?.ovss?.edges?.length == 1) {
        onClose()
        handleSetCveId(data.ovss.edges[0].node.cve)
        handleCveModalOpenState(true)
      }
    },
  })

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }
  const handleSubmit = (): void => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    try {
      const [cveToSearch] = currentInput.match(cveRegex)
      setInputError(false)
      setSearchTerm(cveToSearch)
      const params = applyFiltersToUrl({ openCveId: cveToSearch.toUpperCase() })
      setHelperText('')
      history.push(`${window.location.pathname}?${params}`)
      refetch()
    } catch (e) {
      setInputError(true)
      setHelperText('Please enter a valid CVE')
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentInput(event.target.value)
    setSearchTerm('')
    setInputError(false)
    setHelperText('Press enter to search')
  }

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        Quick Search - CVE Cards
      </Typography>
      <Box className={classes.root}>
        <Box>
          <TextField
            inputRef={inputRef}
            error={inputError}
            helperText={helperText}
            className={classes.textField}
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onKeyPress={handleKeyPress}
            value={currentInput}
            onChange={handleInputChange}
            autoComplete="off"
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Button
                      onClick={handleSubmit}
                      disabled={!currentInput.trim()}
                      classes={{ root: classes.searchButton }}
                    >
                      <Search />
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {error && <Error disableImage />}
        {searchTerm && !loading && !data?.ovss?.edges?.length && !error && (
          <Box marginTop={'2rem'}>
            <NoResults message="No results found" disableImage />
          </Box>
        )}
      </Box>
    </>
  )
}

const GlobalSearchModal: React.FC<SelectOrganisationModalProps> = ({
  onClose,
  handleSetCveId,
  handleCveModalOpenState,
  routes,
}) => {
  //media query
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog onClose={onClose} fullScreen={fullScreen} width="700px" minHeight="fit-content">
      <Box padding={'18px'} height={'100%'}>
        <ErrorBoundary isWidget={false}>
          <Suspense fallback={<FallbackModal />}>
            <ContentModal
              onClose={onClose}
              handleSetCveId={handleSetCveId}
              handleCveModalOpenState={handleCveModalOpenState}
              routes={routes}
            />
          </Suspense>
        </ErrorBoundary>
      </Box>
    </Dialog>
  )
}

export default GlobalSearchModal
