import Cookies from 'js-cookie'
import * as JWTDecode from 'jwt-decode'

export const getJwtPayload = () => {
  try {
    const token = Cookies.get('auth-token')
    const decodedToken = JWTDecode(token)
    return decodedToken?.data
  } catch (error) {
    return {}
  }
}
