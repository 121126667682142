import { Loaders } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

// TYPES
interface TextLoaderInterface {
  lines: number
  width?: string
}

// ======
// STYLED
// ======
const TextLineContainer = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`

// =========
// COMPONENT
// =========
const GlobalTextLoader: React.FC<TextLoaderInterface> = (props) => {
  const lines = [...Array(props.lines)].map((x, i) => (
    <TextLineContainer key={i}>
      <Loaders.Line width={props.width ? props.width : (Math.random() * 0.1 + 0.75) * 100 + '%'} />
    </TextLineContainer>
  ))
  return <>{lines}</>
}

GlobalTextLoader.propTypes = {
  /**
   * The number of lines to render in the text block
   */
  lines: PropTypes.number,
  /**
   * Custom line width
   */
  width: PropTypes.string,
}

GlobalTextLoader.defaultProps = {
  lines: 3,
  width: null,
}

export { GlobalTextLoader }
