import { useEffect, RefObject } from 'react'

type HtmlElementRefType = HTMLImageElement | HTMLDivElement

function useOutsideAlerter(
  ref: RefObject<HtmlElementRefType>,
  onCloseFunction: (option?: boolean) => void,
  setFunctionToFalse?: boolean
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFunctionToFalse === false ? onCloseFunction(false) : onCloseFunction()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default useOutsideAlerter
