import { Theme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { GaugeChart } from 'components'
import Grid from '@material-ui/core/Grid'
import { ReactComponent as IconBug } from 'src/images/icons/icon-bug_2.svg'
import React, { useState } from 'react'
import { unixTimestampToDate } from 'src/utils/dates'
import { theme as globalTheme } from 'src/styling'
import RansomwareIcon from 'src/images/pages/external-asm/ransomware.png'
import { IconButton, Tooltip } from '@material-ui/core'
import { FileCopyOutlined } from '@material-ui/icons'
import { addParamToUrl } from 'src/utils/filters'

// ===================
// STYLES
// ===================
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  //======Outer Grid container
  headerContainer: {
    padding: '5px 0 0 0',
    fontSize: '1.25rem',
    color: globalTheme.colors.ui.blueDark,
  },
  //======Cves and Icon Bug
  iconBugContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': { ...globalTheme.customStyles.icon },
    '& h3': { fontSize: globalTheme.fontSizes.xLarge },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  //======Date
  dateContainer: {
    padding: '30px 0 0 9px',
    '& p': {
      fontSize: globalTheme.fontSizes.medium,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 0 9px',
      '& p': {
        textAlign: 'center',
      },
    },
  },
  //======Chart
  chartContainer: {
    display: 'flex',
    float: 'right',
    color: globalTheme.colors.ui.blueDark,
    '& p': {
      padding: '50px 10px 0 0',
      fontWeight: '500',
      fontSize: globalTheme.fontSizes.mediumLarge,
    },
    '& h4': {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      float: 'none',
      justifyContent: 'center',
      padding: '0',
      '& p': {
        display: 'none',
      },
    },
  },
  responsiveCvssScoreTitle: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
      margin: '0',
      fontWeight: '500',
      color: globalTheme.colors.ui.blueDark,
    },
  },
  ransomwareIconImage: {
    width: '17px',
    height: '17px',
    margin: '8px',
  },
  ransomwareIconContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#BD545C',
    fontSize: '0.8rem',
    marginTop: '12px',
  },
  ransomwareText: {
    margin: 0,
  },
  iconCopyButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const scoreCheck = (cveData: any, ovssData: any, tabName: string, cvssVersion: string): number => {
  if (tabName === 'SUMMARY') return ovssData?.ovss
  else if (cvssVersion === 'V2') return cveData?.nvd?.cvssV2Score
  else if (cvssVersion === 'V3') return cveData?.nvd?.nvdMetricsV3?.baseScore
  return 0
}

// ===================
// HEADER COMPONENT OF THE SUMMARY AND CVSS MODAL
// ===================
const TabHeader = ({ tabName, cveData, ovssData, title, scoreTitleName, cvssVersion }: any) => {
  const classes = useStyles()
  const value = scoreCheck(cveData, ovssData, tabName, cvssVersion)

  const range = tabName === 'SUMMARY' ? 100 : 10
  const isExploitedByRansomware = cveData?.exploitedByRansomware

  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyToClipboard = () => {
    const newUrl = addParamToUrl('open-cve', title)
    navigator.clipboard.writeText(newUrl)

    setShowCopiedMessage(true)
    // Hide the message after a delay
    setTimeout(() => {
      setShowCopiedMessage(false)
    }, 2000)
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={1} id="cve-modal-header">
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box display="block">
            <Box className={classes.iconBugContainer}>
              <IconBug />
              <h3>{cveData ? title : 'CVE Reserved'}</h3>
              <IconButton color="primary" className={classes.iconCopyButton} onClick={copyToClipboard}>
                <Tooltip title={showCopiedMessage ? 'Copied to cliboard!' : 'Copy CVE link'} placement="right-start">
                  <FileCopyOutlined color="primary" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
            {isExploitedByRansomware && (
              <Box className={classes.ransomwareIconContainer}>
                <img className={classes.ransomwareIconImage} src={RansomwareIcon} alt="Ransomware Icon" />
                <p className={classes.ransomwareText}>Exploited by Ransomware</p>
              </Box>
            )}

            <Box className={classes.dateContainer}>
              {(cveData?.nvd?.publishedDatetime || ovssData?.tblTimestamp) && (
                <p>
                  {cveData?.nvd?.publishedDatetime &&
                    `Published: ${unixTimestampToDate(cveData?.nvd?.publishedDatetime)}`}
                  {cveData?.nvd?.publishedDatetime && ovssData?.tblTimestamp && <br />}
                </p>
              )}
            </Box>
          </Box>
        </Grid>
        {cveData && (
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className={classes.responsiveCvssScoreTitle}>{`${scoreTitleName} Score`}</p>
            <Box className={classes.chartContainer}>
              <p id="cvss-score-version">{`${scoreTitleName}${tabName === 'SUMMARY' ? '' : ' Score'}`}</p>
              <GaugeChart
                width={125}
                height={125}
                range={[0, range]}
                value={value}
                showScoreCard={true}
                roundValues={false}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
export default TabHeader
