import React from 'react'
import { theme } from 'src/styling'
import { Box, Typography, makeStyles } from '@material-ui/core'
import OrpheusLogoWithBlueText from 'src/images/orpheus/orpheus-logo-with-blue-text.png'

// ======
// STYLES
// ======
const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.ui.blueDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
  },
  contentContainer: {
    maxWidth: '480px',
    background: theme.colors.ui.white,
    padding: '3rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2.7rem',
  },
  logo: { height: '70px' },
  successMessage: { textAlign: 'center', fontSize: theme.fontSizes.large },
  signInLink: { fontSize: theme.fontSizes.large },
})

// =========
// COMPONENT
// =========
const SuccessfulPaymentPage = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <img src={OrpheusLogoWithBlueText} className={classes.logo} />
        <Typography className={classes.successMessage}>
          You have successfully signed up for the portal, please{' '}
          <a href="/login" className={classes.signInLink}>
            sign in
          </a>{' '}
          to the portal
        </Typography>
      </Box>
    </Box>
  )
}

export default SuccessfulPaymentPage
