import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'

import rootReducer from './root-reducer'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

// Get webpack build hash to use in persisting the state (for "state persist busting" after each build)
let hash = ''
for (const item of Array.from(document.getElementsByTagName('script'))) {
  if (item.src.includes('bundle.')) {
    hash = /bundle.+js/.exec(item.src)[0].replace('bundle', '').replace('.js', '').replace('.', '')
  }
}

const persistConfig = {
  key: `root-${hash}`,
  storage,
}

// Enhancers / middleware
const middleware = [thunk]
const composedEnhancers = compose(applyMiddleware(...middleware))

// Store
const rootStore = createStore(persistReducer(persistConfig, rootReducer), undefined, composedEnhancers)
if (module.hot) {
  module.hot.accept('./root-reducer', () => {
    const nextRootReducer = require('./root-reducer').default
    rootStore.replaceReducer(persistReducer(persistConfig, nextRootReducer))
  })
}

// Persistor
const persistor = persistStore(rootStore)

window['state'] = rootStore.getState()

export { rootStore }
export { persistor }
