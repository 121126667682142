import authReducer, { AuthReducerActions, AuthReducerState } from './auth-reducer'
import uiReducer, { UIReducerActions, UIReducerState } from './ui-reducer'

import { combineReducers } from 'redux'

export type RootReducerState = {
  auth: AuthReducerState
  ui: UIReducerState
}
export type RootReducerActions = AuthReducerActions | UIReducerActions

type RootReducer = {
  (state: RootReducerState, action: RootReducerActions): RootReducerState
}

const combinedReducers = combineReducers({
  ui: uiReducer,
  auth: authReducer,
})

const rootReducer: RootReducer = (state, action) => {
  return combinedReducers(state, action)
}

export default rootReducer
