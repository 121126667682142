import { Form, FormGroup, FormHeader, Loaders } from 'components'

import { GlobalStyles } from 'src/styling'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

// TYPES
interface FormLoaderInterface {
  inputs?: number
  isLabels?: boolean
  title?: string
}

// ======
// STYLED
// ======
const ElementSpacer = styled.div`
  width: 100%;
  height: 2.25rem;
`

const Input = (): React.ReactElement => (
  <FormGroup>
    <ElementSpacer />

    <Loaders.Line height="2.25rem" width="100%" />
  </FormGroup>
)

// =========
// COMPONENT
// =========
const GlobalFormLoader: React.SFC<FormLoaderInterface> = (props) => {
  const inputs = [...Array(props.inputs)].map((_, index) => <Input key={index} />)

  return (
    <GlobalStyles>
      <Form variant="transparent">
        {props.title && <FormHeader>{props.title}</FormHeader>}

        {inputs}

        {props.title && (
          <>
            <ElementSpacer />
            <FormGroup direction="horizontal" justify="end">
              <Loaders.Line height="2.5rem" width="8rem" />
            </FormGroup>
          </>
        )}
      </Form>
    </GlobalStyles>
  )
}

GlobalFormLoader.propTypes = {
  /**
   * The number of inputs to render in the Form
   */
  inputs: PropTypes.number,
  /**
   * Defines if the form loader should leave space where labels are
   */
  isLabels: PropTypes.bool,
  /**
   * The title of the form header
   */
  title: PropTypes.string,
}

GlobalFormLoader.defaultProps = {
  inputs: 2,
  isLabels: true,
  title: null,
}

export { GlobalFormLoader }
