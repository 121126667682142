/* eslint-disable no-use-before-define */
import React from 'react'
import Chip from '@material-ui/core/Chip'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Box, Button, CircularProgress, InputAdornment, Paper } from '@material-ui/core'
import { Loaders } from 'components'
import { theme } from 'src/styling'
import { ReactComponent as SearchExploreIcon } from 'src/images/icons/search-explore.svg'
import { ReactComponent as DeleteIcon } from 'src/images/icons/global-delete-icon.svg'
import { Clear } from '@material-ui/icons'
import clsx from 'clsx'
// ======
// TYPES
// ======
type Props = {
  loading: boolean
  options: string[]
  showSearchIcon?: boolean
  showClear?: boolean
  onClear?: () => void
  currentInput: string
  setCurrentInput: (input: string) => void
  selectedOptions: any[]
  onSubmit: () => void
  isDropdownOpen: boolean
  freeSolo: boolean
  onChange: any
  getOptionLabel: (option: any) => string
  onOpenDropdown: () => void
  onCloseDropdown: () => void
  renderTagValues: string[]
  placeholder?: string
  textFieldAutoFocus?: boolean
  textFieldStyles?: any
  autoCompletePopperStyles?: any
  error: any
  filterOptions?: (options: any[], state: object) => undefined
  clearOnBlur: boolean
  enablePasting?: boolean
  handlePastingData?: (event: React.ClipboardEvent<HTMLInputElement>) => void
}
// ======
// STYLES
// ======
const useStyles = makeStyles(() => ({
  autocomplete: {
    backgroundColor: 'white',
    fontSize: '0.9rem',
    borderRadius: 4,
    padding: '6px !important',
    paddingRight: '6px !important',
    height: '3.06rem',
    overflowY: 'auto',
  },
  autocompleteWithClear: {
    paddingRight: '48px !important',
  },
  chip: {
    margin: '0.225rem',
    fontSize: '0.885rem',
    backgroundColor: theme.colors.ui.greyVeryLight,
    border: `0.9px solid ${theme.colors.ui.greyLight}`,
    borderRadius: '0.15rem',
    lineHeight: '1.575rem',
    height: 'auto',
  },
  chipDeleteIcon: {
    height: '0.675rem',
    width: '0.675rem',
    color: theme.colors.ui.redDark,
  },
  chipDeleteIconContainer: {
    padding: '0.225rem 0.45rem',
    alignSelf: 'stretch',
    cursor: 'pointer',
    width: 'unset',
    height: 'unset',
    margin: 'unset',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      borderTopRightRadius: '0.15rem',
      borderTopLeftRadius: '0.15rem',
      backgroundColor: theme.colors.ui.greyLight,
    },
  },
  chipLabel: {
    padding: '0 0.45rem',
  },
  dropdownOption: {
    fontSize: '0.9rem',
  },
  icon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '6px',
  },
  dropdownErrorMessage: { color: theme.colors.ui.red, padding: '10px 10px 10px 16px' },
  clearButton: {
    position: 'absolute',
    right: 8,
    top: 7,
    minWidth: '0',
  },
}))

// =========
// COMPONENT
// =========
const Autocomplete = ({
  onSubmit,
  loading,
  selectedOptions,
  onChange,
  options,
  currentInput,
  setCurrentInput,
  getOptionLabel,
  isDropdownOpen,
  onOpenDropdown,
  onCloseDropdown,
  renderTagValues,
  placeholder,
  showSearchIcon,
  textFieldAutoFocus,
  textFieldStyles,
  autoCompletePopperStyles,
  freeSolo,
  error,
  clearOnBlur,
  filterOptions,
  showClear,
  enablePasting,
  handlePastingData,
  onClear,
}: Props) => {
  const classes = useStyles()

  return (
    <MuiAutocomplete
      classes={{
        inputRoot: clsx(classes.autocomplete, { [classes.autocompleteWithClear]: showClear }),
        option: classes.dropdownOption,
        popper: autoCompletePopperStyles ?? {},
      }}
      autoHighlight
      multiple
      clearOnBlur={clearOnBlur}
      open={isDropdownOpen}
      onOpen={onOpenDropdown}
      onClose={onCloseDropdown}
      loading={loading}
      loadingText={
        <div style={{ overflow: 'hidden' }}>
          <Loaders.Text width={'100%'} lines={5} />
        </div>
      }
      disablePortal
      noOptionsText="No results found"
      id="tags-filled"
      options={loading ? [] : options}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !isDropdownOpen) {
          event.preventDefault()
          event.stopPropagation()
          onSubmit()
        }
      }}
      freeSolo={freeSolo}
      onInputChange={(event, value) => setCurrentInput(value)}
      getOptionLabel={getOptionLabel}
      value={selectedOptions}
      onChange={onChange}
      filterOptions={filterOptions}
      PaperComponent={({ children }) =>
        error ? (
          <Paper className={classes.dropdownErrorMessage}>
            Sorry, we couldn't fetch any results. Please try again later.
          </Paper>
        ) : (
          <Paper style={{ backgroundColor: 'white', width: '100%' }}>{children}</Paper>
        )
      }
      renderTags={(values, getTagProps) => {
        // pass in renderTagValues prop as array of strings instead of using component
        // values because the options will not always be the same data format
        return renderTagValues.map((value, index) => (
          <Chip
            key={value}
            label={value}
            deleteIcon={
              <span>
                <div className={classes.chipDeleteIcon}>
                  <DeleteIcon />
                </div>
              </span>
            }
            classes={{ root: classes.chip, deleteIcon: classes.chipDeleteIconContainer, label: classes.chipLabel }}
            {...getTagProps({ index })}
          />
        ))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={currentInput}
          placeholder={placeholder}
          classes={{
            root: textFieldStyles ?? {},
          }}
          onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
            if (enablePasting && event.clipboardData.getData('Text').includes(',')) {
              event.preventDefault()
              handlePastingData(event)
            }
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            autoFocus: textFieldAutoFocus ?? false,
            startAdornment: (
              <>
                {showSearchIcon && (
                  <Box className={classes.icon}>
                    <SearchExploreIcon />
                  </Box>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: showClear && (
              <InputAdornment position="end">
                <Button onClick={() => onClear()} classes={{ root: classes.clearButton }} disabled={loading}>
                  {loading ? <CircularProgress size={20} /> : <Clear />}
                </Button>
              </InputAdornment>
            ),
          }}
          onChange={(event) => setCurrentInput(event.target.value)}
        />
      )}
    />
  )
}

export default Autocomplete
