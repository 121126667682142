import React, { useEffect } from 'react'
import { Box, Button, Card, CardActions, CardContent, Container, Link, Typography, makeStyles } from '@material-ui/core'
import { getRoutesFromPayload } from 'src/routers/router-helpers'
import { theme } from 'src/styling'
import { redirectToLoggedOutPage } from 'src/utils/redirect-to-logged-out-page'

const useStyles = makeStyles({
  link: {
    fontSize: '1rem',
    textDecoration: 'none',
    '&:hover': {
      color: theme.colors.ui.white,
      textDecoration: 'none',
    },
  },
  backToLogin: {
    alignSelf: 'end',
    fontSize: '0.7rem',
  },
})

const PocExpiredPage: React.FC = () => {
  const classes = useStyles()
  const routes = getRoutesFromPayload()

  const subscriptionEnabled = Boolean(routes && routes['/subscription'])

  const handleBackToLogin = () => {
    redirectToLoggedOutPage()
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Card variant="outlined" style={{ padding: '16px', paddingBottom: '20px' }}>
          <CardContent style={{ justifyContent: 'center' }}>
            <Typography variant="h5" style={{ marginBottom: '8px', textAlign: 'center' }}>
              Your trial has expired!
            </Typography>
            <Typography style={{ textAlign: 'center' }}>
              Please {subscriptionEnabled ? 'upgrade your subscription' : 'contact our sales team'} to continue using
              our service.
            </Typography>
          </CardContent>
          <CardActions style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button
              variant="contained"
              component="a"
              href={subscriptionEnabled ? '/subscription' : 'mailto:sales@orpheus-cyber.com'}
              color="secondary"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {subscriptionEnabled ? 'Upgrade Subscription' : 'Contact Sales'}
            </Button>
            <Button
              variant="text"
              component="a"
              onClick={handleBackToLogin}
              style={{ marginTop: '16px' }}
              className={[classes.backToLogin].join(' ')}
            >
              Log out
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  )
}

export { PocExpiredPage }
