import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CheckoutFormPage from './checkout-form'
import UpgradePage from './upgrade-page'
import SuccessfulPaymentPage from './successful-payment'

const FreeTrialExpiredPage: React.FC = () => {
  const { freeTrialExpired } = useSelector((state) => state.auth)

  const [step, setStep] = useState('upgrade-page')
  const handleSetStep = (step: string) => setStep(step)
  const [clientSecret, setClientSecret] = useState('')
  const handleSetClientSecret = (clientSecret: string) => setClientSecret(clientSecret)

  if (step === 'upgrade-page')
    return (
      <UpgradePage
        freeTrialExpired={freeTrialExpired}
        handleSetStep={handleSetStep}
        handleSetClientSecret={handleSetClientSecret}
      />
    )

  if (step === 'checkout')
    return (
      <CheckoutFormPage clientSecret={clientSecret} handleSetStep={handleSetStep} freeTrialExpired={freeTrialExpired} />
    )

  if (step === 'successful-payment') return <SuccessfulPaymentPage />
}

export default FreeTrialExpiredPage
