import PropTypes from 'prop-types'
import React from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// TYPES
type Props = {
  direction?: 'horizontal' | 'vertical'
  height?: string
  width?: string
}

type LineProps = {
  height: string
  width: string
}

// ======
// STYLED
// ======
const pulseAnimation = keyframes`
  0% {
    background: ${theme.colors.ui.greyLight};
  }
  50% {
    background: ${theme.colors.ui.greyVeryLight};
  }
  100% {
    background: ${theme.colors.ui.greyLight};
  }
`

const Line = styled.div`
  display: inline-block;
  height: ${(props: LineProps): string => props.height};
  width: ${(props: LineProps): string => props.width};
  animation: ${pulseAnimation} 1.5s ease infinite;
  border-radius: 0.15rem;
  background: ${theme.colors.ui.greyVeryLight};
`

// =========
// COMPONENT
// =========
const GlobalLineLoader: React.FC<Props> = (props) => {
  return (
    <Line
      height={props.direction === 'horizontal' ? props.height : (Math.random() * 0.1 + 0.75) * 100 + '%'}
      width={
        props.direction === 'horizontal'
          ? props.width
            ? props.width
            : (Math.random() * 0.1 + 0.75) * 100 + '%'
          : '100%'
      }
    />
  )
}

GlobalLineLoader.propTypes = {
  /**
   * Defines the direction of the line.
   */
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Defines the line height.
   */
  height: PropTypes.string,
  /**
   * Defines the line width. If not specified, a random width between 75% and 100% is used.
   */
  width: PropTypes.string,
}

GlobalLineLoader.defaultProps = {
  direction: 'horizontal',
  height: '1.25rem',
  width: null,
}

export { GlobalLineLoader }
