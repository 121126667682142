import React from 'react'
import { theme } from 'src/styling'
import { makeStyles, Box } from '@material-ui/core'
import SelectDatepicker from 'react-select-datepicker'

// ======
// TYPES
// ======
type Props = {
  dateRange: {
    from?: Date
    to?: Date
  }
  onDateFromChange: (date: Date) => void
  onDateToChange: (date: Date) => void
  minDate: Date
  maxDate: Date
}

// ========
// STYLES
// ========
const useStyles = makeStyles({
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '.8rem',
  },
  datePickerContainer: {
    display: 'flex',
    height: 'auto',
    fontSize: theme.fontSizes.mediumLarge,
    alignItems: 'center',
  },
  titleSpan: {
    width: '3vw',
    textAlign: 'right',
    marginRight: '7px',
    fontWeight: 500,
  },
})

// =========
// COMPONENT
// =========
const GlobalDateRange: React.FC<Props> = ({ dateRange, onDateFromChange, minDate, onDateToChange, maxDate }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.datePickerContainer}>
        <span className={classes.titleSpan}>Start:</span>
        <SelectDatepicker
          format="day/month/year"
          value={dateRange.from}
          onDateChange={onDateFromChange}
          minDate={minDate}
          maxDate={maxDate}
          showLabels={false}
        />
      </Box>
      <Box className={classes.datePickerContainer}>
        <span className={classes.titleSpan}>End:</span>
        <SelectDatepicker
          format="day/month/year"
          value={dateRange.to}
          onDateChange={onDateToChange}
          minDate={minDate}
          maxDate={new Date()}
          showLabels={false}
        />
      </Box>
    </Box>
  )
}

export { GlobalDateRange }
