import { Dialog, ModalGroup } from 'components'
import React, { Suspense, useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Button, Box, TextField, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import ErrorBoundary from 'src/components/errors/error-boundary'
import { Loaders } from 'components'
import { createResource } from 'src/network/fetcher'
import NoResults from 'src/components/errors/no-results'
import { theme } from 'src/styling'
import client from 'src/network/apollo-client'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import CheckIcon from '@material-ui/icons/Check'
import { gqlResource } from 'src/types/globalTypes'
import { gql as GQL } from 'apollo-boost'
import InfiniteScroll from 'react-infinite-scroll-component'
import { persistor } from 'src/redux/store'

// ======
// TYPES
// ======
type ContentModalProps = {
  resource: gqlResource
  onClose: () => void
  isUserOrganisation: boolean
  organisationId: number
}

type SelectOrganisationModalProps = {
  onClose: () => void
  isUserOrganisation: boolean
  organisationId: number
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'grid',
    gap: '1rem',
    gridTemplateRows: '40px 60px calc(45vh + 1px) 40px',
  },
  table: {
    border: `0.5px solid ${theme.colors.ui.greyDark}`,
    '&.MuiTableContainer-root': { overflowX: 'unset' },
  },
  organisationTableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.ui.greyLight,
    },
  },
  selectedOrganisationTableRow: { backgroundColor: theme.colors.ui.greyLight },
  organisationTableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '60px',
  },
  selectOrganisationHeaders: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  resetButton: {
    color: theme.colors.ui.blue,
    fontSize: '0.7rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'end',
  },
  loadingMessage: { ...theme.customStyles.loadingMoreMessage },
})
// =========
// QUERIES
// =========
const QUERY_ORGANISATION_TENANTS = GQL`
query organisationTenants($first: Int, $after: Int, $filter: String) {
  organisationTenants(first: $first, after: $after, filter: $filter) {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        id
        tenantId
        organisationTenant {
          name
        }
      }
    }
  }
}
`
const UPDATE_USER_SPECIFIED_ORGANISATION_ID = GQL`
  mutation updateUserSpecifiedOrganisationId($selectedOrganisationId: Int) {
    updateUserSpecifiedOrganisationId(userSpecifiedOrganisationId:$selectedOrganisationId)
  }
`

// ==========
// COMPONENTS
// ==========
const FallbackModal: React.FC = () => (
  <Box>
    <ModalGroup>
      <Loaders.Line />
    </ModalGroup>
    <ModalGroup>
      <Loaders.Text lines={9} width={'100%'} />
    </ModalGroup>
  </Box>
)

const ContentModal: React.FC<ContentModalProps> = ({ resource, onClose, isUserOrganisation, organisationId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isInitialMount = useRef(true)

  const { organisationTenants } = resource.read()
  const [filteredTenants, setFilteredTenants] = useState(organisationTenants.edges)
  const [selectedOrganisationId, setSelectedOrganisationId] = useState(null)
  const [hasNextPage, setHasNextPage] = useState(organisationTenants.pageInfo.hasNextPage)
  const [after, setAfter] = useState(0)
  const [searchValue, setSearchValue] = useState(null)
  const handleOrganisationClick = (organisationId: string) => setSelectedOrganisationId(organisationId)

  if (!organisationTenants.edges.length) {
    return (
      <Box height="40vh">
        <NoResults message="No tenants found" />
      </Box>
    )
  }

  const handleFetchMoreOrganisationTenants = () => {
    client
      .query({ query: QUERY_ORGANISATION_TENANTS, variables: { first: 20, after: after + 20, filter: searchValue } })
      .then((response) => {
        const result = response.data.organisationTenants
        setFilteredTenants(filteredTenants.concat(result.edges))
        setHasNextPage(result.pageInfo.hasNextPage)
        setAfter(after + 20)
      })
  }

  const requestSearch = (searchedVal: string) => {
    setSearchValue(searchedVal)
    client
      .query({ query: QUERY_ORGANISATION_TENANTS, variables: { first: 20, after: 0, filter: searchedVal } })
      .then((response) => {
        const result = response.data.organisationTenants
        setFilteredTenants(result.edges)
        setHasNextPage(result.pageInfo.hasNextPage)
        setAfter(0)
        const infiniteScrollElement = document.getElementsByClassName('switch-tenant-infinite-scroll')
        if (infiniteScrollElement[0]) infiniteScrollElement[0].scrollTop = 0
      })
  }

  const handleConfirmOrganisationClick = () => {
    client
      .mutate({
        mutation: UPDATE_USER_SPECIFIED_ORGANISATION_ID,
        variables: { selectedOrganisationId },
      })
      .then((response) => {
        const { token, expires, firstName, organisationName } = JSON.parse(
          response.data.updateUserSpecifiedOrganisationId
        )

        Cookies.set('auth-token', token, {
          expires: expires,
        })

        dispatch({
          type: 'LOGIN',
          payload: { firstName, organisationName },
        })

        persistor.flush().then(() => window.location.reload())
      })
  }

  useEffect(() => {
    // check if component is mounted
    if (isInitialMount.current) isInitialMount.current = false
  }, [])

  return (
    <Box className={classes.container}>
      <div className={classes.selectOrganisationHeaders}>
        <h2>Switch Tenant</h2>
        <Button
          className={classes.resetButton}
          disabled={isUserOrganisation}
          onClick={() => {
            handleOrganisationClick(null)
            handleConfirmOrganisationClick()
          }}
        >
          BACK TO MY ORGANISATION
        </Button>
      </div>

      <TextField
        id="outlined-basic"
        label="Search Tenant"
        variant="outlined"
        onChange={(e) => requestSearch(e.target.value)}
        autoComplete="off"
      />
      <TableContainer className={classes.table}>
        <InfiniteScroll
          className={'switch-tenant-infinite-scroll'}
          height={'45vh'}
          dataLength={filteredTenants?.length}
          next={handleFetchMoreOrganisationTenants}
          hasMore={hasNextPage}
          loader={<h4 className={classes.loadingMessage}>Loading more...</h4>}
        >
          <Table aria-label="simple table">
            <TableBody>
              {filteredTenants.map(({ node }) => {
                const isRowSelected =
                  selectedOrganisationId === node.tenantId ||
                  (isInitialMount.current && organisationId === node.tenantId)
                return (
                  <TableRow
                    key={node.id}
                    className={
                      isRowSelected
                        ? `${classes.organisationTableRow} ${classes.selectedOrganisationTableRow}`
                        : classes.organisationTableRow
                    }
                    onClick={() => handleOrganisationClick(node.tenantId)}
                  >
                    <TableCell component="th" scope="row" className={classes.organisationTableCell}>
                      {node.organisationTenant.name}
                      {isRowSelected && <CheckIcon />}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
      <Box id="as-footer" className={classes.buttonContainer}>
        <Button variant="contained" onClick={(): void => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" onClick={(): void => handleConfirmOrganisationClick()}>
          Confirm
        </Button>
      </Box>
    </Box>
  )
}

const SelectOrganisationModal: React.FC<SelectOrganisationModalProps> = ({
  onClose,
  isUserOrganisation,
  organisationId,
}) => {
  //media query
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const resource = createResource(QUERY_ORGANISATION_TENANTS, { variables: { first: 20, after: 0 } })

  return (
    <Dialog onClose={onClose} fullScreen={fullScreen} width="700px" minHeight="100%">
      <Box padding={'18px'} height={'100%'}>
        <ErrorBoundary isWidget={false}>
          <Suspense fallback={<FallbackModal />}>
            <ContentModal
              resource={resource}
              onClose={onClose}
              isUserOrganisation={isUserOrganisation}
              organisationId={organisationId}
            />
          </Suspense>
        </ErrorBoundary>
      </Box>
    </Dialog>
  )
}

export default SelectOrganisationModal
