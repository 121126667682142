import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'src/styling'

// ===================
// Styles
// ===================

const useStyles = makeStyles({
  formLabel: { fontSize: '0.9rem', fontWeight: 500, padding: '0 0.75em 0.75em 0' },
  asterix: {
    color: theme.colors.ui.redDark,
  },
})

// ===================
// Types
// ===================

type GlobalFormLabelProps = {
  children: React.ReactNode | React.ReactChildren
  isRequired?: boolean
}

// ===================
// COMPONENT
// ===================

export const GlobalFormLabel: React.FC<GlobalFormLabelProps> = ({
  children,
  isRequired = false,
}: GlobalFormLabelProps) => {
  const classes = useStyles()
  return (
    <div className={classes.formLabel}>
      {children} {isRequired && <div className={classes.asterix}> *</div>}
    </div>
  )
}
