import styled from '@emotion/styled'

type GlobalFilterProps = {
  /**
   * The filter items to be rendered.
   */
  filtersData: [
    MultiSelectInputType | SliderType | MultiItemTextInputType | RadioGroupType | DateRangeType | CheckBoxGroupType
  ]
  /**
   * Is the filter vertical
   */
  vertical?: boolean
}

type GlobalFilterItemProps = {
  filterOptions:
    | MultiSelectInputType
    | SliderType
    | MultiItemTextInputType
    | RadioGroupType
    | DateRangeType
    | CheckBoxGroupType
  vertical: GlobalFilterProps['vertical']
}

type filterProps = {
  vertical?: boolean
}

type filterItemProps = {
  title: string
  disabled: boolean
}

type MultiSelectInputType = filterItemProps & {
  type: 'MultiSelectInput'
  currentValues: string[]
  onValuesUpdated: (vals: string[]) => void
  options: string[]
}

type SliderType = filterItemProps & {
  type: 'Slider'
  currentValues: SliderValues
  onValuesUpdated: (vals: SliderValues) => void
  options: string[]
  start: number
  end: number
}

type SliderValues = {
  min: number
  max: number
}

type MultiItemTextInputType = filterItemProps & {
  type: 'MultiItemTextInput'
  currentValues: string[]
  onValuesUpdated: (vals: string[]) => void
}

type RadioGroupType = filterItemProps & {
  type: 'RadioGroup'
  selected: string
  onValuesUpdated: (vals: string) => void
  options: [
    {
      label: string
      id: number | string
    }
  ]
}

type DateRangeType = filterItemProps & {
  type: 'DateRange'
  startDate: any
  endDate: any
  selected: string
  onDatesChange: (vals: any) => void
  onFocusChange: () => void
  focusedInput: any
}

type CheckBoxGroupType = filterItemProps & {
  type: 'CheckBoxGroup'
  options: any
  disabled: boolean
  currentValues: Set<number>
  onValuesUpdated: (vals: Set<number>) => void
}

type FilterItemContainerProps = {
  vertical: boolean
  disabled: boolean
}

const FilterItemContainer = styled.div<FilterItemContainerProps>`
  max-width: 500px;
  width: ${({ vertical }): string => (vertical ? '20rem' : '80%')};
  height: max-content;
  padding: ${({ vertical }): string => (vertical ? '1.2rem 0.5rem 0.8rem 0.5rem' : '1.2rem 1.2rem 0.8rem 1.2rem')};
  ${({ disabled }): string =>
    disabled
      ? `
  opacity: 0.6;
  pointer-events:none;
  `
      : ''}
`

const FilterTitle = styled.div`
  padding-bottom: 1rem;
`

const ContentContainer = styled.div`
  max-height: 25rem;
  overflow-y: scroll;
`

export type {
  GlobalFilterProps,
  GlobalFilterItemProps,
  filterProps,
  filterItemProps,
  MultiSelectInputType,
  SliderType,
  SliderValues,
  MultiItemTextInputType,
  RadioGroupType,
  DateRangeType,
  CheckBoxGroupType,
}
export { FilterItemContainer, FilterTitle, ContentContainer }
