import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// =====
// TYPES
// =====
type ModalTabProps = {
  children: React.ReactNode
  /**
   * Title of tab
   */
  title: string
}

// ======
// STYLES
// ======
const useStyles = makeStyles({
  tabBody: {
    overflowY: 'auto',
    height: '90%',
    padding: '1rem 0.5rem 0 0.5rem',
  },
})

// =========
// COMPONENT
// =========
export const GlobalModalTab: React.FC<ModalTabProps> = ({ children }: ModalTabProps) => {
  const classes = useStyles()

  return (
    <div
      className={classes.tabBody}
      onWheel={(e): void => {
        e.stopPropagation()
      }}
    >
      {children}
    </div>
  )
}
