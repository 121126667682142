import React, { useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, Container, Link, Typography, makeStyles } from '@material-ui/core'
import { theme } from 'src/styling'
import { redirectToLoggedOutPage } from 'src/utils/redirect-to-logged-out-page'
import axios from 'axios'
import { Loaders } from 'src/components'
import SnackBar from 'src/context/snackbar'

const useStyles = makeStyles({
  link: {
    fontSize: '1rem',
    textDecoration: 'none',
    width: '240px',
    height: '40px',
    '&:hover': {
      color: theme.colors.ui.white,
      textDecoration: 'none',
    },
  },
  backToLogin: {
    alignSelf: 'end',
    fontSize: '0.7rem',
  },
})

const UpgradePage = ({ freeTrialExpired, handleSetStep, handleSetClientSecret }) => {
  const classes = useStyles()
  const [loadingClientSecret, setLoadingClientSecret] = useState(false)
  const { enqueueSnackbar } = SnackBar.Consumer()

  const handleClickUpgrade = async () => {
    setLoadingClientSecret(true)
    try {
      const { data } = await axios.post('/payment-portal-lite/subscription', { ...freeTrialExpired })
      handleSetClientSecret(data?.clientSecret)
      handleSetStep('checkout')
    } catch (error) {
      enqueueSnackbar('An unexpected error occurred. Please try again.', {
        variant: 'error',
      })
    }
    setLoadingClientSecret(false)
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Card variant="outlined" style={{ padding: '16px', paddingBottom: '20px' }}>
          <CardContent style={{ justifyContent: 'center' }}>
            <Typography variant="h5" style={{ marginBottom: '8px', textAlign: 'center' }}>
              Your trial has expired
            </Typography>
            <Typography style={{ textAlign: 'center' }}>
              Please confirm your subscription to continue using our service.
            </Typography>
          </CardContent>
          <CardActions style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button
              variant="contained"
              component="a"
              color="secondary"
              rel="noopener noreferrer"
              onClick={handleClickUpgrade}
              className={classes.link}
            >
              {loadingClientSecret ? <Loaders.Spinner size={24} /> : 'Confirm Subscription'}
            </Button>
            <Button
              variant="text"
              component="a"
              onClick={() => redirectToLoggedOutPage()}
              style={{ marginTop: '16px' }}
              className={classes.backToLogin}
            >
              Log out
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  )
}

export default UpgradePage
