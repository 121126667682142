// Replace with https://material-ui.com/components/text-fields/
import React, { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { theme } from 'src/styling'
import { makeStyles, Input } from '@material-ui/core'

// =====
// TYPES
// =====
type TextInputProps = {
  currentInput?: string
  onInputChange?: (val: string) => void
  filterInputValues?: { id?: number; title: string; isKeyword?: boolean }[]
  onFilterInputValuesChange: Dispatch<SetStateAction<(string | number | object | { id: number; title: string })[]>>
  onKeywordInputChange?: (isSearchAllowed: boolean) => void
  onSubmit: () => void
  onCursor?: Dispatch<SetStateAction<number>>
  cursor?: number
  dropDownListSize?: number
  dropDownListElementRef?: React.MutableRefObject<HTMLDivElement[]>
  dropDownOpen?: boolean
  onDropDownSelection?: () => void
  onDropDownOpen: (dropDownState: boolean) => void
  autocomplete?: 'off' | 'on'
  disabled?: boolean
  placeholder?: string
  type?: 'email' | 'password' | 'text'
  inputValidator?: 'alpha' | 'alphanumeric' | 'domain' | 'numeric' | 'ipAddress' | 'stopwords' | 'url'
  value?: string
}

// ======
// STYLES
// ======
const useStyles = makeStyles({
  errorMessage: {
    textAlign: 'left',
    position: 'relative',
    top: ' 0.45rem',
    color: theme.colors.ui.red,
    minWidth: '315px',
  },
  textInput: {
    width: ' 100%',
    backgroundColor: theme.colors.ui.white,
    height: ' 2.52rem',
    lineHeight: ' 1.35rem',
    padding: '0.45rem 0.9rem',
    borderWidth: 0.9,
    fontSize: theme.fontSizes.mediumLarge,
  },
  noBorder: {
    border: 'none',
  },
})

// =========
// COMPONENT
// =========
const GlobalTextInput: React.FC<TextInputProps> = (props) => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.onDropDownOpen(true)
    setValue(event.currentTarget.value)
    props.onInputChange(event.currentTarget.value.toString())
  }

  useEffect(() => {
    const cursor = props.dropDownListElementRef.current[0] ? 0 : 1
    props.onCursor(cursor)
    handleArrowKeyNavigation(cursor + 1)
  }, [`${props.dropDownListElementRef.current}`])

  const handleArrowKeyNavigation = (index: number) => {
    if (props?.dropDownListElementRef?.current[index]) {
      props.dropDownListElementRef.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }

  const handleEnterKeyPress = () => {
    if (!props.filterInputValues.find((currentValue) => currentValue.title === props.currentInput)) {
      if (props.dropDownOpen && props.dropDownListSize) props.onDropDownSelection()
      else if (props.dropDownOpen && props.currentInput && !props.dropDownListSize) props.onKeywordInputChange(false)
      else if (!props.dropDownOpen && !props.currentInput) {
        props.onSubmit()
      }
    }
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleEnterKeyPress()
    } else if (event.key === 'Tab') {
      event.preventDefault()
      props.onKeywordInputChange(false)
    } else if (event.key === 'Backspace') {
      if (!props.currentInput)
        props.onFilterInputValuesChange(
          props.filterInputValues.filter(
            (values) => values.title !== props.filterInputValues[props.filterInputValues.length - 1].title
          )
        )
    } else if (event.key === 'ArrowUp' && props.cursor > 0) {
      props.onCursor(props.cursor - 1)
      handleArrowKeyNavigation(props.cursor - 2)
    } else if (
      event.key === 'ArrowDown' &&
      props.cursor < (props.currentInput ? props.dropDownListSize : props.dropDownListSize - 1)
    ) {
      props.onCursor(props.cursor + 1)

      handleArrowKeyNavigation(props.cursor + 2)
    } else if (event.key === 'Escape') {
      props.onDropDownOpen(!props.dropDownOpen)
    }
    // else {
    //   const cursor = props.dropDownListElementRef.current[0] ? 0 : 1
    //   props.onCursor(cursor)
    //   handleArrowKeyNavigation(cursor + 1)
    // }
  }

  useEffect((): void => {
    const clearInput = (): void => {
      setValue('')
      props.onInputChange('')
    }
    clearInput()
  }, [props.filterInputValues])

  return (
    <>
      <Input
        value={value}
        disableUnderline={true}
        className={classes.textInput}
        autoComplete={props.autocomplete}
        disabled={props.disabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event)}
        placeholder={props.placeholder}
        type={props.type}
        onKeyDown={handleOnKeyDown}
      />
    </>
  )
}

GlobalTextInput.propTypes = {
  /**
   * Defines whether the browser can supply autocompletions
   */
  autocomplete: PropTypes.oneOf(['off', 'on']),
  /**
   * If true, the input element will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The text displayed within the input when empty. If no value is provided but a validator is provided, the default for the validator is used.
   */
  placeholder: PropTypes.string,
  /**
   * The input type.
   */
  type: PropTypes.oneOf(['email', 'password', 'text']),
  /**
   * The input validator.
   */
  inputValidator: PropTypes.oneOf(['alpha', 'alphanumeric', 'domain', 'numeric', 'ipAddress', 'url']),
  /**
   * The value of the input.
   */
  value: PropTypes.string,
  /**
   * Input style variant.
   */
}

GlobalTextInput.defaultProps = {
  autocomplete: 'on',
  disabled: false,
  placeholder: null,
  type: 'text',
  inputValidator: null,
  value: '',
}

export { GlobalTextInput }
