import React from 'react'
import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import NoResultsIcon from 'src/images/errors/no-results.png'
import { theme } from 'src/styling'

// ========
// TYPES
// ========
type Props = {
  message?: string | undefined
  imgHeight?: string
  disableImage?: boolean
}

// =========
// STYLES
// =========
const useStyles = makeStyles<Theme, Props>({
  noResultImageContainer: {
    width: '60px',
  },
  image: { width: '3.5rem', height: '3.5rem' },

  message: {
    fontSize: theme.fontSizes.large,
    fontWeight: 500,
    padding: '0.9rem',
    textAlign: 'center',
  },
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
})

// ============
// COMPONENET
// ============
const NoResults: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  return (
    <Box className={classes.container}>
      {!props.disableImage && (
        <div className={classes.noResultImageContainer}>
          <img className={classes.image} src={NoResultsIcon} />
        </div>
      )}

      <div className={classes.message}>{props.message}</div>
    </Box>
  )
}

export default NoResults
