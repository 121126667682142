import PropTypes from 'prop-types'
import React from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// TYPES
interface GuageChartLoaderInterface {
  height?: string | number
  width?: string | number
}

// ======
// STYLED
// ======
const pulseAnimation = keyframes`
  0% {
    stroke: ${theme.colors.ui.greyLight};
  }
  50% {
    stroke: ${theme.colors.ui.greyVeryLight};
  }
  100% {
    stroke: ${theme.colors.ui.greyLight};
  }
`

const GaugeContainer = styled.div`
  margin: 0 0.5rem;
`

const SVGContainer = styled.svg`
  height: ${(props): string | number => props.height};
  width: ${(props): string | number => props.width};
`

const GaugePath = styled.path`
  fill: none;
  stroke-width: 30;
  stroke-miterlimit: 10;
  strokelinejoin: 'round';
  animation: ${pulseAnimation} 1.5s ease infinite;
`

//Gauge line
const GaugeSVG =
  'M135,318.6c-31-29.8-50.3-71.7-50.3-118c0-90.4,73.3-163.7,163.7-163.7s163.7,73.3,163.7,163.7c0,46.4-19.3,88.3-50.3,118'

// =========
// COMPONENT
// =========
const GlobalGaugeChartLoader: React.FC<GuageChartLoaderInterface> = (props) => {
  return (
    <GaugeContainer>
      <SVGContainer height={props.height} width={props.width} viewBox={'70 22 357 357'}>
        <GaugePath strokeLinecap="round" d={GaugeSVG} />
      </SVGContainer>
    </GaugeContainer>
  )
}

GlobalGaugeChartLoader.propTypes = {
  /**
   * Height of component
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Width of component
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

GlobalGaugeChartLoader.defaultProps = {
  height: '100%',
  width: '100%',
}

export { GlobalGaugeChartLoader }
