import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { theme } from 'src/styling'

// =======
// STYLES
// =======
const useStyles = makeStyles({
  choroplethScale: {
    width: '300px',
    position: 'absolute',
    bottom: '64px',
    right: '32px',
    zIndex: 401,
  },
  choroplethScaleHeader: {
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '3.6px',
    fontSize: theme.fontSizes.mediumLarge,
  },
  choroplethScaleRail: {
    width: '100%',
    height: '3.6px',
    background: 'red',
  },
  choroplethScaleValues: {
    position: 'relative',
  },

  choroplethScaleMin: {
    position: 'absolute',
    left: 0,
    top: 0,
  },

  choroplethScaleMax: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

// =======
// TYPES
// =======
type ChoroplethScaleProps = {
  text: string
  start: string
  stop: string
  scale: {
    domain: () => number[]
  }
}

// =========
// COMPONENT
// =========
export const ChoroplethScale: React.FC<ChoroplethScaleProps> = ({ start, stop, scale, text }) => {
  const classes = useStyles()
  const [min, max] = scale.domain()
  const background = `linear-gradient(-90deg, ${start}, ${stop})`
  return (
    <div className={classes.choroplethScale}>
      <div className={classes.choroplethScaleHeader}>{text}</div>
      <div className={classes.choroplethScaleRail} style={{ background }}></div>
      <div className={classes.choroplethScaleValues}>
        <span className={classes.choroplethScaleMin}>{min}</span>
        <span className={classes.choroplethScaleMax}>{max}</span>
      </div>
    </div>
  )
}
