import { mq } from 'src/styling'
import React from 'react'
import styled from '@emotion/styled'
import { getSeverityColor } from 'src/utils/colors'

// =====
// TYPES
// =====
type GlobalScorecardProps = {
  /**
   * Font size
   */
  fontSize?: string
  /**
   * Scorecard height
   */
  height?: string
  /**
   * Line height
   */
  lineHeight?: string
  /**
   * Defines the range from worst to best
   */
  range: [number, number]
  /**
   * If true, values will be rounded to the nearest whole number
   */
  roundValues?: boolean
  /**
   * The value on the scorecard.
   */
  value: number
  /**
   * Scorecard variant.
   */
  variant?: 'default' | 'noBackground' | 'label'
  /**
   * Scorecard width
   */
  width?: string
  margin?: string
}

type ScorecardProps = {
  color: string
  fontSize: GlobalScorecardProps['fontSize']
  height: GlobalScorecardProps['height']
  lineHeight: GlobalScorecardProps['lineHeight']
  value: GlobalScorecardProps['value']
  variant: GlobalScorecardProps['variant']
  width: GlobalScorecardProps['width']
  margin: GlobalScorecardProps['margin']
}

// ======
// STYLED
// ======
const Scorecard = styled.div<ScorecardProps>`
  width: ${(props): string =>
    props.width
      ? props.width
      : props.variant === 'noBackground'
      ? 'fit-content'
      : props.variant === 'label'
      ? '6rem'
      : '4rem'};
  height: ${(props): string =>
    props.height ? props.height : props.variant === 'noBackground' ? 'fit-content' : 'fit-content'};
  padding: ${(props): string => (props.variant === 'noBackground' ? '0' : '0.45rem')};
  margin: ${(props): string => (props.margin ? props.margin : '0')};
  text-align: center;
  border-radius: 0.15rem;
  background: ${(props): string => (props.variant === 'noBackground' ? 'none' : props.color)};
  color: ${(props): string => (props.variant === 'noBackground' ? props.color : '#fff')};
  font-size: ${(props): string =>
    props.fontSize ? props.fontSize : props.variant === 'noBackground' ? '1.35rem' : '0.9rem'};
  line-height: ${(props): string =>
    props.lineHeight ? props.lineHeight : props.variant === 'noBackground' ? '1.35rem' : '0.9rem'};
  font-weight: 500;

  ${mq.sm} {
    font-size: ${(props): string =>
      props.fontSize ? props.fontSize : props.variant === 'noBackground' ? '1.575rem' : '1.035rem'};
    line-height: ${(props): string =>
      props.lineHeight ? props.lineHeight : props.variant === 'noBackground' ? '1.575rem' : '1.035rem'};
  }
`

const LABELS = ['Very Low', 'Low', 'Medium', 'High', 'Very High']

// =========
// COMPONENT
// =========
export const GlobalScorecard: React.FC<GlobalScorecardProps> = ({
  fontSize,
  height,
  lineHeight,
  range,
  roundValues = true,
  value,
  variant = 'default',
  width,
  margin,
}: GlobalScorecardProps) => {
  const [min, max] = range

  if (roundValues) {
    value = Math.round(value)
  }

  const color = getSeverityColor(value, min, max)

  return (
    <Scorecard
      color={color}
      width={width}
      value={value}
      variant={variant}
      fontSize={fontSize}
      lineHeight={lineHeight}
      height={height}
      margin={margin}
    >
      {variant === 'label' ? LABELS[value] : value > 0 ? value : '-'}
    </Scorecard>
  )
}
