import moment from 'moment'
const constants = Object.freeze({
  DATE_RANGE_FROM: '2013-01-01',
  INTREP_DATE_RANGE_FROM: '2007-01-01',
  INTELLIGENCE_PAGE_DEFAULT_DATE_RANGE_FROM: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  ATTACK_SURFACE_FILTER_DATE_RANGE_FROM: moment().subtract(5, 'years').format('YYYY-MM-DD'),
  ALERT_TYPES: {
    CRR_SCORE: 1,
    VULNERABILITIES: 2,
    RANSWOMARE: 3,
    THIRD_PARTY_CRR_SCORE: 4,
    THIRD_PARTY_VULNERABILITIES: 5,
    THIRD_PARTY_RANSWOMARE: 6,
    PREMIUM_SCAN_FINISHED: 7,
  },
  WIDESPREAD_EXPLOITATION_TOOLTIP_TEXT:
    'Orpheus has directly observed significant numbers of malware samples / malicious activity on honeypots attempting to exploit this CVE.',
  CONFIRMED_EXPLOITATION_TOOLTIP_TEXT:
    'An authoritative source has confirmed that this CVE has been exploited by malicious actors. However, Orpheus has not yet directly observed this exploitation.',
  NOT_EXPLOITED_EXPLOITATION_TOOLTIP_TEXT: 'We have no definitive evidence of exploitation for this CVE.',
  PROFILE_TYPES: {
    NATION_STATE: 1,
    CRIMINAL: 2,
    ACTIVIST: 3,
    NATION_STATE_THREAT_ACTORS: 4,
    CYBER_CRIMINAL_THREAT_ACTORS: 5,
    CYBER_ACTIVIST_THREAT_ACTORS: 6,
  },
  USER_ITEM_TYPES: {
    VULNERABILITY: 4,
    //TODO: add other user_item_types and update respective modals to use these constants
  },
})

export default constants
