import PropTypes from 'prop-types'
import React from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// TYPES
interface SpinnerInterface {
  size?: number
}

// ======
// STYLED
// ======
const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
type SpinnerProps = {
  size: number
}

const Spinner = styled.div`
  height: ${(props: SpinnerProps): string => `${props.size}px`};
  width: ${(props: SpinnerProps): string => `${props.size}px`};
  border: ${(props: SpinnerProps): string => `${props.size / 8}px`} solid ${theme.colors.ui.greyVeryLight};
  border-top: ${(props: SpinnerProps): string => `${props.size / 8}px`} solid ${theme.colors.ui.grey};
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`

// =========
// COMPONENT
// =========
const GlobalSpinnerLoader: React.SFC<SpinnerInterface> = (props) => {
  return (
    <SpinnerContainer>
      <Spinner size={props.size} />
    </SpinnerContainer>
  )
}

GlobalSpinnerLoader.propTypes = {
  /**
   * The height and width of the spinner, in px.
   */
  size: PropTypes.number,
}

GlobalSpinnerLoader.defaultProps = {
  size: 40,
}

export { GlobalSpinnerLoader }
