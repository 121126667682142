import { GlobalAreaChartLoader as AreaChart } from './area-chart/area-chart'
import { GlobalBarChartLoader as BarChart } from './bar-chart/bar-chart'
import { GlobalFormLoader as Form } from './form/form'
import { GlobalGaugeChartLoader as GaugeChart } from './gauge-chart/gauge-chart'
import { GlobalLineLoader as Line } from './line/line'
import { GlobalSpinnerLoader as Spinner } from './spinner/spinner'
import { GlobalTableLoader as Table } from './table/table'
import { GlobalTextLoader as Text } from './text/text'

export const Loaders = {
  AreaChart,
  BarChart,
  Form,
  GaugeChart,
  Line,
  Spinner,
  Table,
  Text,
}
