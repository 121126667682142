import Error from 'src/components/errors/error'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as WheelBarrow } from 'src/images/icons/wheelbarrow.svg'
import { makeStyles } from '@material-ui/core/styles'

// ======
// TYPES
// ======
type WidgetType = {
  children?: React.ReactNode | React.ReactChildren
  variant?: 'default' | 'transparent'
  disabled?: boolean
  overflow?: 'visible' | 'hidden'
  error?: string
}

interface WidgetExport extends React.FC<WidgetType> {
  Header?: React.FC
}

// ======
// STYLED
// ======

const useStyles = makeStyles({
  paper: {
    padding: '18px',
    justifyContent: 'center',
    borderRadius: '0.15rem',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  container: {
    height: '100%',
  },
  containerDisabled: {
    filter: 'blur(9px) grayscale(100%)',
    pointerEvents: 'none',
  },
  iconContainer: {
    width: '90px',
    margin: '0.9rem',
    filter: 'drop-shadow(13.5px 13.5px 9px rgba(0, 0, 0, 0.7))',
  },
  disabledOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    zIndex: 99,
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    padding: '0.9rem',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '9px',
    fontWeight: 400,
    textAlign: 'center',
  },
})

// =========
// COMPONENT
// =========
const GlobalWidget: WidgetExport = (props) => {
  const classes = useStyles(props)

  return (
    <Paper className={[classes.paper, props.variant == 'transparent' && classes.transparent].join(' ')} elevation={3}>
      {props.disabled && (
        <div className={classes.disabledOverlay}>
          <div className={classes.iconContainer}>
            <WheelBarrow />
          </div>
          <div className={classes.title}>This widget is still under construction.</div>
          <div className={classes.subTitle}>But we are working hard to get it ready as soon as possible!</div>
        </div>
      )}

      {props.error && <Error errorCode={props.error} />}

      <div className={[classes.container, props.disabled && classes.containerDisabled].join(' ')}>{props.children}</div>
    </Paper>
  )
}

GlobalWidget.propTypes = {
  /**
   * The content rendered in the widget body.
   */
  children: PropTypes.node,
  /**
   * The widget style variant
   */
  variant: PropTypes.oneOf(['default', 'transparent']),
  /**
   * If widget is disabled
   */
  disabled: PropTypes.bool,
}

GlobalWidget.defaultProps = {
  children: null,
  variant: 'default',
  disabled: false,
}

export { GlobalWidget }
