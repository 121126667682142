import { Autocomplete, DateRange } from 'components'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  recordUserInteraction,
  userAddedSearchTerm,
  userClearedSearch,
  userSubmittedSearch,
  userOpenedDatePicker,
} from 'src/services/newrlic'
import { Button, Box, Popover } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import {
  applyFiltersToUrl,
  getAttackSurfaceFiltersFromUrl,
  getAttackSurfaceFilterDateRange,
  getMinDateRange,
} from 'src/utils/filters'
import { theme } from 'src/styling'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import moment from 'moment'
import constants from 'src/constants'

// ======
// TYPES
// ======
type Props = {
  withStatus: boolean
  userItemType: number
  exploitedByRansomware: boolean
}

export type AttackSurfaceAutocompleteDataItem = {
  title: string
  type: string
}

export type AttackSurfaceFiltersType = {
  ports?: string[]
  domains?: string[]
  ips?: string[]
  cves?: string[]
  ccns?: string[]
  ssls?: string[]
  icns?: string[]
  databases?: string[]
}

// ======
// STYLES
// ======
const useStyles = makeStyles(() => ({
  autoCompletePopperStyles: { margin: '4px 0' },
  textFieldStyles: {
    border: '0.9px solid #d5d5d5',
    '&:hover': { borderColor: '#777777' },
    '& .MuiInputBase-input': { paddingLeft: '10px !important' },
  },
  container: {
    display: 'flex',
    gap: '8px',
  },
  autocompleteContainer: {
    flex: 1,
    border: `0.05rem solid ${theme.colors.ui.greyLight}`,
  },
  searchButton: {
    padding: 8,
    color: theme.colors.ui.blueDark,
    fontSize: '0.8rem',
    backgroundColor: theme.colors.ui.greyVeryLight,
    transition: 'all 0.2 ease-in-out',
    '&:hover': {
      backgroundColor: theme.colors.ui.blueLight,
      color: theme.colors.ui.greyVeryLight,
    },
  },
  arrowForwardIcon: { fontSize: '14px', position: 'relative', top: '2.7px', margin: '0 4.5px' },
  filterInput: {
    padding: '15px 0',
    fontWeight: 1,
    backgroundColor: theme.colors.ui.white,
    border: `0.09rem solid ${theme.colors.ui.greyLight}`,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      border: `0.9px solid ${theme.colors.ui.blueDark}`,
    },
    fontSize: theme.fontSizes.mediumLarge,
  },
}))

// =========
// FUNCTIONS
// =========
const createDropdownOptionsArray = (
  data: AttackSurfaceFiltersType,
  selectedOptions: AttackSurfaceAutocompleteDataItem[]
) => {
  const allOptionsArray = []
  if (data?.ports)
    allOptionsArray.push(
      ...data?.ports?.map((port) => {
        return { title: port, type: 'port' }
      })
    )

  if (data?.ips)
    allOptionsArray.push(
      ...data?.ips?.map((ip) => {
        return { title: ip, type: 'ip' }
      })
    )

  if (data?.domains)
    allOptionsArray.push(
      ...data?.domains?.map((domain) => {
        return { title: domain, type: 'domain' }
      })
    )

  if (data?.cves)
    allOptionsArray.push(
      ...data?.cves?.map((cve) => {
        return { title: cve, type: 'cve' }
      })
    )

  if (data?.ccns)
    allOptionsArray.push(
      ...data?.ccns?.map((ccn) => {
        return { title: ccn, type: 'ccn' }
      })
    )

  if (data?.icns)
    allOptionsArray.push(
      ...data?.icns?.map((icn) => {
        return { title: icn, type: 'icn' }
      })
    )

  if (data?.ssls)
    allOptionsArray.push(
      ...data?.ssls?.map((ssl) => {
        return { title: ssl, type: 'ssl' }
      })
    )

  if (data?.databases)
    allOptionsArray.push(
      ...data?.databases?.map((database) => {
        return { title: database, type: 'database' }
      })
    )

  return allOptionsArray?.filter(({ title }) => !selectedOptions?.map(({ title }) => title).includes(title))
}

const getDropdownOptionLabel = (value: AttackSurfaceAutocompleteDataItem) => {
  if (value?.type === 'port' || value?.type === 'domain') {
    const Db = `${value.title} [${value.type[0].toUpperCase()}${value.type.slice(1)}]`
    return Db
  }
  if (value?.type === 'cve' || value?.type === 'ip') return `${value.title} [${value.type.toUpperCase()}]`
  if (value?.type === 'icn') return `${value.title} [Issuer Common Name]`
  if (value?.type === 'ccn') return `${value.title} [Certificate Common Name]`
  if (value?.type === 'ssl') return `${value.title} [SSL Versions]`
  if (value?.type === 'database') return `${value.title} [Suspected Database]`
  return value.title
}

const convertToArrayOfTypes = (arr: { title: string; type: 'port' | 'ip' | 'domain' | 'cve' }[]) => {
  const result: AttackSurfaceFiltersType = {}
  arr.forEach(({ title, type }) => {
    const key = `${type}s`
    result[key] = [...(result[key] || []), title]
  })
  return result
}

const isDomain = (value: string) => {
  // Ensure the domain follows basic domain name rules using regex
  const domainPattern =
    /^(?!-)(?:[a-zA-Z\d-]{0,62}[a-zA-Z\d]\.)*(?:(?:[a-zA-Z\d-]{0,62}[a-zA-Z\d])\.(?:[a-zA-Z]{2,63}|xn--[a-zA-Z\d]{2,59}))$/
  return domainPattern.test(value)
}

const isValidIpAddress = (value: string) => {
  const ipAddressRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/
  return ipAddressRegex.test(value)
}

const isCve = (value: string) => {
  const cvePattern = /^CVE-\d{4}-\d{4,}$/
  return cvePattern.test(value)
}

const identifyType = (value: string) => {
  if (isValidIpAddress(value)) {
    return 'ip'
  } else if (!isNaN(Number(value)) && Number(value) > 0 && Number(value) < 65536) {
    return 'port'
  } else if (isDomain(value)) {
    return 'domain'
  } else if (isCve(value)) {
    return 'cve'
  } else {
    return 'unknown'
  }
}

const getPastedValues = (event: React.ClipboardEvent<HTMLInputElement>, delimiter: string | RegExp) => {
  const pastedData = event.clipboardData.getData('Text')
  const pastedValues = pastedData
    .split(delimiter)
    .map((value) => value.trim())
    .filter(Boolean)
  return pastedValues
}

const formatDateRange = (dateRange) =>
  dateRange?.from && dateRange?.to
    ? {
        from: moment(dateRange.from).format('YYYY-MM-DD'),
        to: moment(dateRange.to).format('YYYY-MM-DD'),
      }
    : {}

// =========
// COMPONENT
// =========
const AutocompleteDataAttackSurface: React.FC<Props> = ({
  withStatus,
  userItemType,
  exploitedByRansomware,
  filterQuery,
  filterPlaceholder,
  privacyDataFilters,
  showDatePicker,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  // date picker state
  const [anchorEl, setAnchorEl] = useState(null) // Handle date range popup open
  const duration = getAttackSurfaceFilterDateRange()
  const defaultDateRange = {
    from: new Date(constants.ATTACK_SURFACE_FILTER_DATE_RANGE_FROM),
    to: new Date(),
  }
  const minDate = getMinDateRange(true, true)
  const [dateRange, setDateRange] = useState(
    Object.keys(duration).length
      ? {
          from: new Date(duration.from),
          to: new Date(duration.to),
        }
      : defaultDateRange
  )
  const [isDefaultDate, setIsDefaultDate] = useState(true)

  useEffect(() => {
    const newFilters = getAttackSurfaceFiltersFromUrl(location.search, true) as AttackSurfaceAutocompleteDataItem[]
    if (newFilters && Object.keys(newFilters).length > 0) setIsFilterApplied(true)
    setFilterInputValues(newFilters)
  }, [location.search])

  const [currentInput, setCurrentInput] = useState('') // When a user starts typing in the search
  const trimmedInput = currentInput.trim()
  const [filterInputValues, setFilterInputValues] = useState([]) // Array of items in the search

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const handleOpenDropdown = () => trimmedInput?.length && setIsDropdownOpen(true)
  const handleCloseDropdown = () => setIsDropdownOpen(false)

  const [dropdownOptions, setDropdownOptions] = useState([])

  const handleInputOnChange = (event: React.ChangeEvent<{}>, values: AttackSurfaceAutocompleteDataItem[]) => {
    recordUserInteraction(userAddedSearchTerm)

    const filteredInputValues = values.reduce((acc, currentValue) => {
      const valueAlreadyExists = acc?.find(
        (accValue) => accValue.title === currentValue.title && accValue.type === currentValue.type
      )
      if (!valueAlreadyExists) acc?.push(currentValue)
      return acc
    }, [])

    setFilterInputValues(filteredInputValues)
  }
  const handleCurrentInputChange = (val: string = null): void => {
    setCurrentInput(val)
  }

  const handlePastingData = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValues = getPastedValues(event, /[ ,]/)
    const transformedValues = pastedValues.map((data) => ({ title: data, type: identifyType(data) }))
    const validData = transformedValues.filter((item) => item.type !== 'unknown')

    const combinedData = [...filterInputValues, ...validData]

    const uniqueData = combinedData.reduce(
      (acc: AttackSurfaceAutocompleteDataItem[], currentValue: AttackSurfaceAutocompleteDataItem) => {
        if (!acc.some((item) => item.title === currentValue.title && item.type === currentValue.type)) {
          acc.push(currentValue)
        }
        return acc
      },
      []
    )

    setFilterInputValues(uniqueData)
  }

  const handleSubmitSearch = () => {
    recordUserInteraction(userSubmittedSearch)
    setIsDropdownOpen(false)
    updateUrl(filterInputValues)
  }

  const handleClear = () => {
    recordUserInteraction(userClearedSearch)
    setFilterInputValues([])
    updateUrl(null)
  }

  const updateUrl = (filterValues) => {
    if (!filterValues) {
      const params = new URLSearchParams()

      if (withStatus) params.set('actionedtable', 'true')
      if (exploitedByRansomware) params.set('ransomware', 'true')
      if (privacyDataFilters.length > 0) params.set('types', privacyDataFilters.join(','))

      const queryString = params.toString()
      const newUrl = `${location.pathname}${queryString ? '?' + queryString : ''}`
      history.push(newUrl)
    }

    const dateRangeFormatted = !isDefaultDate && formatDateRange(dateRange)
    const values = convertToArrayOfTypes(filterValues)
    const search = applyFiltersToUrl({
      ...values,
      isActionedTable: withStatus,
      exploitedByRansomware,
      privacyTypes: privacyDataFilters,
      ...(!isDefaultDate && { dateRange: dateRangeFormatted }),
    })
    history.push(`${location.pathname}?${search}`)
  }

  const handleClickDateRange = (event) => {
    recordUserInteraction(userOpenedDatePicker)
    setAnchorEl(event.currentTarget)
  }
  const handleCloseDateRange = () => setAnchorEl(null)

  //autocomplete data
  const { loading, error, data } = useQuery(filterQuery, {
    variables: {
      first: 10,
      withStatus,
      userItemType,
      filter: { name: { startsWith: trimmedInput } },
      filterByRansomware: exploitedByRansomware,
      duration: dateRange,
    },
    skip: !trimmedInput,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    const dateRangeFormatted = formatDateRange(dateRange)
    setIsDefaultDate(
      JSON.stringify(dateRangeFormatted) ===
        JSON.stringify({
          from: moment(defaultDateRange.from).format('YYYY-MM-DD'),
          to: moment(defaultDateRange.to).format('YYYY-MM-DD'),
        })
    )
  }, [dateRange])

  //array of filtered data from recent searches and new data
  useEffect(() => {
    if (trimmedInput.length) {
      setIsDropdownOpen(true)
    } else {
      setIsDropdownOpen(false)
    }
  }, [currentInput])

  useEffect(() => {
    if (data) setDropdownOptions(createDropdownOptionsArray(data.data, filterInputValues))
  }, [data, filterInputValues])

  return (
    <div className={classes.container}>
      <div className={classes.autocompleteContainer}>
        <Autocomplete
          onSubmit={handleSubmitSearch}
          selectedOptions={filterInputValues}
          loading={loading}
          options={dropdownOptions}
          onChange={handleInputOnChange}
          currentInput={currentInput}
          setCurrentInput={handleCurrentInputChange}
          showClear={isFilterApplied}
          onClear={handleClear}
          getOptionLabel={(option) => getDropdownOptionLabel(option)}
          renderTagValues={filterInputValues?.map((value: any) => value.title)}
          placeholder={filterInputValues?.length ? '' : filterPlaceholder}
          textFieldAutoFocus={false}
          textFieldStyles={classes.textFieldStyles}
          autoCompletePopperStyles={classes.autoCompletePopperStyles}
          isDropdownOpen={isDropdownOpen}
          onOpenDropdown={handleOpenDropdown}
          onCloseDropdown={handleCloseDropdown}
          freeSolo={false}
          error={error}
          clearOnBlur={true}
          enablePasting={true}
          handlePastingData={handlePastingData}
          filterOptions={(dropdownOptions) => dropdownOptions}
        />
      </div>
      {showDatePicker ? (
        <Box width={'16vw'} className={classes.filterInput} onClick={handleClickDateRange}>
          {dateRange?.from && dateRange?.to ? (
            <span id={'date-picker'}>
              {moment(dateRange.from).format('DD-MM-YYYY')}
              <ArrowForwardIcon className={classes.arrowForwardIcon} />
              {moment(dateRange.to).format('DD-MM-YYYY')}
            </span>
          ) : (
            <span>
              Start Date <ArrowForwardIcon className={classes.arrowForwardIcon} />
              End Date
            </span>
          )}
        </Box>
      ) : null}
      <Button
        onClick={handleSubmitSearch}
        className={classes.searchButton}
        disabled={filterInputValues.length === 0 && isDefaultDate}
      >
        Search
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseDateRange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRange
          dateRange={dateRange}
          onDateToChange={(date: Date) =>
            date &&
            setDateRange({
              from: dateRange.from > date ? (dateRange.from = date) : dateRange.from,
              to: date,
            })
          }
          onDateFromChange={(date: Date) =>
            date &&
            setDateRange({
              from: date,
              to: dateRange.to < date ? (dateRange.to = date) : dateRange.to,
            })
          }
          minDate={minDate}
          maxDate={new Date()}
        />
      </Popover>
    </div>
  )
}

export default AutocompleteDataAttackSurface
