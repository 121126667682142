import DiscoverIcon from 'src/images/navbar/discover.png'
import ExploreIcon from 'src/images/navbar/explore.png'
import IntelligenceIcon from 'src/images/navbar/intelligence.png'
import ThreatIcon from 'src/images/navbar/threat.png'
import InternalRBVMIcon from 'src/images/navbar/internal-rbvm.png'
import ReportsIcon from 'src/images/navbar/reports.png'
import { ReactComponent as SettingsIcon } from 'src/images/navbar/settings.svg'
import ReportViewerIcon from 'src/images/navbar/report-viewer.png'
import { Notifications as NotificationsIcon, Search as SearchIcon } from '@material-ui/icons'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import ThirdPartyRiskIcon from 'src/images/navbar/third-party-risk.png'
import ExternalASMIcon from 'src/images/navbar/external-asm.png'

export const navbarTopPages = [
  { name: 'External ASM', path: '/external-asm', icon: ExternalASMIcon },
  { name: 'Internal RBVM', path: '/internal-rbvm', icon: InternalRBVMIcon },
  { name: 'Third Party Risk', path: '/third-party-risk', icon: ThirdPartyRiskIcon },
  { name: 'Intelligence', path: '/intelligence', icon: IntelligenceIcon },
  { name: 'Threat Analysis', path: '/threat', icon: ThreatIcon },
  { name: 'Profiles', path: '/profiles', icon: DiscoverIcon },
  // This is a temporary measure until we improve the offering so can be uncommitted and the page will be visible
  // { name: 'Deep & Dark Web', path: '/explore', icon: ExploreIcon },
  { name: 'Reports', path: '/reports', icon: ReportsIcon },
  { name: 'Your Report', path: '/your-report', icon: ReportViewerIcon },
  { name: 'Alerts', path: '/alerts', Icon: NotificationsIcon },
]

export const navbarBottomPages = [
  { name: 'Search', Icon: SearchIcon },
  { name: 'Selected Organisation', Icon: AccountBalanceIcon },
  { name: 'Selected Company', Icon: undefined },
  // { name: 'Alerts', Icon: NotificationsIcon },
]

export const userProfile = [
  {
    name: 'Settings',
    path: '/settings',
    Icon: SettingsIcon,
  },
]
