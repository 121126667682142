import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import { Loaders } from 'components'
import { theme } from 'src/styling'
import SnackBar from 'src/context/snackbar'
import OrpheusLogoWithBlueText from 'src/images/orpheus/orpheus-logo-with-blue-text.png'
import { recordUserInteraction, userPaidSuccessfully, userPaidUnsuccessfully } from 'src/services/newrlic'

const stripePromise = loadStripe(process.env.STRIPE_API_KEY)

// ======
// TYPES
// ======
type CheckoutFormPageProps = {
  clientSecret: string
  handleSetStep: (step: string) => void
  freeTrialExpired: {
    priceId: string
    customerId: string
    couponId: string
    couponPercentOff: number
    subscriptionPrice: number
    subscriptionCycleLength: string
  }
}
type CheckoutFormProps = {
  handleSetStep: (step: string) => void
}

// ======
// STYLES
// ======
const useStyles = makeStyles({
  // checkout page
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: theme.colors.ui.blueDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 0',
  },
  buttonStyles: {
    marginTop: '1rem',
    border: '1px solid white',
    height: '52px',
    width: '100%',
    backgroundColor: theme.colors.ui.blueDark,
    color: theme.colors.ui.white,
    padding: '12px 90px',
    fontSize: '1rem',
    '& .MuiButton-label': { textTransform: 'none', fontWeight: 300 },
    '&:hover': { backgroundColor: theme.colors.ui.blueDark },
    '&.MuiButton-root.Mui-disabled': { color: 'grey' },
  },
  form: {
    width: '400px',
    backgroundColor: 'white',
    padding: '1.4rem',
    borderRadius: '5px',
  },
  // no client secret or stripe promise page
  contentContainer: {
    background: theme.colors.ui.white,
    padding: '3rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2.7rem',
  },
  logo: { height: '70px' },
  chooseSubscriptionContainer: {
    height: '450px',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    gap: '.5rem',
    border: '.9px solid black',
    borderRadius: '5px',
    padding: '1.4rem 2rem',
    background: 'white',
    '& p': { color: theme.colors.ui.blueDark },
  },
  chooseSubscriptionHeader: { textAlign: 'center', fontWeight: 'bold' },
  subscriptionInfoContainer: { display: 'grid', justifyItems: 'center' },
  priceInfoContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
  originalPrice: { fontWeight: 'bold', display: 'inline-block', position: 'relative' },
  originalPriceSpan: { fontSize: theme.fontSizes.xLarge },
  discountedPrice: { fontWeight: 'bold', marginBottom: '.5rem' },
  discountedPriceSpan: { fontSize: '2rem' },
  subscriptionTermsText: { fontSize: theme.fontSizes.mediumSmall, color: theme.colors.ui.grey, fontStyle: 'italic' },
  pageListContainer: { display: 'flex', flexDirection: 'column' },
  pageListHeader: { fontWeight: 500, textAlign: 'center' },
  pageList: { display: 'flex', flexDirection: 'column', alignItems: 'center', '& p': { marginBottom: '.3rem' } },
  priceStrikeThrough: {
    '&::after': {
      content: '""',
      top: '40%',
      left: '-5%',
      width: '110%',
      position: 'absolute',
      borderBottom: `solid 2px ${theme.colors.ui.blueDark}`,
    },
  },
})

// Stripe checkout style
const appearance = { theme: 'stripe' }
const paymentElementOptions = { layout: 'tabs' }

// =========
// FUNCTIONS
// =========
const getPaymentErrorMessage = (error) => {
  if (error?.type === 'card_error' || error?.type === 'validation_error')
    return error?.message.includes('Try again in a bit')
      ? error?.message.replace('Try again in a bit.', 'Please try again.')
      : `${error?.message} Please try again.`

  if (error?.type === 'invalid_request_error') return error?.message

  return 'An unexpected error occurred. Please try again.'
}

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
})

// =========
// COMPONENT
// =========
const CheckoutForm = ({ handleSetStep }: CheckoutFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = SnackBar.Consumer()
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [paymentElementMounted, setPaymentElementMounted] = useState(false)
  const [successfulPaymentDetails, setSuccessfulPaymentDetails] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card')

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.getElement('payment')
      paymentElement.on('ready', () => setTimeout(() => setPaymentElementMounted(true), 450))
      paymentElement.on('change', (event) => setSelectedPaymentMethod(event?.value?.type))
    }
  }, [elements])

  useEffect(() => {
    if (successfulPaymentDetails) {
      recordUserInteraction(userPaidSuccessfully)
      handleSetStep('successful-payment')
    }
  }, [successfulPaymentDetails])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) return

    setIsLoading(true)

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/redeem/success`,
      },
    })

    if (
      result?.paymentIntent?.status === 'succeeded' ||
      (result?.paymentIntent?.status === 'processing' && selectedPaymentMethod === 'bacs_debit')
    )
      setSuccessfulPaymentDetails(result.paymentIntent)

    if (result?.error) {
      recordUserInteraction(userPaidUnsuccessfully)
      enqueueSnackbar(getPaymentErrorMessage(result?.error), {
        variant: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} className={classes.form}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      {paymentElementMounted ? (
        <>
          <Button
            className={classes.buttonStyles}
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading || !stripe || !elements}
          >
            {isLoading ? <Loaders.Spinner size={25} /> : 'Confirm Subscription'}
          </Button>
          <Button
            className={classes.buttonStyles}
            onClick={() => handleSetStep('upgrade-page')}
            disabled={isLoading || !stripe || !elements}
          >
            Go back
          </Button>
        </>
      ) : null}
    </form>
  )
}

const CheckoutFormPage = ({ clientSecret, handleSetStep, freeTrialExpired }: CheckoutFormPageProps) => {
  const classes = useStyles()
  const { subscriptionPrice, couponPercentOff, subscriptionCycleLength } = freeTrialExpired
  const priceTimeText =
    subscriptionCycleLength === 'Annually' ? 'year' : subscriptionCycleLength.toLowerCase().slice(0, -2)
  const showMinimumContractText = subscriptionCycleLength === 'Quarterly' || subscriptionCycleLength === 'Monthly'

  return (
    <Box className={classes.container}>
      {stripePromise && clientSecret ? (
        <Box style={{ display: 'flex', gap: '3rem' }}>
          <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
            <CheckoutForm handleSetStep={handleSetStep} />
          </Elements>
          <Box className={classes.chooseSubscriptionContainer}>
            <Typography className={classes.chooseSubscriptionHeader}>Annual subscription, paid:</Typography>
            <Typography style={{ textAlign: 'center', fontSize: '1.5rem' }}>{subscriptionCycleLength}</Typography>
            <Box className={classes.subscriptionInfoContainer}>
              <Box className={classes.priceInfoContainer}>
                <p className={`${classes.originalPrice} ${classes.priceStrikeThrough}`}>
                  <span className={classes.originalPriceSpan}>
                    {formatter.format(subscriptionPrice).replace('.00', '')}
                  </span>
                  /{priceTimeText}
                </p>
                <p className={classes.discountedPrice}>
                  <span className={classes.discountedPriceSpan}>{`${formatter
                    .format((subscriptionPrice * (100 - couponPercentOff)) / 100)
                    .replace('.00', '')}`}</span>
                  /{priceTimeText}
                </p>
                {showMinimumContractText ? (
                  <span className={classes.subscriptionTermsText}>Minimum 12 month term</span>
                ) : null}
              </Box>
              <Box className={classes.pageListContainer}>
                <p className={classes.pageListHeader}>Continue accessing:</p>
                <Box className={classes.pageList}>
                  <p>External Attack Surface Management</p>
                  <p>Cyber Threat Intelligence</p>
                  <p>Threat Actor Profiles</p>
                  <p>Cyber Risk Reports</p>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.contentContainer}>
          <img src={OrpheusLogoWithBlueText} className={classes.logo} />
          <Typography>Please go back to the upgrade subscription page</Typography>
          <Button className={classes.buttonStyles} onClick={() => handleSetStep('upgrade-page')}>
            Go back
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CheckoutFormPage
