import React, { useEffect, Dispatch, SetStateAction } from 'react'
import { theme } from 'src/styling'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

interface FilterInputValueInterface {
  id?: number
  title: string
  type: string
  isKeyword?: boolean
}

type DropdownContainerProps = {
  allowKeyword: boolean
  data: FilterInputValueInterface[]
  isDropdownOpen: string | boolean
  showDropdownScrollbar: boolean
  isDropdownOverlay: boolean
}

type DropDownProps = {
  data: FilterInputValueInterface[]
  onDropDownSelection: (option: any) => void
  dropDownOpen: boolean
  cursor: number
  onDropDownList: (arraySize: any[]) => void
  onDropDownOpen?: Dispatch<SetStateAction<string | boolean>>
  dropDownListElementRef: React.MutableRefObject<HTMLDivElement[]>
  currentInput: string
  onKeywordInputChange?: (isSearchAllowed: boolean) => void
  containerWidth?: number
  allowKeyword?: boolean
  filterInputValues?: FilterInputValueInterface[]
}

// ======
// STYLES
// ======
const useStyles = makeStyles({
  option: {
    width: '100%',
    padding: '0.5rem',
    cursor: 'pointer',
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: theme.colors.ui.greyVeryLight,
    },
  },
  optionNoResults: {
    width: '100%',
    padding: '0.5rem',
    fontSize: '0.9rem',
  },
  dropdownContainer: {
    maxHeight: (props: DropdownContainerProps) => (props.isDropdownOpen ? '15rem' : '0'),
    overflowY: (props: DropdownContainerProps) => (props.showDropdownScrollbar ? 'auto' : 'hidden'),
    border: (props: DropdownContainerProps) =>
      `${props.isDropdownOpen && (props.allowKeyword || props.data.length) ? '1px' : '0'} solid ${
        theme.colors.ui.greyDark
      }`,
    borderTop: 0,
    marginBottom: '1rem',
    backgroundColor: theme.colors.ui.white,
    position: (props: DropdownContainerProps) => (props.isDropdownOverlay ? 'absolute' : 'relative'),
    transition: (props: DropdownContainerProps) => `max-height ${props.isDropdownOpen ? '0.5s' : '0s'}`,
    zIndex: (props: DropdownContainerProps) => (props.isDropdownOverlay ? 10 : 'auto'),
  },
  dropDownSelected: {
    backgroundColor: theme.colors.ui.greyVeryLight,
  },
})

// ======
// FUNCTIONS
// ======
const getKeywordState = (currentInput: string, array: FilterInputValueInterface[]) =>
  currentInput ? currentInput && !array.find((currentValue) => currentValue.title === currentInput) : null

const DropDown: React.FC<DropDownProps> = ({
  data,
  onDropDownSelection,
  currentInput,
  onKeywordInputChange,
  dropDownOpen,
  dropDownListElementRef,
  cursor,
  allowKeyword,
  filterInputValues,
  onDropDownOpen,
  containerWidth,
}) => {
  // need to check specifically for null value to get the left side of the condition, checking for false or any other falsy value does not satisfy the condition

  const keywordState = getKeywordState(currentInput, filterInputValues)
  const isDropdownEmpty = (data.length > 0 && currentInput && !allowKeyword) || (dropDownOpen && keywordState)

  const classes = useStyles({
    isDropdownOpen: isDropdownEmpty,
    isDropdownOverlay: true,
    showDropdownScrollbar: true,
    data,
    allowKeyword,
  })

  useEffect(() => {
    onDropDownOpen(isDropdownEmpty)
  }, [isDropdownEmpty])

  return (
    <Box className={classes.dropdownContainer} style={{ width: containerWidth }}>
      {allowKeyword && getKeywordState(currentInput, filterInputValues) && (
        <div
          id={'search-for-keyword'}
          ref={(el) => (dropDownListElementRef.current[0] = el)}
          className={[classes.option, cursor ? null : classes.dropDownSelected].join(' ')}
          key={currentInput}
          onClick={(): void => onKeywordInputChange(false)}
        >{`Search for keyword ${currentInput}`}</div>
      )}
      {data.map((node: any, index: number) => {
        const id = node.type
          ? `drop-down-item-${node.name}-${node?.type[0].toUpperCase()}${node.type.slice(1)}`
          : `drop-down-item-${node.name}`
        return (
          <div
            id={id}
            ref={(el) => (dropDownListElementRef.current[currentInput ? index + 1 : index] = el)}
            className={[
              classes.option,
              (currentInput ? index + 1 : index) === cursor ? classes.dropDownSelected : '',
            ].join(' ')}
            key={`${node.id}-${node.name}`}
            onClick={(): void => onDropDownSelection(node)}
          >
            {node.type ? `${node.name} [${node.type[0].toUpperCase()}${node.type.slice(1)}]` : `${node.name}`}
          </div>
        )
      })}
    </Box>
  )
}

export { DropDown }
