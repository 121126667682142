import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import TabHeader from './tab-header'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import { theme as globalTheme } from 'src/styling'
import ChipSection from 'src/components/chip-section'

// ===================
// STYLES
// ===================
const useStyles = makeStyles((theme: Theme) => ({
  //======Body
  bodyContainer: {
    border: `0.9px solid ${globalTheme.colors.ui.greyLight}`,
    fontSize: globalTheme.fontSizes.xLarge,
    padding: '27px',
    color: globalTheme.colors.ui.blueDark,
    width: '97%',
    marginLeft: '1%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0',
      padding: '2px',
    },
  },
  nvdTitle: {
    padding: '10px',
    '& p': {
      fontSize: globalTheme.fontSizes.xLarge,
      marginBottom: '0.9rem',
      lineHeight: '1.35',
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        textAlign: 'center',
      },
    },
  },
  tableContainer: {
    border: '1px solid #e1e1e1',
  },
  tableHeadCell: {
    borderRight: '1px solid #e1e1e1',
    backgroundColor: '#FAFAFA',
    fontSize: globalTheme.fontSizes.medium,
    lineHeight: '1.35',
    padding: '14px',
  },
  tableCell: {
    padding: '22.5px 0',
    borderRight: `0.9px solid ${globalTheme.colors.ui.greyLight}`,
    fontSize: globalTheme.fontSizes.medium,
  },
  table: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

/**
 * TYPES
 */
type TabCvssProps = {
  cveData?: any
  cveId?: string
  scoreTitleName?: string
  tabName?: string
  cvssVersion: string
}

/**
 * TSX and FUNCTIONS
 */
const listRowGenerator = (data: any, cvssVersion: string, title: string) => {
  const nvd = data?.nvd
  switch (cvssVersion) {
    case 'V2':
      if (nvd?.confidentialityImpact && title === 'Confidentiality') return nvd.confidentialityImpact
      if (nvd?.availabilityImpact && title === 'Availability') return nvd.availabilityImpact
      if (nvd?.integrityImpact && title === 'Integrity') return nvd.integrityImpact
    case 'V3':
      if (nvd?.nvdMetricsV3?.confidentialityImpact && title === 'Confidentiality')
        return nvd.nvdMetricsV3.confidentialityImpact
      if (nvd?.nvdMetricsV3?.availabilityImpact && title === 'Availability') return nvd.nvdMetricsV3.availabilityImpact
      if (nvd?.nvdMetricsV3?.integrityImpact && title === 'Integrity') return nvd.nvdMetricsV3.integrityImpact
    default:
      return
  }
}

const TabCvss = ({ cveData, cveId, scoreTitleName, tabName, cvssVersion }: TabCvssProps) => {
  const classes = useStyles()

  const tableArray = ['Confidentiality', 'Availability', 'Integrity']

  return (
    <>
      <Box>
        <TabHeader
          title={cveId}
          cveData={cveData}
          scoreTitleName={scoreTitleName}
          tabName={tabName}
          cvssVersion={cvssVersion}
        />
        <Box className={classes.bodyContainer}>
          <Grid>
            <Box className={classes.nvdTitle}>
              <p>NVD Details</p>
            </Box>
            <Box padding={'9px'}>
              <Grid xs={12}>
                <TableContainer className={classes.table}>
                  <Table stickyHeader className={classes.tableContainer} aria-label="simple table">
                    <TableHead>
                      {tableArray.map((title: string) => (
                        <TableCell key={title} align={'center'} className={classes.tableHeadCell}>
                          {title}
                        </TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {tableArray.map((title: string) => (
                          <TableCell key={title} align={'center'} className={classes.tableCell}>
                            {listRowGenerator(cveData, cvssVersion, title)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <ChipSection edges={cveData} cvssVersion={cvssVersion} modalType="cve" />
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default TabCvss
