import { theme } from 'src/styling'
import { makeStyles } from '@material-ui/core/styles'

export const commonStyles = makeStyles({
  titlewithSubtitle: {
    fontSize: theme.fontSizes.mediumLarge,
    margin: '0',
    lineHeight: '1.2rem',
    fontWeight: 'bold',
    '& span': {
      fontSize: theme.fontSizes.medium,
      fontWeight: 'normal',
    },
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'md')]: {
      margin: '5px 0 5px 0',
      textAlign: 'center',
    },
  },
  horizontalCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
})
