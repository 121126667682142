import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

// ===================
// TYPES
// ===================
type GlobalFormGroupProps = {
  children: React.ReactNode
  /**
   * Defines the direction of elements within the form group.
   */
  direction?: 'horizontal' | 'vertical'
  /**
   * Defines the alignment of children in the form group.
   */
  justify?: 'end' | 'center' | 'spread'
}

type FormGroupProps = {
  direction: GlobalFormGroupProps['direction']
  justify: GlobalFormGroupProps['justify']
}

// ===================
// STYLES
// ===================

const useStyles = makeStyles<Theme, FormGroupProps>({
  formGroup: {
    display: 'flex',
    padding: '0.9em 0 0.9em 0',
    flexDirection: ({ direction }) => (direction === 'horizontal' ? 'row' : 'column'),
    justifyContent: ({ justify }) =>
      justify === 'end' ? 'flex-end' : justify === 'center' ? 'center' : 'space-between',
  },
})

// ===================
// COMPONENT
// ===================
export const GlobalFormGroup: React.FC<GlobalFormGroupProps> = ({
  children,
  direction = 'vertical',
  justify = 'spread',
}: GlobalFormGroupProps) => {
  const classes = useStyles({ direction, justify })
  return <div className={classes.formGroup}>{children}</div>
}
