import Cookies from 'js-cookie'
import Error from 'src/components/errors/error'
import React from 'react'
import { RouteProps, useHistory } from 'react-router-dom'
import { getRouteFromPayload } from './router-helpers'
import { useSelector } from 'react-redux'
import { redirectToLoggedOutPage } from 'src/utils/redirect-to-logged-out-page'

type Private = {
  (props: RouteProps): React.ReactNode | null
}

const PrivateRoute = (PrivateComponent: React.FC<RouteProps>): Private => {
  const Private: Private = (props) => {
    const history = useHistory()
    const { isAccountDeactivated } = useSelector((state) => state.ui)
    const { pocExpired, freeTrialExpired } = useSelector((state) => state.auth)
    const token = Cookies.get('auth-token')

    if (!token && isAccountDeactivated) history.push('/account-deactivated')
    else if (!token && !isAccountDeactivated) redirectToLoggedOutPage()

    if (token && pocExpired && window.location.pathname !== '/subscription') history.push('/poc-expired')
    if (token && freeTrialExpired && window.location.pathname !== '/subscription') history.push('/trial-expired')

    const route = getRouteFromPayload(window.location.pathname)

    if (!route) return <Error errorCode={'FORBIDDEN'} />

    return <PrivateComponent {...props} />
  }

  return Private
}

export default PrivateRoute
