import auth from 'src/auth/auth0'
import Cookies from 'js-cookie'

export function redirectToLoggedOutPage() {
  if (Cookies.get('auth-token')) Cookies.remove('auth-token')
  auth.auth0.logout({
    returnTo: `${window.location.protocol}//${window.location.host}/logged-out`,
    clientID: process.env.AUTH0_CLIENT_ID,
  })
}
