import React from 'react'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// =====
// TYPES
// =====
type ModalGroupProps = {
  /**
   * Content rendered within the modal group
   */
  children: React.ReactNode
  /**
   * Padding
   */
  padding?: string
}

// ======
// STYLED
// ======
type GroupProps = {
  padding: string
}
const Group = styled.div<GroupProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }): string => padding};
  flex-wrap: wrap;
  justify-content: center;
  align-items: left;
  border-bottom: 0.05rem solid ${theme.colors.ui.greyLight};

  :last-of-type {
    border-bottom: none;
  }
`

// =========
// COMPONENT
// =========
export const GlobalModalGroup: React.FC<ModalGroupProps> = ({ children, padding = '2rem 0' }: ModalGroupProps) => {
  return <Group padding={padding}>{children}</Group>
}
