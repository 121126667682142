import { Bp, Mq, Theme } from 'src/types/styling'
import { Global, css } from '@emotion/core'
import styled, { CreateStyled } from '@emotion/styled'

import React from 'react'
import { createTheme } from '@material-ui/core/styles'
import normalize from 'normalize.css'

// =============
// MEDIA QUERIES
// =============
const breakpoints: Bp = [
  ['sm', 600],
  ['md', 900],
  ['ml', 1050],
  ['lg', 1200],
  ['l', 1500],
  ['xl', 1800],
]

// General
export const mq: Mq = breakpoints.reduce(
  (values, bp) => {
    values[bp[0]] = `@media (min-width: ${bp[1]}px)`
    return values
  },
  {
    sm: '',
    md: '',
    ml: '',
    lg: '',
    l: '',
    xl: '',
  }
)

// Portrait Specific
export const mqPortrait: Mq = breakpoints.reduce(
  (values, bp) => {
    values[bp[0]] = `@media (min-width: ${bp[1]}px) and (orientation:portrait)`
    return values
  },
  {
    sm: '',
    md: '',
    ml: '',
    lg: '',
    l: '',
    xl: '',
  }
)

// Landscape Specific
export const mqLandscape: Mq = breakpoints.reduce(
  (values, bp) => {
    values[bp[0]] = `@media (min-width: ${bp[1]}px) and (orientation:landscape)`
    return values
  },
  {
    sm: '',
    md: '',
    ml: '',
    lg: '',
    l: '',
    xl: '',
  }
)

// Colors
const colors = {
  colors: {
    ui: {
      blueDark: '#03113f',
      blue: '#031454',
      blueLight: '#1c2b65',
      blueVeryLight: '#677298',
      goldDark: '#80663D',
      gold: '#a0804d',
      goldLight: '#B39970',
      greenDark: '#068F08',
      green: '#09e00c',
      greenLight: '#BEF7C0',
      greyVeryDark: '#333333',
      greyDark: '#777777',
      grey: '#ababab',
      greyLight: '#d5d5d5',
      greyVeryLight: '#f6f6f6',
      orange: 'orange',
      redDark: '#9C0706',
      red: '#e00a09',
      redLight: '#F29A9C',
      black: '#000000',
      whiteSmoke: '#f5f5f5',
      snow: '#FCFCFC',
      white: '#ffffff',
      blackCow: '#484848',
      yellow: '#FFFF00',
    },
    sparklineColors: ['#e64544', '#F1821F', '#BCD75E', '#09B681'],
    targetColors: [
      '#ffffff',
      '#f79292',
      '#f57777',
      '#f35c5c',
      '#f14141',
      '#ef2626',
      '#e71111',
      '#cc0f0f',
      '#b10d0d',
      '#950b0b',
      '#c30e0e',
    ],

    orginColors: [
      '#FFFFFF',
      '#43b7a1',
      '#3ea894',
      '#389987',
      '#338b7a',
      '#2d7c6c',
      '#286d5f',
      '#225e52',
      '#1d4f45',
      '#174038',
      '#12312b',
    ],
    severity: [
      'rgba(9, 182, 129, 1)',
      'rgba(63, 189, 100, 1)',
      'rgba(116, 196, 73, 1)',
      'rgba(168, 204, 46, 1)',
      'rgba(220, 210, 18, 1)',
      'rgba(253, 202, 5, 1)',
      'rgba(248, 166, 18, 1)',
      'rgba(241, 130, 31, 1)',
      'rgba(236, 94, 44, 1)',
      'rgba(230, 69, 68, 1)',
    ],
  },
}

const fontSizes = {
  fontSizes: {
    xxLarge: '1.3rem',
    xLarge: '1.2rem',
    large: '1rem',
    mediumLarge: '0.9rem',
    medium: '0.8rem',
    mediumSmall: '0.7rem',
    small: '0.6rem',
    xSmall: '0.5rem',
  },
}

// Resusable styles
const customStyles = {
  customStyles: {
    arrowContainer: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      margin: '0 0.9rem 0 0.18rem',
      padding: '0.72rem 0.72rem 0.72rem 0.72rem',
      borderRadius: '0.5rem',
      backgroundColor: colors.colors.ui.whiteSmoke,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: colors.colors.ui.snow,
      },
    },
    loadingMoreMessage: {
      padding: '8px',
      fontSize: fontSizes.fontSizes.medium,
    },
    icon: { transform: 'scale(0.9)' },
  },
}

const customTheme: Theme = {
  palette: {
    primary: {
      main: colors.colors.ui.blue,
    },
    secondary: {
      main: colors.colors.ui.gold,
    },
  },
  ...customStyles,
  ...colors,
  ...fontSizes,
  overrides: {
    MuiPaper: {
      elevation3: {
        boxShadow: `0 0.1rem 0.2rem 0 ${colors.colors.ui.greyLight}`,
      },
    },
  },
}

export const theme = createTheme(customTheme) as unknown as Theme

export default styled as CreateStyled<Theme>

export const GlobalStyles: React.FC = (props) => {
  return (
    <>
      <Global
        styles={css`
          ${normalize}

          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }
          html {
            font-family: 'Roboto', sans-serif;
          }
          h1 {
            font-size: 1.8rem;
            font-weight: 600;
          }
          h2 {
            font-size: 1.3rem;
            font-weight: 500;
          }
          h3 {
            font-size: 1.2rem;
            font-weight: 400;
          }
          h4 {
            font-size: 0.9rem;
            font-weight: 400;
          }
          h5 {
            font-size: 0.8rem;
            font-weight: 400;
          }
          h6 {
            font-size: 0.7rem;
            font-weight: 400;
          }
          .noBottomBorder {
            border: none;
          }

          .noBottomBorder > img {
            display: block !important;
            margin-left: auto !important;
            margin-right: auto !important;
            object-fit: scale-down !important;
            width: 90% !important;
          }
          // Keeping this Snackbar as it might be getting used
          .SnackbarItem-message-24 {
            color: white !important;
          }
          .rsd {
            font-weight: 1;
          }
          .rsd_date-container > div {
            margin-right: 8px !important;
          }
          .error-message {
            margin-top: 3px;
            color: red !important;
          }
          .rsd_date-container > div > select {
            font-weight: 1 !important;
            padding: 5px;
            margin: 5px 0;
          }
          .input-range__slider {
            width: 24px;
            height: 24px;
            background: ${theme.colors.ui.blueDark} !important;
            border: none;
            margin-left: -11px;
            margin-top: -14px;
            position: relative;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
          }
          p {
            line-height: 1.5rem;
            margin-bottom: 1rem;
          }
          a {
            font-size: 0.9rem;
            font-weight: 400;
            color: ${theme.colors.ui.blueDark};
            text-decoration: none;
            border-bottom: 0.9px solid ${theme.colors.ui.blueDark};

            :hover {
              color: ${theme.colors.ui.blue};
            }
          }
          ul {
            margin-left: 2rem;
            line-height: 1.5rem;
          }

          svg {
            display: block;
          }
          // Keeping deep dark web post as it might be getting used
          .deep-dark-web-post {
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
          }
          .deep-dark-web-posts::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }

          ::-webkit-scrollbar {
            width: 0.5rem;
            transition: width 0.2s;
          }
          ::-webkit-scrollbar-track {
            border-radius: 0.1rem;
            background: #fff;
          }
          ::-webkit-scrollbar-thumb {
            background: ${theme.colors.ui.greyLight};
            border-radius: 0.1rem;

            :hover {
              background: ${theme.colors.ui.grey};
            }
          }
          /* SEVERITY SLIDER STYLES */
          .severity-slider {
            position: relative;
            width: 85%;
          }

          .input-range__label {
            display: none;
          }

          .input-range__track {
            height: 4px;
            border-radius: 0;
          }

          .severity-slider__ticks {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 48px;
          }

          .severity-slider__values {
            width: 100%;
            position: absolute;
            top: 48px + 8px;
            font-weight: 500;
            display: block;
            text-align: center;
            margin-bottom: 16px;
          }

          /* LEAFLET STYLES */
          .leaflet-control-attribution {
            display: none;
          }

          .leaflet-container {
            width: 100%;
            height: 100%;
            margin: 0 auto;

            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
          }

          .leaflet-bar {
            box-shadow: none;
            border-radius: none;
          }

          .leaflet-touch .leaflet-bar a {
            width: 27px;
            height: 27px;
            line-height: 27px;
          }

          .leaflet-left .leaflet-control {
            margin-left: 28px;
          }

          .leaflet-top {
            top: auto;
            bottom: 0;
          }

          .leaflet-top .leaflet-control {
            margin-bottom: 28px;
          }

          .leaflet-bar a:last-child {
            margin-bottom: 0;
          }

          .leaflet-popup-content-wrapper {
            border-radius: 3px;
          }

          .leaflet-popup-content-wrapper,
          .leaflet-popup-tip {
            background: #03113f;
            color: #ffffff;
            box-shadow: none;
          }

          .leaflet-popup-close-button {
            display: none;
          }

          .leaflet-popup-content {
            margin: 10px;
            font-weight: 500;
            font-size: 12px;
          }

          .leaflet-popup-tip {
            width: 12px;
            height: 12px;
          }
        `}
      />
      {props.children}
    </>
  )
}
