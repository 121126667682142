import { Dialog, ModalGroup } from 'components'
import React, { Suspense } from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import ErrorBoundary from 'src/components/errors/error-boundary'
import { gql as GQL } from 'apollo-boost'
import { Loaders } from 'components'
import Tab from '@material-ui/core/Tab'
import TabCvss from './tabs/tab-cvss'
import TabSummary from './tabs/tab-summary'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { createResource } from 'src/network/fetcher'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import NoResults from 'src/components/errors/no-results'
import { theme as globalTheme } from 'src/styling'
import { recordUserInteraction, userChangedModalTab } from 'src/services/newrlic'
import { getJwtPayload } from 'src/auth/payload'
import Error from 'src/components/errors/error'

// ======
// TYPES
// ======
type CveModalProps = {
  onClose: () => void
  cveIdFromSearch: string
  closeOnOutsideClick?: boolean
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  navBar: {
    color: globalTheme.colors.ui.blueDark,
  },
  tab: {
    fontSize: globalTheme.fontSizes.medium,
    lineHeight: '1.6',
    padding: '5.5px 11px',
    minHeight: '43px',
  },
}))

// ===================
// QUERIES & RESOURCES
// ===================

const QUERY_CVES_SUMMARY = GQL`
  query getCveInfo($cveId: String!, $from:DateTime!, $to:DateTime!) {

    portalExplore(indexes:["wild"], duration:{from:$from, to:$to}, filter:{search:[$cveId], aggregations: { interval: month}}){
      totalCount
      aggregations {
        key_as_string
        doc_count
      }
    }
    cveData: cve(cveId: $cveId) {
      description
      exploitationStatus
      tag {
        id
      }
      nvd {
        availabilityImpact
        confidentialityImpact
        integrityImpact
        publishedDatetime
        lastModifiedDatetime
        cvssV2Score
        summary
        nvdMetricsV3 {
          attackComplexity
          attackVector
          availabilityImpact
          confidentialityImpact
          integrityImpact
          baseScore
        }
        nvdVulnerables(first:50) {
          edges {
            node {
              cpeV23 {
                software
                vendor
              }
            }
          }
        }
      }
      cveLifecyclePrediction {
        probability
      }
      intreps {
        totalCount
      }
      exploitedByRansomware
      profiles(first:50) {
        totalCount
        edges {
          node {
            id
            title
          }
        }
      }
      tags {
        totalCount
      }
    }
    ovssData: ovssByCve(cve: $cveId) {
      ovss
      aeiStatus
      gnMalTag
      exploitdb
      packetstorm
      metasploit
      nuclei
      attackerkb
      vtEngineCountLog
      vtFileIdCountLog
      tblTimestamp
      honeypotMaliciousActivity
      exploitCodeAvailable
      antivirusEngines
      malwareSamples
    }
    twitterPosts(cveIds: [$cveId], duration: { from:$from to:$to }){
      aggregations {
        date
        totalPosts
      }
      edges {
        node {
          sourceDateCreated
        }
      }
    }
    ovssScoresOverTime: ovssByCveScoresOverTime(cve: $cveId){
      ovss
      tblTimestamp
    }
    organisationCompaniesByCve(cve: $cveId) {
      totalCount
      companyCveId
    }
  }
`

//Loader
const FallbackModal: React.FC = () => (
  <Box>
    <ModalGroup>
      <Loaders.Line />
    </ModalGroup>
    <ModalGroup>
      <Loaders.Text lines={9} width={'100%'} />
    </ModalGroup>
  </Box>
)

// ===================
// INTERFACE
// ===================
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

// ===================
// FUNCTIONS
// ===================
function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2.7}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const tabManager = (index: number) => {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` }
}

//Data
const ContentModal: React.FC<any> = ({ onClose, resource, cveIdFromSearch }) => {
  const { cveId: cveIdParams }: { cveId: string } = useParams()
  const cveId = cveIdFromSearch ?? cveIdParams
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const tabNameMap: { [key: number]: string } = {
      0: 'summary',
      1: 'cvss',
    }
    recordUserInteraction(userChangedModalTab(tabNameMap[newValue]))
    setValue(newValue)
  }

  const { cveData, portalExplore, ovssData, twitterPosts, ovssScoresOverTime, organisationCompaniesByCve } =
    resource.read()

  const cvssScoreExists = !!cveData?.nvd?.nvdMetricsV3 || !!cveData?.nvd?.cvssV2Score
  const cvssVersion = cveData?.nvd?.nvdMetricsV3 ? 'V3' : 'V2'
  const scoreTitleName = cvssVersion === 'V3' ? 'CVSS V3' : 'CVSS V2'

  if (!cveData)
    return (
      <Box height={'80vh'}>
        <NoResults message={`${cveId} not found`} />
      </Box>
    )
  return (
    <div className={classes.root}>
      <AppBar id="tab-header-cve-modal" position="static" color="transparent" elevation={0} className={classes.navBar}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange} centered aria-label="simple tabs example">
          <Tab className={classes.tab} label="Summary" {...tabManager(0)} />
          {cvssScoreExists && <Tab className={classes.tab} label="CVSS" {...tabManager(1)} />}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TabSummary
          onClose={onClose}
          cveData={cveData}
          ovssData={ovssData}
          ovssScoresOverTime={ovssScoresOverTime}
          twitterPostsAggregations={twitterPosts?.aggregations}
          explore={portalExplore}
          cveId={cveId}
          organisationCompaniesByCve={organisationCompaniesByCve}
          tabName="SUMMARY"
          scoreTitleName="OVSS"
        />
      </TabPanel>
      {cvssScoreExists && (
        <TabPanel value={value} index={1}>
          <TabCvss
            cveData={cveData}
            cveId={cveId}
            tabName="CVSS"
            scoreTitleName={scoreTitleName}
            cvssVersion={cvssVersion}
          />
        </TabPanel>
      )}
    </div>
  )
}

//Wrapper
const CveModal: React.FC<CveModalProps> = ({ onClose, cveIdFromSearch, closeOnOutsideClick }) => {
  //media query
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { cveId: cveIdParams }: { cveId: string } = useParams()
  const now = moment()
  const to = now.format('YYYY-MM-DD')
  const from = now.subtract(12, 'months').format('YYYY-MM-DD')

  const resource = createResource(QUERY_CVES_SUMMARY, {
    variables: { cveId: cveIdFromSearch ?? cveIdParams, from, to },
  })

  const { enableCveAccess } = getJwtPayload()
  return (
    <Dialog onClose={onClose} fullScreen={fullScreen} closeOnOutsideClick={closeOnOutsideClick}>
      <ErrorBoundary isWidget={false}>
        <Suspense fallback={<FallbackModal />}>
          {!enableCveAccess ? (
            <Error errorCode={'FORBIDDEN'} />
          ) : (
            <ContentModal onClose={onClose} resource={resource} cveIdFromSearch={cveIdFromSearch} />
          )}
        </Suspense>
      </ErrorBoundary>
    </Dialog>
  )
}

export default CveModal
