import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { theme } from 'src/styling'
import { makeStyles } from '@material-ui/core/styles'
import RemoveIcon from '@material-ui/icons/Remove'
import InfiniteScroll from 'react-infinite-scroll-component'

/**
 * Use this class for a generic table that generates table from array of objects.
 * @param items An array, where each index represents a row. Each element in the array should be an object e.g. [{name, age, date},{name, age, date},{name, age, date},...]
 * @param rowKeys Keys that should be used to index each index of items. e.g ["name","age","date"] -> will generate a table where each row, has a name, age and date
 *                rowkeys can represent nested objects. e.g. if items looks like this [{node:{data:{name, age,date}}}, {node:{data:{name, age,date}}}, ...] rowkeys could be:  ["node.data.name","node.data.age","node.data.date"]
 */

type Props = {
  headings?: string[]
  items: any[]
  rowKeys: string[]
  hasMore: boolean
  fetchMore: () => void
}

const useStyles = makeStyles({
  tableHeaderCell: { lineHeight: '1.35' },
  tableCell: {
    padding: '14px',
    fontSize: theme.fontSizes.medium,
    lineHeight: '1.3',
    borderBottom: `0.9px solid ${theme.colors.ui.greyLight}`,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.fontSizes.mediumSmall,
    },
  },
  loadingMessage: { ...theme.customStyles.loadingMoreMessage },
  icon: { ...theme.customStyles.icon },
})

/**
 * Helper function. Provided object: {a:{b:"Hello"}} and path: "a.b" returns "Hello"
 * @param obj Object to process
 * @param path Key to extract from object
 */

type objType = {
  [key: string]: objType | string
}

const leaf = (obj: objType, path: string) =>
  path.split('.').reduce((value, el) => {
    if (value) return el in value ? (typeof value[el] === 'string' && value[el].trim() === '' ? null : value[el]) : {}

    return
  }, obj)

const GlobalTableNewOld: React.FC<Props> = ({ headings, items, rowKeys, fetchMore, hasMore }) => {
  const classes = useStyles()

  return (
    <InfiniteScroll
      height={450}
      dataLength={items.length}
      next={fetchMore}
      hasMore={hasMore}
      loader={<h4 className={classes.loadingMessage}>Loading more...</h4>}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headings.map((headCell: string, index: number) => (
              <TableCell align="left" key={index} className={`${classes.tableHeaderCell} ${classes.tableCell}`}>
                {headCell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(
            (item: any, index: number): React.ReactElement => (
              <TableRow key={index}>
                {rowKeys.map((key, index) => {
                  return (
                    <TableCell className={classes.tableCell} key={index} align="left">
                      {leaf(item, key) ?? <RemoveIcon className={classes.icon} />}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </InfiniteScroll>
  )
}

export { GlobalTableNewOld }
