import React from 'react'
import ErrorFallback from './error-fallback'

// ===================
// TYPES
// ===================
type Props = {
  isWidget?: boolean
  children: React.ReactChildren | React.ReactElement
}

type State = {
  error:
    | {
        networkError?: {
          statusCode: number
        }
      }
    | undefined
  loading: boolean
}

// ===================
// COMPONENT
// ===================
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null, loading: false }
  }

  static getDerivedStateFromError(error: Error): object {
    return { error }
  }

  componentDidCatch(): void {
    // You can also log the error to an error reporting service
    setTimeout(() => {
      this.setState({ loading: false })
    }, 100)
  }

  render(): React.ReactElement | React.ReactNode {
    const retry = (e: React.FormEvent<HTMLInputElement>): void => {
      this.setState({ loading: true })
      e.preventDefault()
      this.setState({ error: null })
    }
    const { error, loading } = this.state
    if (this.state.error) {
      return <ErrorFallback isWidget={this.props.isWidget} loading={loading} error={error} handleRetry={retry} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
