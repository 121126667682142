import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import { theme } from 'src/styling'
import sha1 from 'sha1'
import moment from 'moment'

// TYPES
interface PointInterface {
  x: number
  y: number
  color?: string
}
interface AreaChartInterface {
  data: PointInterface[]
  data2?: PointInterface[]
  data3?: PointInterface[]
  height?: number | string
  minXAxis?: number
  maxXAxis?: number
  maxYAxis?: number
  showXLabels?: boolean
  showYLabels?: boolean
  width?: number
  xOffset?: number
  xPointInterval?: number
  gridXLineWidth?: number
  gridYLineWidth?: number
  riskChart?: boolean
  showLastLabel?: boolean
  showFirstLabel?: boolean
  loading?: boolean
  yAxisVisible?: boolean
  disableMarker?: boolean
  xAxisTitle?: string
  yAxisTitle?: string
  handleSelection?: (event: Event) => void
}

// =================
// UTILITY FUNCTIONS
// =================
const getChartAreaColorStops = (series: PointInterface[], maxY: number): React.ReactText[][] => {
  const colors = theme.colors.severity.filter((color, index) => {
    return index <= Math.floor(maxY / 100) + 1
  })

  const colorStops = colors.map((color, i) => {
    return [i * (1 / (colors.length - 1)), color.replace(', 1)', ', 0.1)')]
  })

  return colorStops
}

const getChartLineColorStops = (series: PointInterface[], minY: number, maxY: number): React.ReactText[][] => {
  const colors = theme.colors.severity.filter((color, index) => {
    return index < Math.floor(maxY / 100) + 1 && index >= Math.floor(minY / 100)
  })

  const colorStops = colors.map((color, i) => {
    return [i * (1 / (colors.length - 1)), color.replace(', 1)', ', 0.5)')]
  })

  return colorStops
}

const addSeriesPointColors = (series: PointInterface[]): PointInterface[] => {
  if (!series) return []
  const colors = theme.colors.severity

  const seriesWithColors = series.map((point) => {
    const color = colors[Math.floor(point.y / 100)]
    point.color = color
    return point
  })

  return seriesWithColors
}

const getDateFormat = () => {
  const windowWidth = window.innerWidth
  return windowWidth < 1300 ? 'MM/YY' : 'DD/MM/YYYY'
}

// ============
// CHART CONFIG
// ============
const generateConfig = (props: AreaChartInterface): object => {
  const minY = props.data.reduce((min, { y }) => {
    if (y < min) {
      min = y
    }
    return min
  }, 1000)

  const maxY = props.data.reduce((max, { y }) => {
    if (y > max) {
      max = y
    }
    return max
  }, 0)

  const config = {
    chart: {
      animation: false,
      type: 'area',
      width: props.width,
      height: props.height,
      events: {
        selection: props.handleSelection
          ? function (event: Event) {
              props.handleSelection(event)
              event.preventDefault()
            }
          : {},
      },
      zoomType: props.handleSelection ? 'x' : null,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: props.handleClickPoint
        ? {
            cursor: 'pointer',
            allowPointSelect: true,
            point: {
              events: {
                select: props.handleClickPoint,
              },
            },
          }
        : {},
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
          stops: getChartAreaColorStops(props.data, maxY),
        },
        lineWidth: 1,
        marker: !props.disableMarker
          ? {
              enabled: props.data.length === 1,
              shadow: false,
              showInLegend: false,
            }
          : {},
        showInLegend: false,
        states: {
          hover: {
            marker: { symbol: 'circle' },
            threshold: null as null,
          },
        },
      },
      line: {
        marker: {
          enabled: false,
          shadow: false,
          showInLegend: false,
        },
        showInLegend: false,
        states: {
          hover: {
            lineWidth: 1,
            threshold: null as null,
          },
        },
      },
    },
    series: [
      {
        color: {
          linearGradient: {
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0,
          },
          stops: getChartLineColorStops(props.data, minY, maxY),
        },
        data: addSeriesPointColors(props.data),
        type: 'area',
      },
      {
        color: 'rgb(253, 202, 5, 1)',
        data: addSeriesPointColors(props.data2),
        dashStyle: 'LongDash',
        type: 'line',
      },
      {
        color: 'rgb(248, 166, 18, 1)',
        data: addSeriesPointColors(props.data3),
        type: 'line',
        dashStyle: 'Dot',
      },
    ],

    title: {
      text: null as null,
    },
    tooltip: {
      backgroundColor: theme.colors.ui.blueDark,
      borderColor: theme.colors.ui.blueDark,
      borderRadius: 8,
      borderWidth: 0,
      xDateFormat: '%Y-%m-%d',
      style: { color: theme.colors.ui.white, fontSize: theme.fontSizes.mediumLarge, textAlign: 'center' },
      padding: 14,
      headerFormat: '<b>{point.key}</b><br/>',
      pointFormat: "<span style='color:#fff; font-weight: 400;'>{point.y}</span><br/>",
      shadow: true,
      useHTML: true,
    },
    xAxis: {
      gridLineWidth: props.gridXLineWidth ? props.gridXLineWidth : 0,
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%d/%m',
        week: '%d/%m',
        month: '%m/%Y',
        year: '%Y',
      },
      labels: {
        enabled: props.showXLabels,
        y: 25,
        style: {
          fontSize: theme.fontSizes.small,
        },
      },
      lineWidth: 0,
      min: props.minXAxis && props.minXAxis,
      max: props.maxXAxis && props.maxXAxis,
      offset: props.xOffset ? props.xOffset : 0,
      showFirstLabel: !!props.showFirstLabel,
      showLastLabel: !!props.showLastLabel,
      pointInterval: props.xPointInterval,
      tickPosition: 'outside',
      type: 'datetime',
      title: {
        text: props.xAxisTitle ? props.xAxisTitle : (null as null),
        style: {
          fontSize: theme.fontSizes.medium,
        },
      },
      plotLines: props.minXAxis &&
        props.maxXAxis && [
          {
            color: 'black',
            width: 1,
            value: props.minXAxis, // start date
            label: {
              type: 'datetime',
              text: moment(props.minXAxis).format(getDateFormat()),
              style: {
                align: 'center',
                fontSize: theme.fontSizes.small,
              },
            },
          },
          {
            color: 'black',
            width: 1,
            value: props.maxXAxis, // End date timestamp
            label: {
              text: moment(props.maxXAxis).format(getDateFormat()),
              x: -10,
              style: {
                fontSize: theme.fontSizes.small,
              },
            },
          },
        ],
    },
    yAxis: {
      gridLineWidth: props.gridYLineWidth ? props.gridYLineWidth : 0,
      labels: {
        enabled: props.showYLabels,
        style: { fontSize: theme.fontSizes.small },
      },
      max: props.maxYAxis ? props.maxYAxis : null,
      min: 0,
      startOnTick: false,
      title: {
        text: props.yAxisTitle ? props.yAxisTitle : (null as null),
        style: {
          fontSize: theme.fontSizes.medium,
        },
      },
      allowDecimals: false,
      visible: props.yAxisVisible ? props.yAxisVisible : false,
    },
  }

  if (props.riskChart) {
    config.chart['margin'] = 0
    config.chart['spacing'] = 0
  }

  return config
}

// =========
// COMPONENT
// =========
const GlobalAreaChart: React.FC<AreaChartInterface> = (props) => {
  const config = generateConfig(props)
  return <HighchartsReact key={sha1(JSON.stringify(props.data))} highcharts={Highcharts} options={config} />
}

GlobalAreaChart.propTypes = {
  /**
   * The data to be rendered on the area chart.
   */
  data: PropTypes.arrayOf(
    PropTypes.exact({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      color: PropTypes.string,
    })
  ).isRequired,
  /**
   * Defines the height of the area chart, in pixels.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The maximum value of the y-axis on the area chart.
   */
  maxYAxis: PropTypes.number,
  /**
   * If true x-axis labels will be displayed
   */
  showXLabels: PropTypes.bool,
  /**
   * If true y-axis labels will be displayed
   */
  showYLabels: PropTypes.bool,
  /**
   * Defines the width of the area chart, in pixels.
   */
  width: PropTypes.number,
  /**
   * Show loader
   */
  loading: PropTypes.bool,
}

GlobalAreaChart.defaultProps = {
  data: null,
  height: null,
  maxYAxis: null,
  showXLabels: false,
  gridYLineWidth: null,
  showYLabels: false,
  width: null,
  gridXLineWidth: null,
}

export { GlobalAreaChart }
