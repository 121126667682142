import { mq, theme } from 'src/styling'

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// ===================
// TYPES
// ===================
type GlobalFormProps = {
  children: React.ReactNode
  variant?: 'default' | 'transparent'
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  baseStyles: {
    width: '100%',
    border: `0.9px solid ${theme.colors.ui.blueDark}`,
    color: theme.colors.ui.greyVeryDark,
    padding: '0.9rem',
    backgroundColor: theme.colors.ui.white,
    display: 'inline-block',
    boxSizing: 'border-box',
    borderRadius: '0.15rem',
    transition: 'padding 0.5s',
    '& p': {
      color: theme.colors.ui.greyVeryDark,
    },
  },
  transparent: { background: 'none', border: 'none', color: theme.colors.ui.greyVeryDark },
})

// =========
// COMPONENT
// =========
export const GlobalForm: React.FC<GlobalFormProps> = ({ variant, children }: GlobalFormProps) => {
  const classes = useStyles()

  return (
    <div className={`${classes.baseStyles} ${variant === 'transparent' ? classes.transparent : ''}`}>{children}</div>
  )
}
