import client from './apollo-client'
import { Query } from 'src/types/graphql'
import { DocumentNode } from 'apollo-boost'

type GQLQueryVariables = {
  [key: string]: any
}

type CreateResourceProps = {
  variables?: GQLQueryVariables
}

// No needed anymore, keep it as a reference
// const readFromCache = (query, props): object | null => {
//   let cache: any
//   try {
//     // Read from apollo cache
//     cache = client.readQuery({
//       query,
//       ...props,
//     })
//     return {
//       read: (): object => {
//         return cache
//       },
//     }
//   } catch (error) {
//     cache = null
//   }

//   return cache
// }

export const wrapPromise = (promise): any => {
  let status = 'pending'
  let response

  const suspender = promise.then(
    (res) => {
      status = 'success'
      response = res
    },
    (err) => {
      status = 'error'
      response = err
    }
  )
  const read = (): any => {
    switch (status) {
      case 'pending':
        throw suspender
      case 'error':
        throw response
      default:
        return response
    }
  }

  return { read }
}

function createResource<T = Query>(query: DocumentNode, props?: CreateResourceProps): { read: () => T } {
  const promise = client.query({ query, ...props }).then((res) => res.data)
  return wrapPromise(promise)
}

export { createResource }
