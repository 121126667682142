import React, { useEffect, useRef, useState } from 'react'
import { mq, theme } from 'src/styling'

import { ReactComponent as ChevronDown } from 'src/images/icons/global-chevron-down.svg'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// TYPES
interface SelectInputInterface {
  currentValue?: string
  isDropdownOverlay?: boolean
  noSelectionText?: string
  options: string[]
  onSelectOption: (val: string) => any
  width?: string
}

// ======
// STYLED
// ======
type SelectInputContainerProps = {
  width: string
}
const SelectInputContainer = styled.div<SelectInputContainerProps>`
  width: ${(props): string => props.width && props.width};
  position: relative;
`

type SelectContainerProps = {
  isDropdownOpen: boolean
}
const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  line-height: 1.5rem;
  border: 1px solid ${(props): string => (props.isDropdownOpen ? theme.colors.ui.greyDark : theme.colors.ui.greyLight)};
  border-radius: ${(props): string => (props.isDropdownOpen ? '0' : '0.15rem')};
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  background-color: #fff;
  cursor: pointer;

  &:hover 
    border: 1px solid ${theme.colors.ui.greyDark};
  }
`

const NoSelectionText = styled.div`
  padding: 0.25rem;
  padding-right: 0.75rem;
  align-self: center;
  font-size: 0.9rem;

  ${mq.sm} {
    font-size: 1rem;
  }
`

const ValuesContainer = styled.div`
  padding: 0.15rem 0.5rem 0.15rem 0.25rem;
  display: flex;
  flex: 1 1 0;
  flex-wrap: wrap;

  ${mq.sm} {
    padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  }
`

const Value = styled.div`
  padding: 0.25rem;
  line-height: 1.75rem;
  font-size: 0.9rem;

  ${mq.sm} {
    font-size: 1rem;
  }
`

const DropdownDivider = styled.span`
  margin: 0.5rem 0;
  border-left: 1px solid ${theme.colors.ui.greyLight};
`

const DropdownIconContainer = styled.span`
  align-self: center;
  cursor: pointer;
`

type DropdownIconProps = {
  isDropdownOpen: boolean
}

const DropdownIcon = styled.div<DropdownIconProps>`
  width: 0.75rem;
  margin: 0.5rem;
  vertical-align: middle;
  transform: rotate(${(props): string => (props.isDropdownOpen ? '180deg' : '0')});
  transition: transform ${(props): string => (props.isDropdownOpen ? '0.5s' : '0.2s')};

  ${mq.sm} {
    margin: 0.75rem;
  }
`

type DropdownContainerProps = {
  isDropdownOpen: boolean
  showDropdownScrollbar: boolean
  isDropdownOverlay: boolean
}
const DropdownContainer = styled.div<DropdownContainerProps>`
  max-height: ${(props): string => (props.isDropdownOpen ? '15rem' : '0')};
  width: 100%;
  overflow-y: ${(props): string => (props.showDropdownScrollbar ? 'auto' : 'hidden')};
  border: ${(props): string => (props.isDropdownOpen ? '1px' : '0')} solid ${theme.colors.ui.greyDark};
  border-top: 0;
  background-color: #fff;
  position: ${(props): string => (props.isDropdownOverlay ? 'absolute' : 'relative')};
  z-index: ${(props): string => (props.isDropdownOverlay ? '10' : 'auto')};
  transition: max-height ${(props): string => (props.isDropdownOpen ? '0.5s' : 's')};
`
type OptionProps = {
  isSelected: boolean
}
const Option = styled.div<OptionProps>`
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  background-color: ${(props): string => (props.isSelected ? theme.colors.ui.greyVeryLight : 'rgba(0,0,0,0)')};
  font-size: 0.9rem;

  :hover {
    background-color: ${theme.colors.ui.greyVeryLight};
  }

  ${mq.sm} {
    font-size: 1rem;
  }
`

// =========
// COMPONENT
// =========
const GlobalSelectInput: React.SFC<SelectInputInterface> = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [showDropdownScrollbar, setShowDropdownScrollbar] = useState(false)
  const node = useRef<React.ReactElement>()

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      if ((node.current as any).contains(e.target)) {
        return
      }
      setIsDropdownOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOpenCloseDropdown = (): void => {
    setTimeout(
      () => {
        setShowDropdownScrollbar(!isDropdownOpen)
      },
      isDropdownOpen ? 0 : 500
    )
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleSelectOption = (optionValue: string): void => {
    props.onSelectOption(optionValue)
    setIsDropdownOpen(false)
  }

  return (
    <SelectInputContainer width={props.width} ref={node}>
      <SelectContainer isDropdownOpen={isDropdownOpen} onClick={handleOpenCloseDropdown}>
        <ValuesContainer>
          {props.currentValue === '' && <NoSelectionText>{props.noSelectionText}</NoSelectionText>}
          {props.currentValue !== '' && <Value>{props.currentValue}</Value>}
        </ValuesContainer>

        <DropdownDivider></DropdownDivider>

        <DropdownIconContainer>
          <DropdownIcon isDropdownOpen={isDropdownOpen}>
            <ChevronDown />
          </DropdownIcon>
        </DropdownIconContainer>
      </SelectContainer>

      <DropdownContainer
        isDropdownOpen={isDropdownOpen}
        isDropdownOverlay={props.isDropdownOverlay}
        showDropdownScrollbar={showDropdownScrollbar}
      >
        {props.options.map((option) => (
          <Option
            key={option}
            onClick={(): void => handleSelectOption(option)}
            isSelected={option === props.currentValue}
          >
            {option}
          </Option>
        ))}
      </DropdownContainer>
    </SelectInputContainer>
  )
}

GlobalSelectInput.propTypes = {
  /**
   * The currently selected value.
   */
  currentValue: PropTypes.string,
  /**
   * If true, the dropdown menu will overlay content below it instead of expanding and pushing the content down.
   */
  isDropdownOverlay: PropTypes.bool,
  /**
   * Text to display if no option has been selected.
   */
  noSelectionText: PropTypes.string,
  /**
   * The available options to select from.
   */
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Callback: Fired when a value is selected from the dropdown. The value is passed as an input.
   */
  onSelectOption: PropTypes.func.isRequired,
  /**
   * The width of the select component
   */
  width: PropTypes.string,
}

GlobalSelectInput.defaultProps = {
  currentValue: '',
  isDropdownOverlay: false,
  noSelectionText: 'Select',
  onSelectOption: null,
  options: [],
  width: 'auto',
}

export { GlobalSelectInput }
