//Same as dialog header
// Theirs
import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

// Ours
import { theme as globalTheme, theme } from 'src/styling'
import ThirdPartyIconActive from 'src/images/navbar/third-party-icon-active.png'
import ReactTooltip from 'react-tooltip'

// ======
// TYPES
// ======
type Props = {
  children?: React.ReactNode
  isUnderlined?: boolean
  subtitle?: React.ReactNode | string
  title?: React.ReactNode | string
  titleIcon?: React.ReactNode
  minHeight?: string
  maxHeight?: string
  textAlign?: string
  isToolTipNextToTitle?: boolean
  id?: string
  enableThirdPartyIcon?: boolean
}

type HeaderContainerProps = {
  isUnderlined?: boolean
  minHeight?: string
  maxHeight?: string
  isToolTipNextToTitle?: boolean
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles<Theme, HeaderContainerProps>((theme) => ({
  headerRight: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' },
  subTitle: { color: 'black', fontSize: globalTheme.fontSizes.mediumSmall },
  title: {
    width: '100%',
    fontSize: globalTheme.fontSizes.xLarge,
    fontWeight: 500,
    color: globalTheme.colors.ui.blueDark,
    margin: '0.45rem 0 0.45rem 0',
    [theme.breakpoints.down('xs')]: { fontSize: '1.5rem' },
    [theme.breakpoints.down('xs')]: { fontSize: '1.5rem' },
  },
  iconContainer: { marginRight: '0.9rem' },
  thirdPartyIcon: {
    width: '1.2rem',
    marginLeft: '0.3rem',
    marginTop: '1px',
    position: 'absolute',
  },
  headerLeft: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flex: (props) => (props.isToolTipNextToTitle ? 'none' : '1'),
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: (props) => (props.isToolTipNextToTitle ? 'none' : 'space-between'),
    alignItems: 'center',
    minHeight: (props) => props.minHeight,
    maxHeight: (props) => props.maxHeight,
  },
}))

// =========
// COMPONENT
// =========
const GlobalWidgetHeader: React.FC<Props> = (props) => {
  const classes = useStyles({
    isUnderlined: props.isUnderlined,
    minHeight: props.minHeight,
    maxHeight: props.maxHeight,
    isToolTipNextToTitle: props.isToolTipNextToTitle,
  })
  return (
    <Box className={classes.headerContainer} id={props.id}>
      <Box className={classes.headerLeft} textAlign={props.textAlign}>
        {props.titleIcon && <Box className={classes.iconContainer}>{props.titleIcon}</Box>}
        {props.subtitle && <Box className={classes.subTitle}>{props.subtitle}</Box>}
        <Box textAlign={props.textAlign} className={classes.title}>
          {props.title}
          {props.enableThirdPartyIcon && (
            <img
              src={ThirdPartyIconActive}
              className={classes.thirdPartyIcon}
              data-tip
              data-for={`tooltip-viewing-as-third-party`}
            />
          )}
          <ReactTooltip
            backgroundColor={theme.colors.ui.blueDark}
            id={`tooltip-viewing-as-third-party`}
            place="right"
            effect="solid"
          >
            Viewing as third party
          </ReactTooltip>
        </Box>
      </Box>
      <Box className={classes.headerRight}>{props.children}</Box>
    </Box>
  )
}

export { GlobalWidgetHeader }
