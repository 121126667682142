import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { ReactComponent as ToolTipIcon } from 'src/images/icons/tool-tip.svg'

type Props = {
  children: React.ReactElement
  placement?: string
  open: boolean
  onClose?: () => void
  onOpen?: () => void
  isMobile?: boolean
  iconMarginLeft?: number
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  iconContainer: {
    height: 11.7,
    width: 11.7,
    marginLeft: (props: Props) => props.iconMarginLeft ?? 9,
  },
})

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 360,
    boxShadow: '0px 0px 22.5px -3.6px rgba(0,0,0,0.5)',
    fontSize: 13.5,
    padding: 14.4,
    lineHeight: 1.44,
  },
  arrow: {
    color: 'white',
    boxShadow: '0px 0px 22.5px -3.6px rgba(0,0,0,0.5)',
    marginBottom: -0.9,
  },
}))(Tooltip)

const ToolTip: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  return (
    <CustomTooltip
      title={props.children}
      onOpen={props.isMobile ? null : props.onOpen}
      onClose={props.isMobile ? null : props.onClose}
      open={props.open}
      interactive
      arrow
      placement={props.placement}
    >
      <Box className={classes.iconContainer}>
        <ToolTipIcon />
      </Box>
    </CustomTooltip>
  )
}

export default ToolTip
