//Same as Widget HEADER

import { Avatar, Box, Chip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { theme as globalTheme } from 'src/styling'
import { useHistory } from 'react-router-dom'
import humanFormat from 'human-format'
import { applyFiltersToUrl, getPrivacyDataTypesFromUrl } from 'src/utils/filters'

interface ChipsData {
  title?: string
  totalCount?: number
  key?: string
}
// ======
// TYPES
// ======
type Props = {
  children?: React.ReactNode
  isUnderlined?: boolean
  subtitle?: React.ReactNode | string
  title?: React.ReactNode | string
  titleIcon?: React.ReactNode
  minHeight?: string
  flipTitles?: boolean
  headerLeftWidth?: number | string
  headerRightWidth?: string | number
  chipsData?: ChipsData[]
}
// ======
// STYLED
// ======

type HeaderContainerProps = {
  isUnderlined?: boolean
  minHeight?: string
}

// ===================
// STYLES
// ===================

const useStyles = makeStyles<Theme, HeaderContainerProps>((theme) => ({
  headerRight: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' },
  subTitle: { color: 'black', fontSize: globalTheme.fontSizes.mediumLarge },
  title: {
    fontSize: globalTheme.fontSizes.xxLarge,
    fontWeight: 500,
    color: globalTheme.colors.ui.blueDark,
    margin: '0.36rem 0 0.36rem 0',
    [theme.breakpoints.down('xs')]: { fontSize: globalTheme.fontSizes.xxLarge },
    '& p': {
      '& span': {
        fontSize: globalTheme.fontSizes.mediumLarge,
        color: globalTheme.colors.ui.blueDark,
      },
    },
  },
  titleFlipped: {
    margin: '0.36rem 0 0rem 0',
  },
  headerLeft: { height: '100%', display: 'flex', flexWrap: 'wrap', flex: '1', alignItems: 'center' },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '9px',
    borderBottom: (props): string => (props.isUnderlined ? `0.05rem solid ${globalTheme.colors.ui.greyLight}` : '0px'),
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  chipBox: {
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
  },
  selectedAvatar: {
    backgroundColor: `${globalTheme.colors.ui.greyLight} !important`,
    color: `${globalTheme.colors.ui.blue} !important`,
  },
}))

// =========
// COMPONENT
// =========
const GlobalDialogHeader: React.FC<Props> = (props) => {
  const classes = useStyles({ isUnderlined: props.isUnderlined, minHeight: props.minHeight })
  const [selectedChips, setSelectedChips] = useState(getPrivacyDataTypesFromUrl() || [])
  const history = useHistory()

  const handleChipClick = (type: string) => {
    let updatedSelectedChips: string[]
    const queryParams = new URLSearchParams(history.location.search)
    const domains = queryParams.get('domains')?.split(',')
    const isActionedTable = Boolean(queryParams.get('actionedtable'))
    const currentTypes = queryParams.get('types')?.split(',') || []
    const urlParams = []
    if (type === 'Show All') {
      updatedSelectedChips = []
      queryParams.delete('types')
    } else {
      if (currentTypes.includes(type)) {
        updatedSelectedChips = currentTypes.filter((t) => t !== type)
      } else {
        updatedSelectedChips = [...currentTypes, type]
      }
    }
    urlParams.push(
      applyFiltersToUrl({
        privacyTypes: updatedSelectedChips,
        domains: domains?.length > 0 && domains,
        isActionedTable: isActionedTable && isActionedTable,
      })
    )

    history.push(`${location.pathname}${!!urlParams?.length ? `?${urlParams}` : ''}`)

    setSelectedChips(updatedSelectedChips)
  }

  const isShowAllSelected = selectedChips.length === 0
  return (
    <>
      <Box className={classes.headerContainer}>
        <Box className={classes.headerLeft} width={props.headerLeftWidth}>
          {props.titleIcon && <Box className={classes.iconContainer}>{props.titleIcon}</Box>}
          <div>
            {props.subtitle && props.flipTitles == false && <Box className={classes.subTitle}>{props.subtitle}</Box>}
            <Box className={[classes.title, props.flipTitles ? classes.titleFlipped : ''].join(' ')}>{props.title}</Box>
            {props.subtitle && props.flipTitles == true && <Box className={classes.subTitle}>{props.subtitle}</Box>}
          </div>
        </Box>

        <Box className={classes.headerRight} width={props.headerRightWidth}>
          {props.children}
        </Box>
      </Box>
      {props.chipsData && (
        <Box className={classes.chipBox}>
          <Chip
            key={'Show All'}
            className={classes.chipContainer}
            label="Show All"
            color="primary"
            variant={isShowAllSelected ? 'default' : 'outlined'}
            onClick={() => handleChipClick('Show All')}
          />
          {props.chipsData.map((chip, index) => (
            <Chip
              className={classes.chipContainer}
              key={chip.title}
              avatar={
                <Avatar className={selectedChips.includes(chip.key) ? classes.selectedAvatar : ''}>
                  {humanFormat(chip.totalCount, {
                    maxDecimals: 0,
                  })}
                </Avatar>
              }
              label={chip.title}
              onClick={() => handleChipClick(chip.key)}
              disabled={chip.totalCount === 0}
              color="primary"
              variant={selectedChips.includes(chip.key) ? 'default' : 'outlined'}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export { GlobalDialogHeader }
