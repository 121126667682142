import axios from 'axios'
export const downloadCompanyReport = async (
  companyId: number,
  companyName: string,
  type: string,
  scanIterId: number,
  date: string,
  companyScoreId: number
) => {
  const params = new URLSearchParams()

  if (scanIterId) params.set('scanIterId', `${scanIterId}`)
  if (date) params.set('date', date)
  if (companyScoreId) params.set('companyScoreId', `${companyScoreId}`)

  const paramsString = params.toString()

  return axios
    .get(`/companies/${companyId}/download/${type}${paramsString.length > 0 ? `?${paramsString}` : paramsString}`)
    .then((response) => {
      if (response?.data?.url) {
        const aTag = document.createElement('a')
        aTag.href = response.data.url
        aTag.click()
        return response?.data?.url
      }
      return null
    })
    .catch((error) => {
      console.log('error', error)
      throw new Error(error)
    })
}

export const isHttpAuthError = (statusCode: number) => [401, 403].includes(statusCode)
