import { ReactComponent as DeleteIcon } from 'src/images/icons/global-delete-icon.svg'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'
import { theme } from 'src/styling'

// ===================
// TYPES
// ===================
type GlobalTagProps = {
  /**
   * The text rendered in the tag.
   */
  value: StatefulValue | string
  /**
   * Defines if the tag is deletable.
   */
  isDeletable?: boolean
  /**
   * Callback: Fired when the tag is deleted. The tag value is passed as an input.
   */
  onDelete?: (val: StatefulValue | string) => void
  /**
   * Callback: Fired when the tag is inverted. The tag value and current state are passed as inputs.
   */
  onInvert?: (val: StatefulValue | string) => void
}

type StatefulValue = {
  text: string
  state: string
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  tagDeleteIcon: {
    height: '0.675rem',
    width: '0.675rem',
    color: theme.colors.ui.redDark,
  },
  delecteIconContainer: {
    padding: '0.225rem 0.45rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      borderTopRightRadius: '0.15rem',
      borderTopLeftRadius: '0.15rem',
      backgroundColor: theme.colors.ui.greyLight,
    },
  },
  tag: {
    display: 'inline-flex',
    margin: '0.225rem',
    fontSize: '0.885rem',
    borderRadius: '0.15rem',
    whiteSpace: 'nowrap',
    backgroundColor: theme.colors.ui.greyVeryLight,
    border: `0.9px solid ${theme.colors.ui.greyLight}`,
  },
  tagText: {
    whiteSpace: 'pre-wrap',
    lineHeight: '1.575rem',
    padding: '0rem 0.45rem',
    cursor: 'default',
    borderRadius: '0.15rem',
    backgroundColor: theme.colors.ui.greyVeryLight,
  },
  invertState: {
    cursor: 'pointer',
    backgroundColor: theme.colors.ui.redLight,
  },
  on: {
    backgroundColor: theme.colors.ui.greenLight,
  },
})

// ===================
// COMPONENT
// ===================
export const GlobalTag: React.FC<GlobalTagProps> = ({ value, isDeletable = false, onDelete, onInvert }) => {
  const classes = useStyles()

  const handleDeleteTag = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    onDelete(value)
  }

  const handleClickTag = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    if ((value as StatefulValue).state) {
      onInvert(value)
    }
  }

  return (
    <div
      id={`tag-${typeof value === 'string' ? value : value.text}`} // This needs extending to include the type as well!
      className={classes.tag}
      key={((value as StatefulValue).text ? (value as StatefulValue).text : value) as unknown as number}
    >
      <div
        className={`
        ${classes.tagText} 
        ${(value as StatefulValue).state && classes.invertState} 
        ${(value as StatefulValue).state == 'on' && classes.on}
        `}
        onClick={handleClickTag}
      >
        {typeof value === 'string' ? value : value.text}
      </div>

      {isDeletable && (
        <span className={classes.delecteIconContainer} onClick={handleDeleteTag}>
          <div className={classes.tagDeleteIcon}>
            <DeleteIcon />
          </div>
        </span>
      )}
    </div>
  )
}
