import { MultiItemTextInput } from 'src/components'
import { Box, Button, useMediaQuery } from '@material-ui/core'
import React, { Dispatch, SetStateAction, LegacyRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'src/styling'

// ======
// TYPES
// ======
interface FilterInputValuesInterface {
  id?: number
  title: string
  isKeyword?: boolean
}

type FilterInputProps = {
  currentInput?: string
  currentSearchValues?: FilterInputValuesInterface[]
  onInputChange?: (val: string) => void
  filterInputValues?: FilterInputValuesInterface[]
  onFilterInputValuesChange: Dispatch<SetStateAction<(string | number | object | { id: number; title: string })[]>>
  onKeywordInputChange?: (isSearchAllowed: boolean) => void
  onSubmit: () => void
  onClear: () => void
  onCursor?: Dispatch<SetStateAction<number>>
  cursor?: number
  onDropDownOpen: (dropDownState: boolean) => void
  onDropDownSelection?: () => void
  dropDownListSize?: number
  dropDownListElementRef?: React.MutableRefObject<HTMLDivElement[]>
  dropDownOpen?: boolean
  showSearch?: boolean
  showClear?: boolean
  placeholder?: string
  caseSensitive?: boolean
  dateRangePicker?: JSX.Element
  sourceFilter?: JSX.Element
  severitySlider?: JSX.Element
  moreOptions?: JSX.Element
  containerRightPaddingLeft?: number
  dateRange?: [{ startDate?: string; endDate?: string; key?: string }]
  severity?: { min: number; max: number }
  textInputWidth?: string | number
  textInputRef?: LegacyRef<HTMLDivElement>
  isRecentSearchOpen: boolean
}

// ======
// STYLES
// ======
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: '3.06rem',
  },
})

const FilterInput: React.FC<FilterInputProps> = (props) => {
  const classes = useStyles()
  const isScreenSmall = useMediaQuery('(min-width: 1000px)')

  return (
    <Box className={classes.root}>
      <MultiItemTextInput
        textInputRef={props.textInputRef}
        textInputWidth={props.textInputWidth}
        // input entered by the user
        currentInput={props.currentInput}
        // function to handle input change
        onInputChange={props.onInputChange}
        // array of keywords added to the search
        filterInputValues={props.filterInputValues}
        // function to add the keyword typed by the user to the array of filterInputValues
        onKeywordInputChange={props.onKeywordInputChange}
        // function to set the filterInputValues
        onFilterInputValuesChange={props.onFilterInputValuesChange}
        onSubmit={props.onSubmit}
        onDropDownOpen={props.onDropDownOpen}
        onCursor={props.onCursor}
        cursor={props.cursor}
        dropDownListSize={props.dropDownListSize}
        dropDownListElementRef={props.dropDownListElementRef}
        dropDownOpen={props.dropDownOpen}
        onDropDownSelection={props.onDropDownSelection}
        inputType="text"
        inputPlaceholder={props.placeholder}
        caseSensitive={props.caseSensitive}
      />
      {(props?.sourceFilter || props?.dateRangePicker || props?.severitySlider) && (
        <Box
          width={!props.showSearch ? '100%' : null}
          style={!isScreenSmall ? { display: 'none' } : {}}
          display="flex"
          flexDirection="row"
        >
          {props.dateRangePicker}
          {props.sourceFilter}
          {props.severitySlider}
        </Box>
      )}
      <Box display="flex" flexDirection="row">
        {props.showSearch && (
          <Button
            id="filter-input-search-button"
            style={{ fontSize: theme.fontSizes.mediumSmall }}
            onClick={props.onSubmit}
          >
            SEARCH
          </Button>
        )}
        {props.showClear && (
          <Button
            id="filter-input-clear-cancel-button"
            style={{ fontSize: theme.fontSizes.mediumSmall }}
            onClick={props.onClear}
          >
            {props.isRecentSearchOpen ? 'CANCEL' : 'CLEAR'}
          </Button>
        )}
      </Box>
      {props.moreOptions}
    </Box>
  )
}

export default FilterInput
