import React, { useEffect, useRef, useState } from 'react'

import HCSoldGauge from 'highcharts/modules/solid-gauge'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Scorecard } from 'components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { theme } from 'src/styling'
import { getSeverityColor } from 'src/utils/colors'

HCSoldGauge(Highcharts)

// ======
// TYPES
// ======

type StyleProps = {
  color: string
  demarcWord: string
  demarcWordFontSize: string
  isExternalASMPage: boolean | undefined
}

type createSeriesType = {
  radius: string
  innerRadius: string
  y: number
}

type Props = {
  demarcWord?: string
  height?: number
  range: [number, number]
  roundValues?: boolean
  value: number
  width?: number
  showScoreCard?: boolean
  centerWord?: string
  fontSize?: string
  demarcWordFontSize?: string
  isExternalASMPage: boolean | undefined
  color: string | undefined
}

// ======
// STYLES
// ======

const useStyles = makeStyles<Theme, StyleProps>({
  gaugeContainer: {
    position: 'relative',
  },
  chartContainer: {
    opacity: 0.8,
  },
  scoreCardContainer: {
    position: 'absolute',
    top: (props) => (props.isExternalASMPage ? '35%' : props.demarcWord ? '42%' : '45%'),
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '30%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  demarcWordContainer: {
    fontSize: (props: StyleProps) => (props.demarcWordFontSize ? props.demarcWordFontSize : '1.5vw'),
    color: (props: StyleProps) => (props.color ? props.color : theme.colors.ui.greyDark),
    position: 'relative',
    width: '100%',
    bottom: (props: StyleProps) => (props.isExternalASMPage ? 65 : 50),
    textAlign: 'center',
  },
})

const createSeries = (percentageValue: number): createSeriesType[] => {
  const data = []
  data.push({
    radius: '107.5%',
    innerRadius: '92.5%',
    y: percentageValue,
  })
  return data
}

const generateConfig = (props: Props, percentageValue: number): object => {
  const ticks: any = []
  const title: any = null

  const chartLineColor = getSeverityColor(percentageValue, 0, 100)

  return {
    chart: {
      height: props.height ? props.height : '100%',
      width: props.width,
      type: 'solidgauge',
      backgroundColor: null,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPositions: ticks,
      lineWidth: 2,
      minColor: chartLineColor,
      maxColor: chartLineColor,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        rounded: true,
      },
    },
    pane: {
      startAngle: -130,
      endAngle: 130,
      background: [
        {
          backgroundColor: 'rgba(0,0,0,0)',
          borderWidth: 0,
        },
      ],
      size: '92.5%',
    },
    series: {
      data: createSeries(percentageValue),
      stickyTracking: false,
      enableMouseTracking: false,
    },
  }
}

// ======
// COMPONENT
// ======

const GlobalGaugeChart: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const [min, max] = props.range
  const value = props.value
  const valueAsPercentage = ((value - min) / (max - min)) * 100
  const scoreContainer = useRef(null)

  // use scoreCardWidth to set the font size used in the score card. Unable to set fontsize to fill with css.
  const [scoreCardWidth, setScoreCardWidth] = useState(0)
  const fontSize = props.fontSize ?? `${scoreCardWidth / 2}px`

  useEffect(() => {
    async function updateRefs(): Promise<void> {
      if (scoreContainer.current) {
        setScoreCardWidth(scoreContainer.current.offsetWidth)
      }
    }

    updateRefs()
  }, [scoreContainer])

  const config = generateConfig(props, valueAsPercentage)

  //Splits words onto seperate lines
  const centerWords = props.centerWord?.split(' ').map((w, i, z) => (
    <div className="resizeme" key={i}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${(131 + 23 * w.length) * (z.length != 1 ? z.length / 1.8 : 1)} 95`}
        preserveAspectRatio="xMinYMid meet"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="75"
          fill={theme.colors.ui.greyDark}
          fontWeight={500}
        >
          {w}
        </text>
      </svg>
    </div>
  ))
  return (
    <>
      <div className={classes.gaugeContainer}>
        <div className={classes.chartContainer}>
          <HighchartsReact highcharts={Highcharts} options={config} />
        </div>

        <div className={classes.scoreCardContainer} ref={scoreContainer}>
          {scoreCardWidth && props.showScoreCard && !props.centerWord && (
            <Scorecard
              range={props.range}
              value={props.value}
              roundValues={props.roundValues}
              variant={'noBackground'}
              fontSize={fontSize}
              lineHeight={'normal'}
            />
          )}
          {!props.showScoreCard && props.centerWord && centerWords}
        </div>
        {props.demarcWord && <h2 className={classes.demarcWordContainer}>{props.demarcWord}</h2>}
      </div>
    </>
  )
}

GlobalGaugeChart.defaultProps = {
  demarcWord: null,
  height: null,
  range: null,
  roundValues: true,
  value: null,
  width: null,
  showScoreCard: true,
}

export { GlobalGaugeChart }
